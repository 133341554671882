import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { get, isNil } from 'utils/lodash';
import { getDefault } from 'utils/textHelper';
import Pagination from 'components/Pagination';
import IconSquare from 'components/IconSquare';
import Table, { ALIGNMENT } from 'components/Table';
import LoaderCSS from 'components/LoaderCSS';
import cn from 'classnames';
import { langCode } from 'config/i18n';
import Tippy from '@tippyjs/react';
import Emoji from 'components/Emoji';
import 'tippy.js/dist/tippy.css'; // optional
import './index.scss';

const TableShipments = ({
  withPagination = true,
  scope,
  onSort = () => {},
  onPageChange = () => {},
  shipments,
  shop,
  sorting,
  totalPages,
  page,
  isAdmin,
  loading = false,
  history,
  t
}) => {
  function openShipment(id) {
    history.push(`/shipments/${id}`);
  }

  const showReturnReasons = get(shop, 'showReturnReasons', false);
  const gatekeeperEnabled = get(shop, 'gatekeeperEnabled', false);
  const shopHasExchangeOption = get(shop, 'hasExchangeOption', false);

  // return reason
  function getReturnReason(d) {
    const rr = get(d, `returnReason.${langCode()}`);
    if (!rr) return '-';

    let rrFormatted = rr;
    const lengthOfRr = scope === 'forOverviewPage' ? 10 : 20;
    if (rr.length > lengthOfRr) {
      rrFormatted = rr?.truncate(lengthOfRr);
    }

    if (rrFormatted.length < rr.length)
      return (
        <Tippy placement="bottom" content={rr}>
          <span>{rrFormatted}</span>
        </Tippy>
      );

    return rr;
  }

  const investigationIcon = (
    <IconSquare icon="attention" className="investigation-icon" />
  );

  function getBadgeClass(status) {
    if (['delivered'].includes(status)) return 'badge-green';
    if (['incident', 'unfulfilled'].includes(status)) return 'badge-red';
    return null;
  }

  const isPickup = value => !isNil(value) && value === 'pickup';
  const isDropoff = value => !isNil(value) && value === 'dropoff';

  const columns = () => {
    let result = [];
    if (isAdmin) {
      result.push({
        id: d => `by-admin-icon-${d.id}`,
        accessor: d =>
          d.byAdmin ? <Emoji symbol="🪄" label="Created by admin" /> : '',
        className: 'shipments-table__emoji'
      });
      result.push({
        id: d => `return-type-icon-${d.id}`,
        accessor: d =>
          (isPickup(get(d, 'returnType')) && (
            <Emoji symbol="🚌" label="pickup" />
          )) ||
          (isDropoff(get(d, 'returnType')) && (
            <Emoji symbol="📦" label="dropoff" />
          )),
        className: 'shipments-table__emoji'
      });
    }

    result = [
      ...result,
      {
        id: 'orderNumber',
        title: t('shipments.orderNumber'),
        accessor: d => getDefault(d, 'orderNumber', 'N/A').substring(0, 20),
        sortable: true,
        className: 'shipments-table__order-number'
      },
      {
        id: 'creationDate',
        title: t('shipments.creationDate'),
        accessor: d => moment(d.createdAt).format('D-M'),
        className: 'shipments-table__operation-date'
      }
    ];

    if (gatekeeperEnabled || isAdmin)
      result.push({
        id: 'approveStatus',
        title: t('statusApprove.approved'),
        accessor: d => (
          <div className="badge">{t(`statusApprove.${d.approveStatus}`)}</div>
        ),
        className: 'shipments-table__approve-status'
      });

    result.push({
      id: 'status',
      title: t('shipments.status'),
      accessor: d => (
        <div className={cn('badge', getBadgeClass(d.status))}>
          {t(`status.${d.status}`)}
        </div>
      ),
      sortable: true,
      className: 'shipments-table__status'
    });

    result.push({
      id: 'email',
      title: t('shipments.email'),
      accessor: d =>
        (scope === 'forOverviewPage' ? d.email?.truncate(15) : d.email) ||
        'N/A',
      className: 'shipments-table__email'
    });

    if (showReturnReasons && scope !== 'forOverviewPage')
      result.push({
        id: 'returnReason',
        title: t('shipments.returnReason'),
        accessor: d => getReturnReason(d),
        className: 'shipments-table__operation-date'
      });

    if (shopHasExchangeOption && scope !== 'forOverviewPage')
      result.push({
        id: 'wantsExchange',
        title: t('shipments.wantExchangeTo'),
        alignment: ALIGNMENT.CENTER,
        accessor: d =>
          getDefault(d, 'wantExchangeTo', false)
            ? t('general.yes')
            : t('general.no'),
        className: 'shipments-table__email',
        sortable: true
      });

    result.push({
      id: 'country',
      title: t('shipments.country'),
      alignment: ALIGNMENT.CENTER,
      accessor: d => d.countryCode?.toUpperCase(),
      sortable: true,
      className: 'shipments-table__country'
    });

    result.push({
      id: 'hasInvestigation',
      title: investigationIcon,
      accessor: d => (get(d, 'investigation.open') ? investigationIcon : null),
      className: 'shipments-table__has-investigation'
    });

    result.push({
      id: 'action',
      alignment: ALIGNMENT.CENTER,
      className: 'shipments-table__actions',
      accessor: () => (
        <IconSquare icon="eye" className="shipment-action__icon" />
      )
    });
    return result;
  };

  const { sortCriteria, sortKey } = sorting;

  return (
    <div className="table-shipments__container">
      <div className="shipments-table__table-container">
        <Table
          withBorder={false}
          data={shipments}
          columns={columns()}
          sortCriteria={sortCriteria}
          sortKey={sortKey}
          onHeadClick={onSort}
          onRowClick={item => openShipment(item.id)}
          rowClickable
          className={
            scope === 'forOverviewPage' ? 'table-shipments--overview' : ''
          }
          t={t}
        />
      </div>
      <LoaderCSS visible={loading} wide />
      {withPagination && totalPages > 1 && (
        <Pagination
          pages={totalPages}
          page={page}
          onPageChange={onPageChange}
          loading={loading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    shop: { shop },
    auth: { user },
    shipment,
    main: { loadingObj }
  } = state;
  const loading = get(loadingObj, props.featureName);
  const { shipments = [], page, totalPages } = get(shipment, props.scope) || {};
  return { shop, user, shipments, page, totalPages, loading };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(TableShipments);

export const PROFILE = {
  UPDATE_NAME: 'PROFILE_UPDATE_NAME',
  UPDATE_NAME_FAIL: 'PROFILE_UPDATE_NAME_FAIL',
  UPDATE_NAME_OK: 'PROFILE_UPDATE_NAME_OK',

  SEND_RESET_PASSWORD_URL: 'SEND_RESET_PASSWORD_URL',
  SEND_RESET_PASSWORD_URL_FAIL: 'PROFILE_SEND_RESET_PASSWORD_URL_FAIL',
  SEND_RESET_PASSWORD_URL_OK: 'PROFILE_SEND_RESET_PASSWORD_URL_OK',
  SEND_RESET_PASSWORD_URL_STATE: 'PROFILE_SEND_RESET_PASSWORD_URL_STATE',

  RESET_PASSWORD: 'PROFILE_RESET_PASSWORD',
  RESET_PASSWORD_OK: 'PROFILE_RESET_PASSWORD_OK',
  RESET_PASSWORD_FAIL: 'PROFILE_RESET_PASSWORD_FAIL',

  UPDATE_PASSWORD: 'PROFILE_UPDATE_PASSWORD',
  UPDATE_PASSWORD_OK: 'PROFILE_UPDATE_PASSWORD_OK',
  UPDATE_PASSWORD_FAIL: 'PROFILE_UPDATE_PASSWORD_FAIL',

  REMOVE_ERROR: 'PROFILE_REMOVE_ERROR',
  RESET: 'PROFILE_RESET'
};

export default {
  updateName: (firstName, lastName) => ({
    type: PROFILE.UPDATE_NAME,
    firstName,
    lastName
  }),
  updateNameOk: () => ({
    type: PROFILE.UPDATE_NAME_OK
  }),
  updateNameFail: (error, actionName) => ({
    type: PROFILE.UPDATE_NAME_FAIL,
    error,
    actionName
  }),
  //
  sendResetPasswordUrl: email => ({
    type: PROFILE.SEND_RESET_PASSWORD_URL,
    email
  }),
  sendResetPasswordUrlOK: () => ({
    type: PROFILE.SEND_RESET_PASSWORD_URL_OK
  }),
  sendResetPasswordUrlFail: (error, actionName) => ({
    type: PROFILE.SEND_RESET_PASSWORD_URL_FAIL,
    error,
    actionName
  }),
  //
  resetPassword: (token, newPassword) => ({
    type: PROFILE.RESET_PASSWORD,
    token,
    newPassword,
  }),
  resetPasswordOk: () => ({
    type: PROFILE.RESET_PASSWORD_OK
  }),
  resetPasswordFail: (error, actionName) => ({
    type: PROFILE.RESET_PASSWORD_FAIL,
    error,
    actionName
  }),
  updatePassword: (oldPassword, newPassword) => ({
    type: PROFILE.UPDATE_PASSWORD,
    oldPassword,
    newPassword
  }),
  updatePasswordOk: () => ({
    type: PROFILE.UPDATE_PASSWORD_OK
  }),
  updatePasswordFail: (error, actionName) => ({
    type: PROFILE.UPDATE_PASSWORD_FAIL,
    error,
    actionName
  }),
  //
  removeError: () => ({
    type: PROFILE.REMOVE_ERROR
  }),
  reset: () => ({
    type: PROFILE.RESET
  })
};

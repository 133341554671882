import React from 'react';
import ReactSVG from 'react-svg';
import chevronIcon from 'assets/images/arrow.svg';
import './index.scss';

export const PaginationButton = ({ onClick, disabled, text, next }) => {
  const className = next
    ? 'pagination__button-next pagination__button--primary'
    : 'pagination__button-prev pagination__button--secondary';

  return (
    <button
      type="button"
      onClick={onClick}
      onKeyDown={onClick}
      disabled={disabled}
      className={`${className} pagination__button`}
    >
      <span>{text}</span>
    </button>
  );
};

export default ({
  pages,
  page,
  onPageChange,
  className = '',
  loading,
  ...rest
}) => {
  const viewPage = page;
  const viewPages = pages;

  const onPrevClick = () => {
    if (loading) return;

    const updatedPage = page - 1 <= 0 ? 0 : page - 1;
    onPageChange(updatedPage);
  };

  const onNextClick = () => {
    if (loading) return;

    const updatedPage = page + 1 > pages ? pages : page + 1;
    onPageChange(updatedPage);
  };

  let pageItems = [];

  // page = 15, pages = 30
  if (viewPage >= 5 && viewPages > 10 && viewPages - viewPage + 1 >= 5) {
    pageItems = [
      1,
      '...',
      viewPage - 1,
      viewPage,
      viewPage + 1,
      '...',
      viewPages
    ];
    /**
     * [1, '...', 14, 15, 16, '...', 30]
     */
  } else if (viewPage < 5 && viewPages > 10) {
    pageItems = [
      ...[...Array(5).keys()].map((k, i) => i + 1),
      '...',
      viewPages
    ];
    /**
     * [1, 2, 3, 4, 5, '...', 30]
     */
  } else if (
    viewPage > 5 &&
    viewPages - viewPage + 1 <= 5 &&
    viewPages - viewPage + 1 >= 0 &&
    viewPages > 10
  ) {
    pageItems = [
      1,
      '...',
      ...[...Array(5).keys()].map((k, i) => i + viewPages - 4)
    ];
    /**
     * [1, '...', 25, 26, 27, 28, 29, 30]
     */
  } else if (viewPages <= 10) {
    pageItems = [...[...Array(viewPages).keys()].map((k, i) => i + 1)];
    /**
     * [1, 2, 3, 4, 5, 6, 7, 8, 9, pages]
     */
  }

  return (
    <>
      <div className={`${className} pagination`}>
        <PaginationButton
          onClick={onPrevClick}
          disabled={loading || viewPages <= 1 || viewPage <= 1}
          text={<ReactSVG src={chevronIcon} />}
        />
        <div className="pagination__pages">
          {pageItems.map((p, i) => (
            <div
              className={`${
                p === viewPage ? 'pagination__page-button--active' : ''
              } pagination__page-button ${
                p === '...' ? 'pagination__page-button--separator' : ''
              }`}
              key={`${i}-${p}`}
              onClick={() => !loading && onPageChange(p)}
              onKeyDown={() => onPageChange(p)}
              tabIndex={-1}
              role="button"
              disabled={loading}
            >
              <span>{p}</span>
            </div>
          ))}
        </div>
        <PaginationButton
          onClick={onNextClick}
          disabled={loading || viewPages === viewPage}
          next
          text={<ReactSVG src={chevronIcon} />}
        />
      </div>
    </>
  );
};

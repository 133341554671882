import React from 'react';
import ReactSVG from 'react-svg';
import envelope from 'assets/images/envelope.svg';
import overview from 'assets/images/dashboard.svg';
import shipments from 'assets/images/shipments.svg';
import statistics from 'assets/images/statistics.svg';
import team from 'assets/images/team.svg';
import faq from 'assets/images/team.svg';
import eye from 'assets/images/eye.svg';
import eyeClosed from 'assets/images/eyeClosed.svg';
import generateShipment from 'assets/images/generateLabel.svg';
import profile from 'assets/images/profile.svg';
import logout from 'assets/images/logout.svg';
import attention from 'assets/images/attention.svg';
import tick from 'assets/images/tick.svg';
import './index.scss';

const icons = {
  envelope,
  eye,
  eyeClosed,
  overview,
  shipments,
  statistics,
  team,
  faq,
  generateShipment,
  profile,
  logout,
  attention,
  tick
};
export default ({ icon, className = '', style = {}, onClick = () => {} }) => {
  return (
    <div className={`icon-square ${className}`} style={style} onClick={onClick}>
      <ReactSVG src={icons[icon]} />
    </div>
  );
};

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';

function Locale(props = {}) {
  const { disabled, errors, validate = [], change, t } = props;

  const options = [
    { text: t('locales.english'), value: 'en' },
    { text: t('locales.dutch'), value: 'nl' },
    { text: t('locales.french'), value: 'fr' },
    { text: t('locales.deutsch'), value: 'de' }
  ];

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      disabled={disabled}
      options={options}
      prompt={t('form.locale.prompt')}
      errors={[get(errors, 'locale')]}
      label={t('shipments.locale')}
      name="locale"
      validate={validate}
      change={change}
    />
  );
}
export default withTranslation()(Locale);

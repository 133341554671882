import { createPromiseAction } from '@adobe/redux-saga-promise';

export const AUTH = {
  TOGGLE_LOGOUT_MODAL: 'AUTH_TOGGLE_LOGOUT_MODAL',
  TOGGLE_LOGOUT_MODAL_FAIL: 'AUTH_TOGGLE_LOGOUT_MODAL_FAIL',
  TOGGLE_LOGOUT_MODAL_OK: 'AUTH_TOGGLE_LOGOUT_MODAL_OK',
  LOGIN: 'AUTH_LOGIN',
  LOGIN_FAIL: 'AUTH_LOGIN_FAIL',
  LOGIN_OK: 'AUTH_LOGIN_OK',
  REFRESH_TOKEN: 'AUTH_REFRESH_TOKEN',
  REFRESH_TOKEN_OK: 'AUTH_REFRESH_TOKEN_OK',
  USER_LOGOUT: 'AUTH_USER_LOGOUT',
};

export default {
  login: createPromiseAction(AUTH.LOGIN),
  loginOk: (token, user) => ({
    type: AUTH.LOGIN_OK,
    token,
    user
  }),
  loginFail: error => ({
    type: AUTH.LOGIN_FAIL,
    error
  }),
  refreshToken: createPromiseAction(AUTH.REFRESH_TOKEN),
  refreshTokenOk: (token, user) => ({
    type: AUTH.REFRESH_TOKEN_OK,
    token,
    user
  }),
  toggleLogoutModal: () => ({
    type: AUTH.TOGGLE_LOGOUT_MODAL
  }),
  toggleLogoutModalOk: () => ({
    type: AUTH.TOGGLE_LOGOUT_MODAL_OK
  }),
  toggleLogoutModalFail: error => ({
    type: AUTH.TOGGLE_LOGOUT_MODAL_FAIL,
    error
  }),
  logout: () => ({
    type: AUTH.USER_LOGOUT
  }),
};

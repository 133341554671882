import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import { isBlank, getDefault } from 'utils/textHelper';
import searchIcon from 'assets/images/search.svg';
import './index.scss';

const SearchBar = ({ value = '', history, t }) => {
  const inputRef = useRef();
  const goToResults = searchKey => {
    if (isBlank(searchKey)) return null;
    const uri = encodeURI(`/s/${encodeURI(searchKey)}`);
    return history.push(uri);
  };

  const handleKeyDown = e => {
    const searchKey = getDefault(inputRef, 'current.value', '').trim();
    return e.key === 'Enter' && goToResults(searchKey);
  };

  const searchOnClick = () => {
    const searchKey = getDefault(inputRef, 'current.value', '').trim();
    return goToResults(searchKey);
  };

  return (
    <div className="search-bar">
      <ReactSVG
        src={searchIcon}
        className="search-bar__icon"
        onClick={searchOnClick}
        style={{ cursor: 'pointer' }}
      />
      <input
        type="text"
        placeholder={t('search.placeholder')}
        className="search-bar__input"
        onKeyDown={handleKeyDown}
        ref={inputRef}
        defaultValue={value}
      />
    </div>
  );
};

export default compose(withRouter, withTranslation())(SearchBar);

import React from 'react';
import { Field as ReduxFormField, reduxForm } from 'redux-form';

import { required, repeated, minLength } from 'utils/validations';
import Field from 'components/Field';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { dispatch } from 'store';
import profileActions from 'features/profile/actions';

const validate = values => {
  const errors = {};
  
  errors.oldPassword = required(values.oldPassword);
  if (values.oldPassword) errors.oldPassword = minLength(8)(values.oldPassword);
  
  errors.newPassword = required(values.newPassword);
  if (values.newPassword) errors.newPassword = minLength(8)(values.newPassword);
  
  errors.repeatedNewPassword = repeated(values.newPassword)(
    values.repeatedNewPassword
  );

  return errors;
};

const Form = ({ handleSubmit, onSubmit, errors, onClose, t }) => (
  <div>
    <div className="modal__header">
      <span>{t('profile.passwordForm.introText')}</span>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column">
        <ReduxFormField
          className=""
          component={Field}
          name="oldPassword"
          label={t('profile.passwordForm.oldPassword.label')}
          errors={errors ? errors.oldPassword : null}
          type="password"
        />
        <ReduxFormField
          className=""
          component={Field}
          name="newPassword"
          label={t('profile.passwordForm.newPassword.label')}
          errors={errors ? errors.newPassword : null}
          type="password"
        />
        <ReduxFormField
          className=""
          component={Field}
          name="repeatedNewPassword"
          label={t('profile.passwordForm.repeatedNewPassword.label')}
          errors={errors ? errors.repeatedNewPassword : null}
          type="password"
        />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <Button
          text={t('profile.cancel')}
          onClick={onClose}
          className="button--secondary width--auto"
        />
        <Button
          text={t('profile.update')}
          type="submit"
          className="button--primary width--auto ml-4"
        />
      </div>
    </form>
  </div>
);

const FormWithRedux = reduxForm({
  form: 'updateProfilePasswordForm',
  validate
})(Form);

class UpdatePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handlePasswordChange({ oldPassword, newPassword }) {
    dispatch(profileActions.updatePassword(oldPassword, newPassword));
  }

  render() {
    const { onClose, t } = this.props;

    return (
      <Modal visible className="modal--small">
        <FormWithRedux
          onClose={onClose}
          onSubmit={this.handlePasswordChange}
          t={t}
        />
      </Modal>
    );
  }
}

export default UpdatePasswordModal;

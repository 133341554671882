import React from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

function ShopSelector({
  options = [],
  onSelect,
  selectedValue,
  loading = false,
  containerStyles = {},
  t
}) {
  const selectedOption = options.find(option => option.value === selectedValue);
  const stylez = {
    container: styles => ({
      ...styles,
      minWidth: '17rem',
      ...containerStyles
    })
  };

  return (
    <Select
      styles={stylez}
      value={selectedOption}
      isLoading={loading}
      isClearable
      isSearchable
      options={options}
      onChange={option => onSelect(option ? option.value : option)}
      getOptionLabel={option => `${option.text}`}
      getOptionValue={option => option.value}
      placeholder={t('form.selectShop')}
    />
  );
}

export default withTranslation()(ShopSelector);

import React from 'react';
import { dispatch } from 'store';
import cn from 'classnames';
import { Field as ReduxFormField, reduxForm } from 'redux-form';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import investigationActions from 'features/investigation/actions';
import Field from 'components/Field';
import FieldTextArea from 'components/FieldTextArea';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import { required } from 'utils/validations';
import { get } from 'lodash';
import './index.scss';

const StartInvestigationForm = props => {
  const {
    shipment,
    handleSubmit,
    errors,
    submitting,
    pristine,
    history,
    t
  } = props;

  const startInvestigation = ({ body }) =>
    dispatch(investigationActions.startInvestigation(shipment.id, body));

  return (
    <>
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '98%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        main={
          <>
            <ContentBox
              style={{ minWidth: '61.25rem' }}
              header={
                <Breadcrumbs style={{ marginBottom: '2.5rem' }}>
                  <Breadcrumbs.Item
                    onClick={() => history.push('/overview')}
                    tabIndex={0}
                  >
                    {t('general.overview')}
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item
                    onClick={() => history.push('/shipments')}
                    tabIndex={-1}
                  >
                    {t('general.overviewShipment')}
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item
                    tabIndex={-2}
                    onClick={() =>
                      history.push(`/shipments/${get(shipment, 'id')}`)
                    }
                  >
                    {t('general.shipment')} {get(shipment, 'orderNumber')}
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item tabIndex={-3}>
                    {t('shipments.startInvestigation')}
                  </Breadcrumbs.Item>
                </Breadcrumbs>
              }
              containerStyle={{
                flexDirection: 'column',
                minHeight: '50%',
                borderRadius: '2.5rem',
                marginBottom: '2.5rem'
              }}
            >
              <ContentBox.Row
                header={
                  <ContentBox.Row.Header
                    text={<>{t('pages.investigationForm.investigation')}:</>}
                  />
                }
                content={
                  <div className="start-investigation-form__top-block">
                    <ReduxFormField
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      disabled
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.name')}
                      name="name"
                    />
                    <ReduxFormField
                      disabled
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.date')}
                      name="date"
                    />

                    <ReduxFormField
                      disabled
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.email')}
                      name="email"
                    />
                    <ReduxFormField
                      disabled
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.network')}
                      name="network"
                    />
                    <ReduxFormField
                      disabled
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.orderNumber')}
                      name="orderNumber"
                    />

                    <ReduxFormField
                      disabled
                      style={{ marginBottom: '0' }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('start-investigation-form__field')}
                      component={Field}
                      label={t('shipments.trackingCode')}
                      name="trackingCode"
                    />
                  </div>
                }
              />
            </ContentBox>
            <ContentBox
              style={{ minWidth: '61.25rem' }}
              containerStyle={{
                flexDirection: 'column',
                minHeight: '50%',
                borderRadius: '2.5rem'
              }}
            >
              <ContentBox.Row
                header={
                  <ContentBox.Row.Header
                    text={t('pages.investigationForm.addComment')}
                  />
                }
                content={
                  <form
                    onSubmit={handleSubmit(startInvestigation)}
                    className="start-investigation-form"
                  >
                    <ReduxFormField
                      inputStyle={{ height: '12.5rem' }}
                      disabled={submitting}
                      className=""
                      component={FieldTextArea}
                      name="body"
                      placeholder={t('pages.investigationForm.placeholder')}
                      errors={get(errors, 'body')}
                      validate={[required]}
                    />
                    <Button
                      disabled={submitting || pristine}
                      text={t('pages.investigationForm.primatyButtonText')}
                      type="submit"
                      className="button--primary width--100"
                    />
                  </form>
                }
              />
            </ContentBox>
          </>
        }
      />
    </>
  );
};

export default reduxForm({
  form: 'startInvestigationForm'
})(StartInvestigationForm);

import { call, put } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { getAddressRequest, addressResponseInterface } from './api';
import mainActions from '../main/actions';
import addressActions from './actions';
import { handleAPIError } from '../../utils/sagaUtils';

export function* getAddress(action) {
  yield call(implementPromiseAction, action, function*() {
    yield put(mainActions.startLoading('getAddress'));
    const {
      payload: { postalCode, houseNumber }
    } = action;

    try {
      const response = yield getAddressRequest(postalCode, houseNumber);
      const address = addressResponseInterface(response);

      yield put(addressActions.getAddressOk(address));
      return address;
    } catch (error) {
      yield put(addressActions.getAddressFail(error));
      handleAPIError(error, 'getAddress');
      throw error;
    } finally {
      yield put(mainActions.stopLoading('getAddress'));
    }
  });
}

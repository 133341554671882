import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getShop } from 'utils/selectors';

function Location(props = {}) {
  const { disabled, errors, validate = [], change, onChange, t } = props;

  const shop = useSelector(getShop);
  const locations = get(shop, 'locations', []);
  const locationOptions = locations
    .filter(location => location.creatingLabels)
    .map(l => {
      return {
        text: (
          <>
            <span className="modal-approve__location-name">{l.name}</span>{' '}
            {`${l.street}, ${l.houseNumber}, ${l.postalCode} ${l.city}`}
          </>
        ),
        value: +l.id
      };
    });

  if (locationOptions.length === 0) return null;

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      style={{ zIndex: 3 }}
      disabled={disabled}
      options={locationOptions}
      prompt={t('form.selectDestination')}
      name="locationId"
      errors={[get(errors, 'locationId')]}
      label={t('shipments.location')}
      validate={validate}
      change={change}
      onChange={onChange}
    />
  );
}
export default withTranslation()(Location);

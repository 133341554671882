import { isEmpty, isNil } from 'lodash';
import { post } from '../rootApi';

// SHIPMENTS
export function getShipmentsRequest(
  perPage,
  pageNum,
  sorting,
  loadShop = false,
  withAuthor = false,
  shopId,
  from,
  to,
  byStatus,
  byOrigin
) {
  let paramsLine = `page: ${pageNum}, perPage: ${perPage}`;
  if (sorting !== null && !isEmpty(sorting)) {
    paramsLine = paramsLine.concat(
      `, sortKey: ${sorting.sortKey.toUpperCase()}, sortCriteria: ${
        sorting.sortCriteria
      }`
    );
  }
  if (withAuthor) paramsLine = paramsLine.concat(`, withAuthor: true`);
  if (shopId) paramsLine = paramsLine.concat(`, shopId: ${shopId}`);
  if (from) paramsLine = paramsLine.concat(`, from: "${from}"`);
  if (to) paramsLine = paramsLine.concat(`, to: "${to}"`);
  if (byStatus) paramsLine = paramsLine.concat(`, byStatus: "${byStatus}"`);
  if (byOrigin) paramsLine = paramsLine.concat(`, byOrigin: "${byOrigin}"`);

  let GET_SHOP = `shop {
    id
    code
    name
    slug
    frontendAssetId
    shopUrl
    hasFreeShipments
    termsAndConditionsUrl
    networks
    countries
    countryOptions
    paperlessOrigins
    insuranceOptionEnabled
    gatekeeperEnabled
    multiDestinationsEnabled
    simpleLabels
    showPersonalFieldsForDropoff
    hasExchangeOption
    showReturnReasons
    showDescription
    requireDescription
    locations {
      id
      name
      countryCode
      postalCode
      city
      street
      houseNumber
      default
      creatingLabels
    }
    priceLists {
      network
      pickupParcelPrice
      dropoffParcelPrice
      dropoffLetterboxPrice
      optionalPrice
      countryCode
    }
  }
`;
  if (!loadShop) GET_SHOP = '';

  const GET_SHIPMENTS = `
  query {
    shipmentsWithMeta(${paramsLine}) {
      totalPages
      page
      shipments {
        id
        status
        orderNumber
        email
        country
        countryCode
        returnType
        approveStatus
        start
        createdAt
        labelUrl
        wantExchangeTo
        byAdmin
        investigation {
          open  
          resolved
        }
        returnReason {
          nl
          en
        }
      }  
    }
    ${GET_SHOP}
  }
 `;

  return post({ query: GET_SHIPMENTS });
}

// SEARCH SHIPMENT
export function searchShipmentsRequest(searchKey, pageNum, perPage, sorting) {
  let paramsLine = `query: "${searchKey.replace(
    /\\/g,
    ''
  )}", page: ${pageNum}, perPage: ${perPage}`;
  if (!isNil(sorting) && !isEmpty(sorting))
    paramsLine = paramsLine.concat(
      `, sortKey: ${sorting.sortKey.toUpperCase()}, sortCriteria: ${
        sorting.sortCriteria
      }`
    );

  const SEARCH_SHIPMENTS = `
  {
    search(${paramsLine}) {
      totalPages
      page
      shipments {
        id
        status
        orderNumber
        email
        country
        countryCode
        returnType
        start
        createdAt
        approveStatus
        wantExchangeTo
        byAdmin
        investigation {
          open
          resolved
        }
        returnReason {
          nl
          en
        }
      }
    }
  }
 `;
  return post({ query: SEARCH_SHIPMENTS });
}

// GET SHIPMENT
export function getShipmentRequest(shipmentId) {
  const GET_SHIPMENT = `
  {
    shipment(id: "${shipmentId}") {
      id
      status
      start
      stop
      ref
      returnReason {
        nl
        en
        defect
      }
      customReturnReason
      trackingUpdates {
        status
        timestamp
        ref
        explanation
      }
      orderNumber
      email
      name
      numberOfBoxes
      comments
      shipFromStreet
      shipFromHouseNumber
      shipFromCity
      shipFromPostalCode
      shipFromCountryCode
      shipFromAddressLine
      address
      returnType
      isFree
      phone
      locale
      shop {
        id
        name
        frontendAssetId
        gatekeeperEnabled
        multiDestinationsEnabled
        showDescription
        partner
        insuranceOptionEnabled
        locations {
          id
          name 
          countryCode
          postalCode
          city
          street
          houseNumber
          default
          proceedWithShipment
        }
      }
      shopName
      notes {
        id
        createdAt
        message
        shipment {
          id
          orderNumber
        }
        author {
          fullName
          role
        }
      }
      network
      timeslotId
      labelUrl
      trackingUrl
      approveStatus
      investigation {
        id
        open
        resolved
      }
      wantExchangeTo
      defect
      paymentStatus
      imageUrl
      parentUrl
      childUrls
      itemDescriptions
      userShouldntPay
      insured
    }
  }
 `;

  return post({ query: GET_SHIPMENT });
}

// CREATE LABEL FOR SHIPMENT
export function createLabelRequest(id) {
  const query = `
    mutation createLabelForShipment($id: String!) {
      createLabelForShipment(id: $id) {
        id
      }
    }
  `;
  const variables = { id };
  return post({ query, variables });
}

// UPDATE PICKUP SHIPMENT
export function updatePickupRequest(variables) {
  return post({
    query: 'op:Dashboard:UpdatePickupShipment',
    variables
  });
}

// CANCEL SHIPMENT
export function cancelShipmentRequest(id, shopId) {
  const query = `mutation cancelShipment($id: String!, $shopId: String) {
    cancelShipment(id:$id, shopId: $shopId)
  }`;
  return post({
    query,
    variables: { id, shopId }
  });
}

// UPDATE APPROVE STATUS
export function updateApproveStatusRequest(
  shipmentId,
  status,
  locationPackages,
  userShouldntPay = null
) {
  return post({
    query: `mutation updateApproveStatus($shipmentId: String!, $status: String!, $locationPackages: [LocationPackages!], $userShouldntPay: Boolean) {
      updateApproveStatus(shipmentId: $shipmentId, status: $status, locationPackages: $locationPackages, userShouldntPay: $userShouldntPay)
    }`,
    variables: {
      shipmentId,
      status,
      locationPackages,
      userShouldntPay
    }
  });
}

export function createShipmentRequest(variables) {
  return post({
    query: 'op:CreateDashboardShipment',
    variables
  });
}

import { post } from '../rootApi';

// USER
export function updateProfileNameRequest(firstName, lastName) {
  const query = `
    mutation updateProfileName($firstName:String!, $lastName:String!){
    updateProfileName(firstName: $firstName, lastName: $lastName) {
      jwtToken
    }
  }
`;

  const variables = { firstName, lastName };
  return post({ query, variables });
}

export function sendResetPasswordUrlRequest(email) {
  const query = `
    mutation {
      sendResetPasswordUrlV1(email: "${email}")
    }
 `;
  return post({ query });
}

export function resetPasswordRequest(token, password) {
  const query = `
    mutation {
      resetProfilePasswordV1(token: "${token}", password: "${password}") {
        jwtToken
        error
      }
    }
 `;
  return post({ query });
}

export function updateProfilePasswordRequest(oldPassword, newPassword) {
  const query = `
    mutation updateProfilePassword($oldPassword:String!, $newPassword:String!){
    updateProfilePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      jwtToken
    }
  }
`;

  const variables = { oldPassword, newPassword };
  return post({ query, variables });
}

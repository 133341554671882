import React from 'react';
import Pagination from 'components/Pagination';
import Table, { ALIGNMENT } from 'components/Table';
import 'components/TableShipments/index.scss';
import './index.scss';

// In this component we utilize the styles from the TableShipments component
// because there it's not any difference
const TableShipmentsPerCountry = ({
  withPagination = true,
  rows,
  totalPages,
  page,
  loading = false,
  filterZeroes = false,
  t
}) => {
  function compareNames(a, b) {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  }

  const columns = () => {
    return [
      {
        id: 'country',
        title: t('stats.country'),
        accessor: d => d[0]
      },
      {
        id: 'amount',
        title: t('stats.amount'),
        accessor: d => d[1],
        alignment: ALIGNMENT.CENTER
      }
    ];
  };

  let data = rows.body;
  if (filterZeroes) {
    data = data
      .filter(el => el[1] !== 0)
      .map(el => [t(`countries.${el[0]}`), el[1]])
      .sort(compareNames);
  }

  return (
    <div className="table-shipments__container table-shipments-per-country__container">
      <div className="shipments-table__table-container">
        <Table withBorder={false} data={data} columns={columns()} t={t} />
      </div>
      {withPagination && rows.length > 0 && +totalPages > 1 && (
        <Pagination pages={totalPages} page={page} loading={loading} />
      )}
    </div>
  );
};

export default TableShipmentsPerCountry;

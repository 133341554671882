export const INVESTIGATIONS = {
  GET: 'INVESTIGATIONS_GET',
  GET_FAIL: 'INVESTIGATIONS_GET_FAIL',
  GET_OK: 'INVESTIGATIONS_GET_OK',
  START: 'INVESTIGATIONS_START',
  START_FAIL: 'INVESTIGATIONS_START_FAIL',
  START_OK: 'INVESTIGATIONS_START_OK',
  TOGGLE_STATUS: 'INVESTIGATIONS_TOGGLE_STATUS',
  TOGGLE_STATUS_FAIL: 'INVESTIGATIONS_TOGGLE_STATUS_FAIL',
  TOGGLE_STATUS_OK: 'INVESTIGATIONS_TOGGLE_STATUS_OK',
  CLEAR_ERRORS: 'INVESTIGATIONS_CLEAR_ERRORS'
};

export default {
  getInvestigations: (page, scope) => ({
    type: INVESTIGATIONS.GET,
    page,
    scope
  }),
  getInvestigationsOk: (scope, investigations) => ({
    type: INVESTIGATIONS.GET_OK,
    featureName: 'getInvestigations',
    scope,
    investigations
  }),
  getInvestigationsFail: error => ({
    type: INVESTIGATIONS.GET_FAIL,
    featureName: 'getInvestigations',
    error
  }),
  startInvestigation: (shipmentId, message) => ({
    type: INVESTIGATIONS.START,
    shipmentId,
    message
  }),
  startInvestigationOk: () => ({
    type: INVESTIGATIONS.START_OK,
    featureName: 'startInvestigation'
  }),
  startInvestigationFail: error => ({
    type: INVESTIGATIONS.START_FAIL,
    featureName: 'startInvestigation',
    error
  }),
  toggleInvestigationStatus: (investigationId, resolved, options) => ({
    type: INVESTIGATIONS.TOGGLE_STATUS,
    investigationId,
    resolved,
    options
  }),
  toggleInvestigationStatusOk: () => ({
    type: INVESTIGATIONS.TOGGLE_STATUS_OK,
    featureName: 'toggleInvestigationStatus'
  }),
  toggleInvestigationStatusFail: error => ({
    type: INVESTIGATIONS.TOGGLE_STATUS_FAIL,
    featureName: 'toggleInvestigationStatus',
    error
  }),
  clearErrors: () => ({
    type: INVESTIGATIONS.CLEAR_ERRORS
  })
};

import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './config/i18n';
import './assets/scss/_bootstrap.scss';
import './assets/scss/_global.scss';

// font
WebFont.load({
  google: {
    families: ['Cabin:400,500,700,900']
  }
});

serviceWorker.unregister();
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

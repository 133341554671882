import { call, put } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { get } from 'lodash';
import { pickDefault, isBlank } from 'utils/textHelper';
import { push } from 'react-router-redux';
import { PER_PAGE } from 'config.js';
import { handleAPIError } from 'utils/sagaUtils';
import {
  getShipmentsRequest,
  searchShipmentsRequest,
  getShipmentRequest,
  createLabelRequest,
  createShipmentRequest,
  cancelShipmentRequest,
  updateApproveStatusRequest,
  updatePickupRequest
} from './api';
import mainActions from '../main/actions';
import shipmentActions from './actions';
import shopActions from '../shop/actions';
import { wrapShop } from '../shop/helper';

export function* getShipments(action) {
  yield put(mainActions.startLoading('getShipments'));
  const {
    scope,
    page,
    sorting,
    isAdmin,
    withAuthor,
    shopId,
    from,
    to,
    byStatus,
    byOrigin
  } = action;
  const loadShop = !isAdmin;
  try {
    const response = yield getShipmentsRequest(
      PER_PAGE.shipments[scope],
      page,
      sorting,
      loadShop,
      withAuthor,
      shopId,
      from,
      to,
      byStatus,
      byOrigin
    );
    const shipmentsWithMeta = get(response, 'data.data.shipmentsWithMeta');
    const rawShop = get(response, 'data.data.shop');
    yield put(shipmentActions.getShipmentsOk(scope, shipmentsWithMeta));
    const shop = wrapShop(rawShop);
    yield put(shopActions.getShopOk(shop));
  } catch (error) {
    yield put(shipmentActions.getShipmentsFail(scope, error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getShipments'));
  }
}

export function* searchShipments(action) {
  yield put(mainActions.startLoading('searchShipments'));
  const { searchKey, page, sorting, isAdmin } = action;
  const loadShop = !isAdmin;
  try {
    const response = yield searchShipmentsRequest(
      searchKey,
      page,
      PER_PAGE.shipments.forSearchPage,
      sorting,
      loadShop
    );
    const data = get(response, 'data.data.search');
    yield put(shipmentActions.searchShipmentsOk(data));
  } catch (error) {
    yield put(shipmentActions.searchShipmentsFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('searchShipments'));
  }
}

export function* getShipment(action) {
  yield call(implementPromiseAction, action, function*() {
    yield put(mainActions.startLoading('getShipment'));

    const { payload: shipmentId } = action;
    yield put(shipmentActions.clearShipment());

    try {
      const response = yield getShipmentRequest(shipmentId);
      const data = get(response, 'data.data.shipment');
      yield put(shipmentActions.getShipmentOk(data));
      return data;
    } catch (error) {
      yield put(shipmentActions.getShipmentFail(error));
      handleAPIError(error);
      throw error;
    } finally {
      yield put(mainActions.stopLoading('getShipment'));
    }
  });
}

export function* createLabel(action) {
  yield put(mainActions.startLoading('createLabel'));
  const { id } = action;
  try {
    yield createLabelRequest(id);
    yield put(shipmentActions.createLabelOk());
    yield put(push('/generated-shipments'));
  } catch (error) {
    yield put(shipmentActions.createLabelFail(error));
    handleAPIError(error, 'createLabel');
  } finally {
    yield put(mainActions.stopLoading('createLabel'));
  }
}

export function* updatePickup(action) {
  yield call(implementPromiseAction, action, function*() {
    yield put(mainActions.startLoading('updatePickup'));
    let { payload: params } = action;
    params = pickDefault(params, Object.keys(params), '');

    if (params.network !== 'UPS') {
      const shipFromHouseNumber = [params.houseNumber];
      if (!isBlank(params.houseAddition))
        shipFromHouseNumber.push(params.houseAddition);
      params.shipFromHouseNumber = shipFromHouseNumber.join(' ');
    }

    try {
      yield updatePickupRequest(params);
    } catch (error) {
      yield put(shipmentActions.updatePickupFail(error));
      handleAPIError(error, 'updatePickup');
      throw error;
    } finally {
      yield put(mainActions.stopLoading('updatePickup'));
    }

    yield put(shipmentActions.updatePickupOk());
    yield put(mainActions.setShowSuccessPickupUpdateModal(true));
    yield put(push(`/shipments/${params.id}`));
  });
}

export function* createShipment(action) {
  yield call(implementPromiseAction, action, function*() {
    const { payload: values } = action;

    try {
      const { houseNumber, houseNumberSuffix } = values;
      const shipFromHouseNumber = [houseNumber];
      if (houseNumberSuffix) shipFromHouseNumber.push(houseNumberSuffix);
      const variables = {
        ...values,
        shipFromHouseNumber: shipFromHouseNumber.join(' ')
      };
      yield createShipmentRequest(variables);
    } catch (error) {
      handleAPIError(error, 'createShipment');
      throw error;
    }

    return null;
  });
}

export function* cancelShipment(action) {
  yield put(mainActions.startLoading('cancelShipment'));
  const { shipmentId, shopId } = action;
  try {
    yield cancelShipmentRequest(shipmentId, shopId);
    yield put(shipmentActions.cancelShipmentOk());
    yield put(push(`/shipments/${shipmentId}`));
  } catch (error) {
    handleAPIError(error, 'cancelShipment');
  } finally {
    yield put(mainActions.stopLoading('cancelShipment'));
  }
}

export function* updateApproveStatus(action) {
  yield put(mainActions.startLoading('updateApproveStatus'));
  const {
    shipmentId,
    status,
    locationPackages,
    userShouldntPay
  } = action.values;
  try {
    yield updateApproveStatusRequest(
      shipmentId,
      status,
      locationPackages,
      userShouldntPay
    );
    yield put(shipmentActions.updateApproveStatusOk());
    yield put(shipmentActions.getShipment(shipmentId));
  } catch (error) {
    handleAPIError(error, 'updateApproveStatus');
  } finally {
    yield put(mainActions.stopLoading('updateApproveStatus'));
  }
}

export function* updateHandledStatus(action) {
  yield put(mainActions.startLoading('updateApproveStatus'));
  const { shipmentId, status, locationId, userShouldntPay } = action.values;
  try {
    yield updateApproveStatusRequest(
      shipmentId,
      status,
      locationId,
      userShouldntPay
    );
    yield put(shipmentActions.updateApproveStatusOk());
    yield put(shipmentActions.getShipment(shipmentId));
  } catch (error) {
    handleAPIError(error, 'updateApproveStatus');
  } finally {
    yield put(mainActions.stopLoading('updateApproveStatus'));
  }
}

/* eslint-disable no-return-assign */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { get, isNil, isEmpty, debounce } from 'utils/lodash';
import { dispatch } from 'store.js';
import {
  Field as ReduxFormField,
  reduxForm,
  getFormValues,
  initialize
} from 'redux-form';
import shipmentActions from 'features/shipment/actions';
import addressActions from 'features/address/actions';
import timeslotActions from 'features/timeslot/actions';
import mainActions from 'features/main/actions';
import { addTimeslot } from 'utils/formatters';
import ModalSuccess from 'components/ModalSuccess';
import Button from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import Field from 'components/Field';
import FieldSelect from 'components/FieldSelect';
import { required } from 'utils/validations';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import { isBlank } from 'utils/textHelper';
import getOrderFlags from 'utils/getOrderFlags';
import ConfirmCancelModal from './ConfirmCancelModal';
import './index.scss';

const paramsNames = [
  'id',
  'start',
  'stop',
  'orderNumber',
  'email',
  'name',
  'numberOfBoxes',
  'comments',
  'shipFromStreet',
  'shipFromHouseNumber',
  'shipFromCity',
  'shipFromPostalCode',
  'shipFromAddressLine',
  'shipFromCountryCode',
  'shop',
  'timeslotId',
  'network'
];

const ShipmentEditContainer = props => {
  const { id: shipmentId } = get(props, 'match.params');
  const {
    t,
    history,
    onRemoveError,
    change,
    submitting,
    handleSubmit,
    values
  } = props;

  const [shipment, setShipment] = useState();
  const [timeslots, setTimeslots] = useState([]);
  const [formError, setFormError] = useState();
  const [apiError, setApiError] = useState();
  const [timeslotsPostalCode, setTimeslotsPostalCode] = useState();
  const [state, setState] = useState({
    errors: null,
    showCancelModal: false,
    silentUpdate: true
  });
  useEffect(() => {
    setFormError();
    // eslint-disable-next-line
  }, [values]);
  const updateState = (vals = {}) => setState({ ...state, ...vals });

  const { errors, serverError, showCancelModal, loading } = state;

  const shopId = get(values, 'shop.id');
  const { isLocal, isUps, isDhl } = getOrderFlags({
    values: shipment
  });
  const status = get(shipment, 'status');
  const shipFromPostalCode = get(values, 'shipFromPostalCode');
  const houseNumber = get(values, 'houseNumber');
  const houseAddition = get(values, 'houseAddition');

  const setFormattedFormError = e => {
    const errMsgKey = e.response.data.errors[0].message;
    const errMsg = t(errMsgKey);
    setFormError(errMsg);
  };

  const setFormattedApiError = e => {
    const errMsgKey = e.response.data.errors[0].message;
    const errMsg = t(errMsgKey);
    setApiError(errMsg);
  };

  const fetchTimeslots = async attrs => {
    dispatch(mainActions.resetFeatureErrors());
    if (!attrs) return null;

    let postalCode = '*'; // for UPS

    if (isLocal) {
      postalCode = attrs.shipFromPostalCode;
      if (postalCode.length !== 6) return null;
    }

    const payload = {
      postalCode,
      network: attrs.network,
      shopId: get(attrs, 'shop.id')
    };

    let data;
    try {
      data = await dispatch(timeslotActions.getTimeslots(payload));
    } catch (e) {
      return setFormattedFormError(e);
    }

    const allTimeslots = addTimeslot(data, {
      id: attrs.timeslotId,
      start: attrs.start,
      stop: attrs.stop
    });

    setTimeslotsPostalCode(attrs.shipFromPostalCode);
    setTimeslots(allTimeslots);
    change('timeslotId', attrs.timeslotId);

    return null;
  };

  const debouncedFetchTimeslots = useCallback(debounce(fetchTimeslots, 250), [
    !isEmpty(values)
  ]);

  const getShipment = async () => {
    const data = await dispatch(
      shipmentActions.getShipment(shipmentId, paramsNames)
    );
    setShipment(data);

    const [
      shipmentHouseNumber,
      shipmentHouseAddition
    ] = data.shipFromHouseNumber ? data.shipFromHouseNumber.split(/\W/, 2) : [];

    const payload = {
      ...data,
      houseNumber: shipmentHouseNumber,
      houseAddition: shipmentHouseAddition
    };
    await dispatch(initialize('shipmentEditForm', payload));
  };

  useEffect(() => {
    getShipment();
    // eslint-disable-next-line
  }, []);

  const formRef = useRef();

  const fetchAddress = async attrs => {
    dispatch(mainActions.resetFeatureErrors());
    if (!attrs) return null;

    if (attrs.shipFromPostalCode.length !== 6 || isBlank(attrs.houseNumber))
      return null;

    const houseNumberParts = [attrs.houseNumber];
    if (!isBlank(attrs.houseAddition))
      houseNumberParts.push(attrs.houseAddition);

    const payload = {
      postalCode: attrs.shipFromPostalCode,
      houseNumber: houseNumberParts.join(' ')
    };

    let data;
    try {
      data = await dispatch(addressActions.getAddress(payload));
    } catch (e) {
      return setFormattedFormError(e);
    }
    if (!data) return null;

    change('shipFromCity', data.city);
    change('shipFromStreet', data.streetName);

    return null;
  };

  const debouncedFetchAddress = useCallback(debounce(fetchAddress, 250), [
    !isEmpty(values)
  ]);

  useEffect(() => {
    debouncedFetchTimeslots(values);
    // eslint-disable-next-line
  }, [shipFromPostalCode]);

  useEffect(() => {
    debouncedFetchAddress(values);
    // eslint-disable-next-line
  }, [shipFromPostalCode, houseNumber, houseAddition]);

  const toggle = name => {
    const value = get(state, name);
    updateState({ [name]: !value });
  };

  const generateLabel = () => dispatch(shipmentActions.createLabel(shipmentId));

  const edit = async (silentUpdate = true) => {
    dispatch(mainActions.resetFeatureErrors());
    if (!values) return null;

    try {
      await dispatch(
        shipmentActions.updatePickup({
          ...values,
          silentUpdate,
          userShouldntPay: true
        })
      );
    } catch (e) {
      return setFormattedApiError(e);
    }
  };

  const cancel = () => {
    if (!shopId) return null;

    toggle('showCancelModal');
    return dispatch(shipmentActions.cancelShipment(shipmentId, shopId));
  };

  if (isEmpty(values)) return <div />;

  if (!shipmentId) return <div />;

  return (
    <>
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '98%',
          height: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        main={
          <>
            <Breadcrumbs>
              <Breadcrumbs.Item
                onClick={() => history.push('/overview')}
                tabIndex={0}
                key={0}
              >
                {t('general.overview')}
              </Breadcrumbs.Item>
              <Breadcrumbs.Item
                onClick={() => history.push('/shipments')}
                tabIndex={-1}
                key={-1}
              >
                {t('general.overviewShipment')}
              </Breadcrumbs.Item>
              <Breadcrumbs.Item
                onClick={() => history.push(`/shipments/${shipmentId}`)}
                tabIndex={-2}
                key={-2}
              >
                {t('general.shipment')} {values.orderNumber}
              </Breadcrumbs.Item>
              <Breadcrumbs.Item tabIndex={-3} key={-3}>
                {t('general.editShipment')}
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <form
              style={{
                width: '55vw',
                display: 'flex',
                flexGrow: '1'
              }}
              onSubmit={handleSubmit(edit)}
              ref={formRef}
            >
              <div className="shipment-edit__container">
                <div className="shipment-edit__info">
                  <ContentBox
                    style={{
                      minWidth: 'calc(50% - 2.5rem)',
                      width: 'calc(50% - 2.5rem)',
                      marginRight: '2.5rem'
                    }}
                    containerStyle={{
                      flexDirection: 'column',
                      minHeight: 'calc(100% - 2.5rem)',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('shipments.shipmentDetails')}
                        />
                      }
                      content={
                        <>
                          <ReduxFormField
                            className="other-class"
                            component={Field}
                            errors={[get(errors, 'name')]}
                            label={t('shipments.name')}
                            name="name"
                            validate={[required]}
                            onChange={() => {
                              if (get(errors, 'name')) {
                                onRemoveError('name');
                              }
                            }}
                          />
                          <ReduxFormField
                            className="other-class"
                            component={Field}
                            errors={[get(errors, 'email')]}
                            label={t('shipments.email')}
                            name="email"
                            validate={[required]}
                            onChange={() => {
                              if (get(errors, 'email')) {
                                onRemoveError('email');
                              }
                            }}
                          />
                          <ReduxFormField
                            className=""
                            component={FieldSelect}
                            style={{ zIndex: 2 }}
                            prompt={t('form.selectTimeslot')}
                            options={timeslots}
                            errors={[get(errors, 'timeslotId')]}
                            label={t('shipments.timeslotsFor', {
                              postalCode: timeslotsPostalCode
                            })}
                            name="timeslotId"
                            validate={[required]}
                            change={change}
                            onChange={() => {
                              if (get(errors, 'timeslotId')) {
                                onRemoveError('timeslotId');
                              }
                            }}
                          />
                        </>
                      }
                    />
                  </ContentBox>
                  <ContentBox
                    style={{
                      minWidth: 'calc(50% - 2.5rem)',
                      width: 'calc(50% - 2.5rem)',
                      marginRight: '2.5rem'
                    }}
                    containerStyle={{
                      flexDirection: 'column',
                      minHeight: 'calc(100% - 2.5rem)',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('shipments.addressInf')}
                        />
                      }
                      content={
                        <>
                          <ReduxFormField
                            className="other-class"
                            inputStyle={{ textTransform: 'uppercase' }}
                            component={Field}
                            errors={[get(errors, 'shipFromPostalCode')]}
                            label={t('shipments.postCode')}
                            name="shipFromPostalCode"
                            validate={[required]}
                            onChange={() => {
                              if (get(errors, 'shipFromPostalCode')) {
                                onRemoveError('shipFromPostalCode');
                              }
                            }}
                          />
                          <ReduxFormField
                            disabled={isLocal}
                            className="other-class"
                            component={Field}
                            errors={[get(errors, 'shipFromCity')]}
                            label={t('shipments.city')}
                            name="shipFromCity"
                            validate={[required]}
                            onChange={() => {
                              if (get(errors, 'shipFromCity')) {
                                onRemoveError('shipFromCity');
                              }
                            }}
                          />
                          {isLocal && isDhl && (
                            <>
                              <ReduxFormField
                                disabled={isLocal}
                                className="other-class"
                                component={Field}
                                errors={[get(errors, 'shipFromStreet')]}
                                label={t('shipments.street')}
                                name="shipFromStreet"
                                validate={[required]}
                                onChange={() => {
                                  if (get(errors, 'shipFromStreet')) {
                                    onRemoveError('shipFromStreet');
                                  }
                                }}
                              />
                              <ReduxFormField
                                className="other-class"
                                component={Field}
                                errors={[get(errors, 'houseNumber')]}
                                label={t('shipments.houseNum')}
                                name="houseNumber"
                                validate={[required]}
                                onChange={() => {
                                  if (get(errors, 'houseNumber')) {
                                    onRemoveError('houseNumber');
                                  }
                                }}
                              />
                              <ReduxFormField
                                className="other-class"
                                component={Field}
                                errors={[get(errors, 'houseAddition')]}
                                label={t('shipments.houseAddition')}
                                name="houseAddition"
                                onChange={() => {
                                  if (get(errors, 'houseAddition')) {
                                    onRemoveError('houseAddition');
                                  }
                                }}
                              />
                            </>
                          )}
                          {!isLocal && isUps && (
                            <ReduxFormField
                              className="other-class"
                              component={Field}
                              errors={[get(errors, 'shipFromAddressLine')]}
                              label={t('shipments.streetAddress')}
                              name="shipFromAddressLine"
                              validate={[required]}
                              onChange={() => {
                                if (get(errors, 'shipFromAddressLine')) {
                                  onRemoveError('shipFromAddressLine');
                                }
                              }}
                            />
                          )}
                        </>
                      }
                    />
                  </ContentBox>
                </div>
                <div className="shipment-edit__errors">
                  <p>{formError}</p>
                </div>
                <div className="shipment-edit__buttons">
                  <div className="shipment-edit__buttons-container">
                    <Button
                      disabled={submitting || loading || status === 'cancelled'}
                      text={
                        status === 'cancelled'
                          ? t('shipments.cancelled')
                          : t('shipments.cancel')
                      }
                      onClick={() => toggle('showCancelModal')}
                      className="button--danger shipment-edit__button"
                      style={{
                        minHeight: '3.0625rem',
                        height: '3.0625rem'
                      }}
                    />
                    {isDhl && isLocal && (
                      <Button
                        disabled={submitting || loading}
                        text={t('shipments.generateLabel')}
                        onClick={generateLabel}
                        className="button--primary shipment-edit__button"
                        style={{
                          minHeight: '3.0625rem',
                          height: '3.0625rem'
                        }}
                      />
                    )}
                  </div>
                  <div className="shipment-edit__buttons-container">
                    <Button
                      disabled={submitting || loading || formError}
                      text={t('shipments.save')}
                      onClick={handleSubmit(() => edit(true))}
                      className="button--primary shipment-edit__button"
                      style={{ minHeight: '3.0625rem', height: '3.0625rem' }}
                    />
                    <Button
                      disabled={submitting || loading || formError}
                      text={t('shipments.saveWithEmail')}
                      onClick={handleSubmit(() => edit(false))}
                      className="button--primary shipment-edit__button"
                      style={{ minHeight: '3.0625rem', height: '3.0625rem' }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
      <ConfirmCancelModal
        visible={showCancelModal}
        onClose={() => toggle('showCancelModal')}
        onProceed={cancel}
        t={t}
      />
      <ModalSuccess
        visible={apiError}
        onButtonClick={() => setApiError()}
        text={apiError}
        buttonText={t('errors.closeButtonText')}
      />
      <ModalSuccess
        visible={!isNil(serverError)}
        onButtonClick={() => dispatch(mainActions.clearServerError())}
        text={
          <>
            {t(serverError)} {t('errors.contactAdmin')}
          </>
        }
        buttonText={t('errors.closeButtonText')}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {
    shipment: { shipment },
    address: { city, streetName },
    auth: { user },
    main: { serverError }
  } = state;

  return {
    shipment,
    user,
    city,
    streetName,
    values: getFormValues('shipmentEditForm')(state),
    serverError
  };
};

export default compose(
  withRouter,
  withTranslation(),
  reduxForm({
    form: 'shipmentEditForm'
  }),
  connect(mapStateToProps)
)(ShipmentEditContainer);

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';

function ReturnType(props = {}) {
  const { disabled, errors, validate = [], change, t } = props;

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      disabled={disabled}
      options={[
        { text: t('shipments.dropoff'), value: 'dropoff' },
        { text: t('shipments.pickup'), value: 'pickup' }
      ]}
      prompt={t('form.selectReturnType')}
      errors={[get(errors, 'returnType')]}
      label={t('shipments.returnType')}
      name="returnType"
      validate={validate}
      change={change}
    />
  );
}
export default withTranslation()(ReturnType);

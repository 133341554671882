import React from 'react';
import Button from 'components/Button';
import LoaderCSS from 'components/LoaderCSS';
import DateFilterForm from 'components/DateFilterForm';

function DateRangeForm({
  from,
  to,
  onDateChange,
  onSubmit,
  t,
  loading = false
}) {
  return (
    <div className="date-form" style={{ display: 'flex' }}>
      <DateFilterForm
        t={t}
        setFrom={day => onDateChange('from', day)}
        setTo={day => onDateChange('to', day)}
      />
      <Button
        disabled={loading}
        onClick={() => onSubmit(from, to)}
        text={t('stats.load')}
        className="button--secondary"
        style={{
          height: '2.65rem',
          position: 'relative',
          bottom: '.1rem'
        }}
      />
      <LoaderCSS visible={loading} />
    </div>
  );
}

export default DateRangeForm;

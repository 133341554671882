import { NOTES } from './actions';

export const INITIAL_STATE = {
  forOverviewPage: {
    notes: []
  },
  forProfilePage: {
    notes: []
  },
  forShipmentDetailsPage: {
    notes: []
  },
  error: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { notes, error, pageName } = action;
  switch (action.type) {
    case NOTES.GET_OK:
      return {
        ...state,
        [pageName]: {
          notes
        },
        error: null
      };
    case NOTES.GET_FAIL:
      return {
        ...state,
        [pageName]: INITIAL_STATE[pageName],
        error
      };
    case NOTES.ADD_FAIL:
      return {
        ...state,
        error
      };
    default:
      return state;
  }
};

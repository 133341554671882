import { isBlank } from 'utils/textHelper';

export const required = value => (value ? undefined : 'This field is required');
export const minLength = min => value =>
  value && value.length >= min
    ? undefined
    : `Meer dan ${min} karakters benodigd`;
export const maxLength = max => value =>
  value && value.length <= max
    ? undefined
    : `Meer dan ${max} karakters benodigd`;
export const email = value => {
  if (isBlank(value)) return undefined;
  return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value.trim()
  )
    ? 'Ongeldig e-mailadres'
    : undefined;
}
  
export const repeated = first => value =>
  value && value === first ? undefined : 'De wachtwoorden komen niet overeen';
export const alreadyExists = (items, key) => value =>
  items.some(item => item[key] === value)
    ? 'E-mailadres bestaat al'
    : undefined;

export const strongPassword = value =>
  value &&
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/.test(value)
    ? undefined
    : 'Password must be strong enough';

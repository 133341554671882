import { get, isNil, isEmpty } from 'lodash';

const flags = ({ shop, config, values }) => {
  if (!values || isEmpty(values)) return {};

  const network = get(values, 'network');
  const returnType = get(values, 'returnType');
  const shipFromCountryCode = get(values, 'shipFromCountryCode');

  const isLocal = shipFromCountryCode === 'nl';
  const isDropoff = returnType === 'dropoff';
  const isInternational =
    !isNil(shipFromCountryCode) && shipFromCountryCode !== 'nl';
  const isDpd = network === 'DPD';
  const isPickup = returnType === 'pickup';
  const isDhl = network === 'DHL';
  const isUps = network === 'UPS';
  const isUS = isUps && shipFromCountryCode === 'us';
  const isIE = isUps && shipFromCountryCode === 'ie';
  const simpleShipments = get(shop, 'simpleLabels');
  const showPersonalFieldsForDropoff = get(
    shop,
    'showPersonalFieldsForDropoff'
  );

  const hubLocations = get(
    config,
    `hubLocations.${network?.toLowerCase()}`,
    []
  );

  const showFromFields = () => {
    if (isPickup) return true;
    if (isLocal) return showPersonalFieldsForDropoff;

    return !(
      isUps &&
      simpleShipments &&
      hubLocations.includes(shipFromCountryCode)
    );
  };

  return {
    isUps,
    isDhl,
    isDpd,
    isPickup,
    isDropoff,
    isLocal,
    isInternational,
    isUS,
    isIE,
    showFromFields: showFromFields()
  };
};

export default flags;

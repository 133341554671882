import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import './index.scss';

const DateFilterForm = props => {
  const { t, setFrom, setTo, disabled = false } = props;


  return (
    <div className="filter-date-form">
      <DayPickerInput
        disabled={disabled}
        onDayChange={setFrom}
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD/MM/YYYY"
        placeholder={t('general.from')}
        classNames={{
          container: 'date-input__container',
          overlay: 'date-input__overlay'
        }}
      />
      <DayPickerInput
        disabled={disabled}
        onDayChange={setTo}
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD/MM/YYYY"
        placeholder={t('general.to')}
        classNames={{
          container: 'date-input__container',
          overlay: 'date-input__overlay'
        }}
      />
    </div>
  );
};

export default DateFilterForm;

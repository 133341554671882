import { INVESTIGATIONS } from './actions';

export const INITIAL_STATE = {
  investigations: {
    unresolved: { data: [], totalPages: 1, page: 1 },
    resolved: { data: [], totalPages: 1, page: 1 },
    all: { data: [], totalPages: 1, page: 1 }
  },
  errors: {}
};

export default (state = INITIAL_STATE, action = {}) => {
  const { scope, investigations, error, featureName } = action;
  switch (action.type) {
    case INVESTIGATIONS.GET_OK:
      return {
        ...state,
        investigations: { ...state.investigations, [scope]: investigations },
        errors: { ...state.errors, [featureName]: null }
      };
    case INVESTIGATIONS.GET_FAIL:
      return {
        ...state,
        investigations: {
          ...state.investigations,
          [scope]: INITIAL_STATE.investigations[scope]
        },
        errors: { ...state.errors, [featureName]: error }
      };
    case INVESTIGATIONS.START_OK:
    case INVESTIGATIONS.TOGGLE_STATUS_OK:
      return {
        ...state,
        errors: { ...state.errors, [featureName]: null }
      };
    case INVESTIGATIONS.START_FAIL:
    case INVESTIGATIONS.TOGGLE_STATUS_FAIL:
      return {
        ...state,
        errors: { ...state.errors, [featureName]: error }
      };
    case INVESTIGATIONS.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { connect, useSelector } from 'react-redux';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { get, isNil, debounce } from 'lodash';
import { isBlank } from 'utils/textHelper';
import { reduxForm, getFormValues, initialize } from 'redux-form';
import shopActions from 'features/shop/actions';
import Button from 'components/Button';
import shipmentActions from 'features/shipment/actions';
import addressActions from 'features/address/actions';
import timeslotActions from 'features/timeslot/actions';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import { required, email as emailValidation } from 'utils/validations';
import flags from 'utils/flags';
import userFlags from 'utils/userFlags';
import { getConfig, getShop as shopSelector, getUser } from 'utils/selectors';
import './index.scss';
import ReturnType from './Fields/ReturnType';
import Network from './Fields/Network';
import Email from './Fields/Email';
import NumberOfBoxes from './Fields/NumberOfBoxes';
import OrderNumber from './Fields/OrderNumber';
import Locale from './Fields/Locale';
import Name from './Fields/Name';
import ShipFromCountryCode from './Fields/ShipFromCountryCode';
import ShipFromPostalCode from './Fields/ShipFromPostalCode';
import HouseNumber from './Fields/HouseNumber';
import HouseNumberSuffix from './Fields/HouseNumberSuffix';
import ShipFromStreet from './Fields/ShipFromStreet';
import ShipFromStateProvinceCode from './Fields/ShipFromStateProvinceCode';
import ShipFromAddressLine from './Fields/ShipFromAddressLine';
import Timeslot from './Fields/Timeslot';
import Location from './Fields/Location';
import Shop from './Fields/Shop';
import ShipFromCity from './Fields/ShipFromCity';
import isEmpty from 'lodash/isEmpty';

const GenerateShipmentContainer = props => {
  const {
    errors,
    values,
    submitting,
    pristine,
    change,
    handleSubmit,
    shipment,
    t
  } = props;
  const { shipmentId } = useParams();

  const shop = useSelector(shopSelector);
  const user = useSelector(getUser);
  const config = useSelector(getConfig);
  const history = useHistory();
  const { isAdmin, isShopAdmin } = userFlags(user);
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState({});
  const [shopOptions, setShopOptions] = useState([]);
  const updateLoading = (key, value) =>
    setLoading({ ...loading, [key]: value });
  const [timeslots, setTimeslots] = useState([]);

  const showNameField = get(shop, 'showNameField', false);
  const locations = get(shop, 'locations', []);

  const defaultLocation = locations.find(location => location.default);
  const deaultLocationId = get(defaultLocation, 'id');

  useEffect(() => {
    if (defaultLocation) change('locationId', +deaultLocationId);
  }, [deaultLocationId]);

  const {
    isLocal,
    isInternational,
    isUps,
    isDhl,
    isPickup,
    isDropoff,
    isDpd,
    isHomerr,
    isBrenger,
    showFromFields
  } = flags({ shop, config, values });

  const {
    network,
    returnType,
    shipFromPostalCode,
    shipFromCountryCode,
    houseNumber,
    shopId
  } = values || {};

  const getShop = async () => {
    updateLoading('getShop', true);
    setApiError();

    try {
      await dispatch(shopActions.getShop(shopId));
    } catch (err) {
      setApiError(err.message);
    } finally {
      updateLoading('getShop', false);
    }
  };

  const getShops = async () => {
    updateLoading('getShops', true);
    setApiError();
    let options;
    try {
      options = await dispatch(shopActions.getShops());
    } catch (err) {
      setApiError(err.message);
    } finally {
      updateLoading('getShops', false);
    }

    
    setShopOptions(options);
    if (isShopAdmin && !isEmpty(options)) change('shopId', options[0].value);
  };

  const getShipment = async () => {
    updateLoading('getShipment', true);
    setApiError();
    try {
      await dispatch(shipmentActions.getShipment(shipmentId));
    } catch (err) {
      setApiError(err.message);
    } finally {
      updateLoading('getShipment', false);
    }
  };

  const initialGet = async () => {
    if (shipmentId) await getShipment();
    await getShops();
  };

  useEffect(() => {
    initialGet();
  }, []);

  useEffect(() => {
    if (!shipment) return;

    change('shopId', shipment.shop.id);
  }, [shipment]);

  useEffect(() => {
    if (!shopId) return;

    getShop();
  }, [shopId]);

  useEffect(() => {
    if (!shop) return;

    if (shipmentId) {
      if (!shipment) return;

      const params = { ...shipment, shopId: shipment.shop.id };
      dispatch(initialize('generateShipmentForm', params));
      return;
    }

    dispatch(
      initialize('generateShipmentForm', {
        locale: 'nl',
        numberOfBoxes: 1,
        locationId: +get(defaultLocation, 'id'),
        shopId,
        returnType: 'dropoff'
      })
    );
  }, [shop]);

  useEffect(() => {
    if (!network || !returnType) return;

    if (isDhl && isPickup) change('shipFromCountryCode', 'nl');
    if (isDhl && !isLocal && isDropoff) change('shipFromCountryCode', 'nl');
    if (isUps && isPickup && isLocal) change('shipFromCountryCode', null);
  }, [network, returnType]);

  const fetchTimeslots = async () => {
    if (isBlank(network) || !isPickup) return;

    const params = {
      network,
      shopId
    };

    if (isDhl) {
      if (isBlank(shipFromPostalCode) || shipFromPostalCode.length !== 6)
        return;

      params.postalCode = shipFromPostalCode;
    }

    updateLoading('getTimeslots', true);
    setApiError();
    let newTimeslots;

    try {
      newTimeslots = await dispatch(timeslotActions.getTimeslots(params));
    } catch (err) {
      setApiError(err.message);
    } finally {
      updateLoading('getTimeslots', false);
    }

    setTimeslots(newTimeslots);
  };

  const debouncedFetchTimeslots = debounce(fetchTimeslots, 250);

  useEffect(() => {
    debouncedFetchTimeslots();
  }, [network, returnType]);

  useEffect(() => {
    if (!isDhl) return;

    debouncedFetchTimeslots();
  }, [shipFromPostalCode]);

  useEffect(() => {
    debouncedFetchTimeslots();
  }, [shipFromCountryCode]);

  // only local addresses
  const fetchAddress = async () => {
    if (isBlank(shipFromPostalCode) || isBlank(houseNumber)) return;
    if (shipFromPostalCode.length !== 6) return;

    updateLoading('fetchAddress', true);
    setApiError();
    let address;
    try {
      address = await dispatch(
        addressActions.getAddress({
          postalCode: shipFromPostalCode,
          houseNumber
        })
      );
    } catch (err) {
      setApiError('The combination of postal code and house number is invalid');
      return;
    } finally {
      updateLoading('fetchAddress', false);
    }

    change('shipFromCity', address.city);
    change('shipFromStreet', address.streetName);
  };

  const debouncedFetchAddress = debounce(fetchAddress, 250);

  useEffect(() => {
    if (isInternational) return;

    debouncedFetchAddress();
  }, [shipFromPostalCode, houseNumber]);

  const createShipment = async () => {
    updateLoading('createShipment', true);
    setApiError();

    try {
      await dispatch(shipmentActions.createShipment({ ...values, shopId }));
    } catch (err) {
      setApiError(err.message);
      return;
    } finally {
      updateLoading('createShipment', false);
    }

    history.push('/generated-shipments');
  };

  const shopIsChosen = !isNil(shopId);
  const firstChooseCountry = isNil(shipFromCountryCode);
  const networkIsChosen = !isNil(network);
  const firstChooseShop = isAdmin && !shopIsChosen;
  const commonProps = { change, errors, values };

  const cityField = ({ disabled = false } = {}) => {
    return (
      <ShipFromCity
        {...commonProps}
        disabled={
          firstChooseCountry ||
          (isPickup && !networkIsChosen) ||
          (isPickup && isDhl) ||
          firstChooseShop ||
          disabled
        }
        validate={[required]}
      />
    );
  };

  return (
    <MainContentContainer
      style={{ height: 'fit-content' }}
      mainStyles={{
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      main={
        <>
          <ContentBox
            style={{ minWidth: '61.25rem', minHeight: '29.25rem' }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem'
            }}
          >
            <ContentBox.Row
              loading={loading.getShipment}
              header={
                <ContentBox.Row.Header
                  text={t('shipments.generateShipment')}
                  separated
                >
                  <Button
                    style={{
                      width: 'auto',
                      position: 'relative',
                      bottom: '1.2rem'
                    }}
                    disabled={false}
                    text={t('shipments.allShipments')}
                    className="button--secondary"
                    onClick={() => history.push('/generated-shipments')}
                  />
                </ContentBox.Row.Header>
              }
              content={
                <form
                  onSubmit={handleSubmit(createShipment)}
                  className="create-label-form"
                >
                  {apiError && <div style={{ color: 'red' }}>{apiError}</div>}
                  {shopOptions.length > 1 && (
                    <div
                      style={{ zIndex: 10 }}
                      className="create-label-form__details-fields"
                    >
                      <Shop
                        shopOptions={shopOptions}
                        loading={loading.getShops}
                        selectedValue={shopId}
                        onChange={value => change('shopId', value)}
                      />
                    </div>
                  )}
                  <div
                    style={{ zIndex: 9, marginTop: '1rem' }}
                    className="create-label-form__details-fields"
                  >
                    <ReturnType
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                    />

                    <Network
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                      onChange={() => change('timeslotId', null)}
                    />
                  </div>
                  <div
                    style={{ zIndex: 8 }}
                    className="create-label-form__details-fields"
                  >
                    <Email
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={
                        isLocal
                          ? [required, emailValidation]
                          : [emailValidation]
                      }
                    />

                    <NumberOfBoxes
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                    />

                    <OrderNumber {...commonProps} disabled={firstChooseShop} />

                    <Locale
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                    />

                    {(showFromFields || showNameField) && (
                      <Name
                        {...commonProps}
                        disabled={firstChooseShop}
                        validate={[required]}
                      />
                    )}
                  </div>
                  <div
                    style={{ zIndex: 7 }}
                    className="create-label-form__details-fields"
                  >
                    <ShipFromCountryCode
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                      onChange={value => {
                        const forDhl =
                          (isPickup && value !== 'nl') ||
                          (isDropoff && !['de', 'nl'].includes(value));

                        const forUps = isPickup && value === 'nl';

                        if (
                          (isDhl && forDhl) ||
                          (isUps && forUps) ||
                          (isPickup && (isDpd || isHomerr || isBrenger))
                        )
                          change('network', undefined);
                      }}
                    />

                    {showFromFields && (
                      <>
                        <ShipFromPostalCode
                          {...commonProps}
                          disabled={
                            firstChooseCountry ||
                            (isPickup && !networkIsChosen) ||
                            firstChooseShop
                          }
                          validate={[required]}
                        />
                        {isInternational && cityField()}
                        {isLocal && (
                          <>
                            <HouseNumber
                              {...commonProps}
                              disabled={firstChooseCountry || firstChooseShop}
                              validate={[required]}
                            />

                            <HouseNumberSuffix
                              {...commonProps}
                              disabled={firstChooseCountry || firstChooseShop}
                            />

                            {cityField({ disabled: true })}

                            <ShipFromStreet
                              {...commonProps}
                              disabled
                              validate={[required]}
                            />
                          </>
                        )}
                        <ShipFromStateProvinceCode
                          {...commonProps}
                          disabled={firstChooseShop}
                          validate={[required]}
                        />

                        {isInternational && (
                          <ShipFromAddressLine
                            {...commonProps}
                            disabled={firstChooseCountry || firstChooseShop}
                            validate={[required]}
                          />
                        )}
                        {isPickup && (
                          <Timeslot
                            {...commonProps}
                            disabled={submitting || firstChooseShop}
                            options={timeslots}
                            validate={[required]}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {locations.length > 1 && (
                    <Location
                      {...commonProps}
                      disabled={firstChooseShop}
                      validate={[required]}
                    />
                  )}
                  <div className="create-label-form__actions">
                    <Button
                      style={{ height: '3.75rem', marginTop: '2rem' }}
                      disabled={submitting || (!shipmentId && pristine)}
                      text={t('shipments.generateShipment')}
                      type="submit"
                      className="button--primary"
                    />
                  </div>
                </form>
              }
            />
          </ContentBox>
        </>
      }
    />
  );
};

const mapStateToProps = state => ({
  shipment: get(state, 'shipment.shipment'),
  values: getFormValues('generateShipmentForm')(state)
});

export default compose(
  withRouter,
  withTranslation(),
  reduxForm({
    form: 'generateShipmentForm'
  }),
  connect(mapStateToProps)
)(GenerateShipmentContainer);

import axios from 'axios';
import { baseAuthUrl, graphQlBase, adminApiUrl } from '../config';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'en'
  }
};

export function setApiToken(token) {
  CONFIG.headers.Authorization = token;
  axios.defaults.headers.common.Authorization = token;
}

// AUTH
export function loginRequest(email, password) {
  return axios.post(`${baseAuthUrl}`, { email, password }, CONFIG);
}

// GRAPHQL API CALL
export function post(data = {}) {
  return axios.post(`${graphQlBase}`, data, CONFIG);
}

// CONTROLLERS ENDPOINTS CALLS
export function exportUrl({ from, to, shopId, byStatus, byOrigin, locale }) {
  const token = axios.defaults.headers.common.Authorization;

  const paramsQuery = [`from=${from}`, `to=${to}`, `&token=${token}`];
  if (shopId) paramsQuery.push(`shop_id=${shopId}`);
  if (byStatus) paramsQuery.push(`by_status=${byStatus}`);
  if (byOrigin) paramsQuery.push(`by_origin=${byOrigin}`);
  if (locale) paramsQuery.push(`locale=${locale}`);

  const link = encodeURI(
    `${adminApiUrl}/shipments/export.csv?${paramsQuery.join('&')}`
  );

  return link;
}



import { put } from 'redux-saga/effects';
import { get } from 'lodash';
import { handleAPIError } from 'utils/sagaUtils';
import moment from 'moment';
import {
  getDailyCountersRequest,
  getTotalCountersRequest,
  getShopCountersRequest
} from './api';
import mainActions from '../main/actions';
import shopActions from '../shop/actions';
import { wrapShop } from '../shop/helper';
import statsActions from './actions';

const formatTime = value =>
  value ? moment.parseZone(value).toISOString() : null;

export function* getDailyCounters(action) {
  yield put(mainActions.startLoading('getDailyCounters'));
  const { noDays } = action;

  try {
    const response = yield getDailyCountersRequest(noDays);
    const dailyCounters = get(response, 'data.data.statsDailyCounters');
    yield put(statsActions.getDailyCountersOk(dailyCounters));
  } catch (error) {
    yield put(statsActions.getDailyCountersFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getDailyCounters'));
  }
}

export function* getTotalCounters(action) {
  yield put(mainActions.startLoading('getTotalCounters'));
  const { from, to } = action;

  try {
    const response = yield getTotalCountersRequest(
      formatTime(from),
      formatTime(to)
    );
    const totalCounters = get(response, 'data.data.statsTotalCounters.data');
    const billedCounters = get(response, 'data.data.statsBilledCounters.data');
    yield put(statsActions.getTotalCountersOk(totalCounters, billedCounters));
  } catch (error) {
    yield put(statsActions.getTotalCountersFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getTotalCounters'));
  }
}

export function* getShopCounters(action) {
  const { from, to } = action;
  yield put(mainActions.startLoading('getShopCounters'));

  try {
    const response = yield getShopCountersRequest(
      formatTime(from),
      formatTime(to)
    );
    const respData = get(response, 'data.data');
    const monthlyCounters = get(respData, 'statsMonthlyCounters.data');
    const billedCounters = get(respData, 'statsBilledCounters.data');
    const shopCounters = get(respData, 'statsShopCounters.data');
    const returnReasonCounters = get(
      respData,
      'statsReturnReasonCounters.data'
    );
    const rawShop = get(respData, 'shop');
    const shop = wrapShop(rawShop);
    yield put(
      statsActions.getShopCountersOk(
        monthlyCounters,
        returnReasonCounters,
        billedCounters,
        shopCounters
      )
    );
    yield put(shopActions.getShopOk(shop));
  } catch (error) {
    yield put(statsActions.getShopCountersFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getShopCounters'));
  }
}

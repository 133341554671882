import React from 'react';
import Note from 'components/Note';
import Breadcrumbs from 'components/Breadcrumbs';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import { isNil } from 'lodash';
import './index.scss';

const ShipmentNotes = ({ shipment, history, t }) => {
  if (isNil(shipment)) return null;

  return (
    <MainContentContainer
      style={{ height: 'fit-content' }}
      mainStyles={{
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      main={
        <>
          <ContentBox
            header={
              <Breadcrumbs>
                <Breadcrumbs.Item
                  onClick={() => history.push('/overview')}
                  tabIndex={0}
                >
                  {t('general.overview')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  onClick={() => history.push('/shipments')}
                  tabIndex={-1}
                >
                  {t('general.overviewShipment')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  tabIndex={-2}
                  onClick={() => history.push(`/shipments/${shipment.id}`)}
                >
                  {t('general.shipment')} {shipment.orderNumber}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item tabIndex={-3}>
                  {t('notes.allNotes')}
                </Breadcrumbs.Item>
              </Breadcrumbs>
            }
            style={{
              minWidth: '53.5rem',
              minHeight: '29.25rem',
            }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem',

            }}
          >
            <ContentBox.Row
              header={
                <ContentBox.Row.Header
                  text={
                    <>
                      {t('notes.notes')} - {t('general.shipment')}{' '}
                      {shipment.orderNumber}
                    </>
                  }
                />
              }
              content={
                <div className="shipment-notes__container">
                  {shipment.notes.map((note, index) => (
                    <Note key={index} note={note} t={t} />
                  ))}
                </div>
              }
            />
          </ContentBox>
        </>
      }
    />
  );
};

export default ShipmentNotes;

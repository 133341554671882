import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';
import flags from 'utils/flags';
import { useSelector } from 'react-redux';
import { getShop, getUser } from 'utils/selectors';
import userFlags from 'utils/userFlags';

function Network(props = {}) {
  const {
    disabled,
    errors,
    values,
    validate = [],
    change,
    onChange,
    t
  } = props;

  let networkOptions = [
    { text: 'DHL', value: 'DHL' },
    { text: 'UPS', value: 'UPS' }
  ];

  const shop = useSelector(getShop);
  const user = useSelector(getUser);
  const { isDropoff } = flags({values});
  const { isAdmin } = userFlags(user);

  if (isDropoff) networkOptions.push({ text: 'DPD', value: 'DPD' });

  if (!isAdmin && shop) {
    const { networks = [] } = shop;
    networkOptions = networkOptions.filter(option =>
      networks.includes(option.value.toLowerCase())
    );
  }

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      disabled={disabled}
      options={networkOptions}
      prompt={t('form.selectNetwork')}
      errors={[get(errors, 'network')]}
      label={t('shipments.network')}
      name="network"
      validate={validate}
      change={change}
      onChange={onChange}
    />
  );
}
export default withTranslation()(Network);

export const NOTES = {
  GET: 'NOTES_GET',
  GET_FAIL: 'NOTES_GET_FAIL',
  GET_OK: 'NOTES_GET_OK',
  ADD: 'NOTES_ADD',
  ADD_FAIL: 'NOTES_ADD_FAIL',
  ADD_OK: 'NOTES_ADD_OK'
};

export default {
  getNotes: (pageName, page, { userId, shipmentId }) => ({
    type: NOTES.GET,
    pageName,
    page,
    userId,
    shipmentId
  }),
  getNotesOk: (pageName, notes) => ({
    type: NOTES.GET_OK,
    pageName,
    notes
  }),
  getNotesFail: (pageName, error) => ({
    type: NOTES.GET_FAIL,
    pageName,
    error
  }),
  addNote: (body, shipmentId) => ({
    type: NOTES.ADD,
    body,
    shipmentId
  }),
  addNoteOk: () => ({
    type: NOTES.GET_OK
  }),
  addNoteFail: error => ({
    type: NOTES.GET_FAIL,
    error
  })
};

/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import AuthLayout from 'components/AuthLayout';
import ResetPassword from 'components/ResetPassword';
import profileActions from 'features/profile/actions';
import mainActions from 'features/main/actions';
import { dispatch } from 'store';
import { isEqual } from 'lodash';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    const { token } = props.match.params;
    this.state = {
      token,
      error: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleRemoveError = this.handleRemoveError.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);

    dispatch(profileActions.reset());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { error } = nextProps;

    if (!isEqual(error, prevState.error)) {
      newState = { ...newState, error };
    }

    return newState;
  }

  handleLoginClick() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/login');
  }

  onSubmit({ password }) {
    const { token } = this.state;
    dispatch(profileActions.resetPassword(token, password));
  }

  handleRemoveError() {
    dispatch(mainActions.clearFeatureError('resetPassword'));
  }

  render() {
    const { error, t } = this.props;

    return (
      <AuthLayout modalClass="auth-container__reset-password">
        <ResetPassword
          errors={{ password: [error] }}
          onRemoveError={this.handleRemoveError}
          onSubmit={this.onSubmit}
          t={t}
        />
        <div className="auth-container__switch" style={{ marginTop: '2.5rem' }}>
          <Link to="/">{t('pages.login.back')}</Link>
        </div>
      </AuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  error: state.main.featureErrors.resetPassword
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(ResetPasswordContainer);

/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import React from 'react';
import { Pie } from 'react-chartjs-2';
import ColorScheme from 'color-scheme';
import { langCode } from 'config/i18n';
import { get } from 'lodash';
import './index.scss'

export default function ReturnReasonPie({
  backendData = [],
  returnReasons = {},
  t
}) {
  const generateColors = () => {
    const scheme = new ColorScheme();
    scheme
      .from_hue(0) // Start the scheme
      .scheme('triade') // Use the 'triade' scheme, that is, colors
      // selected from 3 points equidistant around
      // the color wheel.
      .variation('soft'); // Use the 'soft' color variation

    return scheme.colors().map(color => '#' + color);
  };

  const returnReasonsLocale = { ...get(returnReasons, langCode(), {}) };
  if (returnReasonsLocale) returnReasonsLocale[0] = { text: t('stats.others') };
  const chartData = [];
  const chartLabels = [];
  backendData.forEach(reason => {
    const text = get(returnReasonsLocale, `${reason.value}.text`, '');
    chartData.push(reason.counter);
    chartLabels.push(text);
  });

  const data = {
    datasets: [
      {
        data: chartData,
        backgroundColor: generateColors()
      }
    ],

    // These labels appear in the legend and in the tooltips
    // when hovering different arcs
    labels: chartLabels
  };

  return (
    <div>
      <Pie
        data={data}
        width={250}
        height={250}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function(tooltipModel) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  const colors = tooltipModel.labelColors[i];
                  let style = `background-color: ${colors.backgroundColor};`;
                  style += `border-color: ${colors.borderColor};`;
                  const colorPreview = `<span class="label__color-preview" style="${style}"></span>`;
                  innerHtml += `<tr><td class="label">${colorPreview} ${body}</td></tr>`;
                });
                innerHtml += '</tbody>';

                console.log(innerHtml);

                const tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              const position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.backgroundColor = 'hsla(0, 0%, 0%, .7)';
              tooltipEl.style.color = 'hsla(0, 0%, 100%, .9)';
              tooltipEl.style.borderRadius = '.5rem';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          }
        }}
      />
    </div>
  );
}

import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import usePortal from 'hooks/usePortal';
import ButtonClose from 'components/ButtonClose';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000033;
`;

export const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000033;
  display: flex;
  align-items: center;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 11;
  background-color: white;
  border-radius: 2.5rem;
  background-color: white;
  padding: 3.0625rem 9.25rem;
`;

function Modal2({ className, title, onClose, children }) {
  const target = usePortal('modal-root');

  return ReactDOM.createPortal(
    <Overlay>
      <ModalBox className={className}>
        <Header>
          <Title>{title}</Title>
          <ButtonClose onClick={onClose} />
        </Header>
        {children}
      </ModalBox>
    </Overlay>,
    target
  );
}

export default Modal2;

/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFormValues, initialize } from 'redux-form';
import LoaderCSS from 'components/LoaderCSS';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { isBlank, pickDefault, getDefault } from 'utils/textHelper';
import moment from 'moment';
import { langCode } from 'config/i18n';
import { dispatch } from 'store.js';
import mainActions from 'features/main/actions';
import shipmentActions from 'features/shipment/actions';
import SearchBar from 'components/SearchBar';
import Note from 'components/Note';
import Message from 'components/Message';
import Button from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import ContentBox from 'components/ContentBox';
import ModalSuccess from 'components/ModalSuccess';
import MainContentContainer from 'components/MainContentContainer';
import { getReadableTime } from 'utils/formatters';
import ShipmentNotes from './Notes';
import AddNoteModal from './AddNoteModal';
import StartInvestigationForm from './StartInvestigationForm';
import ModalApprove from './ModalApprove';
import ModalImage from './ModalImage';
import './index.scss';
import SuccessUpdateModal from './SuccessUpdateModal';

export class ShipmentDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.history = props.history;
    this.editShipment = this.editShipment.bind(this);
    this.generateShipment = this.generateShipment.bind(this);
    this.trackShipment = this.trackShipment.bind(this);
    this.startResearch = this.startResearch.bind(this);
    this.updateApproveStatus = this.updateApproveStatus.bind(this);
    this.state = {
      user: null,
      shop: null,
      shipmentId: props.match.params.id,
      shipment: null,
      addNote: false,
      errors: {},
      loading: {},
      showConfirmationInvestigation: false,
      values: null,
      showImageModal: false
    };

    this.escFunction = this.escFunction.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { shipment, loading, user, values } = nextProps;

    if (!isEqual(values, prevState.values)) {
      newState = { ...newState, values };
    }

    if (!isEqual(user, prevState.user)) {
      newState = { ...newState, user };
    }

    if (!isEqual(loading, prevState.loading)) {
      newState = { ...newState, loading };
    }

    if (!shipment) {
      newState = {
        ...newState,
        shipment: null,
        shop: null,
        showApproveModal: false
      };
    } else if (!isEqual(shipment, prevState.shipment)) {
      const { shop } = shipment;
      newState = { ...newState, shipment, shop, showApproveModal: false };
      newState.addNote = false;

      // values for startInvestigationForm
      const initialValues = pickDefault(
        shipment,
        ['name', 'email', 'orderNumber', 'network', 'trackingCode'],
        '-'
      );
      const startMoment = moment(shipment.start);
      initialValues.date = startMoment.isValid()
        ? startMoment.format('lll')
        : '-';
      dispatch(initialize('startInvestigationForm', initialValues));

      const locations = get(shop, 'locations');
      const defaultLocationId = locations.find(location => location.default)
        ?.id;
      dispatch(
        initialize('shipmentApproveForm', {
          locationId: defaultLocationId,
          userShouldntPay: shipment.isFree
        })
      );
    }

    return newState;
  }

  escFunction(event) {
    if (event.keyCode !== 27) return;

    this.setState({
      showImageModal: false
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', () => this.escFunction, false);

    const { shipmentId } = this.state;
    dispatch(shipmentActions.getShipment(shipmentId));
    dispatch(mainActions.resetFeatureErrors());
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  getBooleanOrDefault(value) {
    if (isNil(value)) {
      return '-';
    }

    const yes = (
      <span className="badge badge--yes">{this.t('general.yes')}</span>
    );
    const no = <span className="badge badge--no">{this.t('general.no')}</span>;
    return value ? yes : no;
  }

  trackShipment() {
    const { shipment } = this.state;
    return window.open(shipment.trackingUrl, '_blank');
  }

  editShipment() {
    const { shipmentId } = this.state;
    const { history } = this.props;
    return history.push(`/shipments/${shipmentId}/edit`);
  }

  generateShipment() {
    const { shipmentId } = this.state;
    return window.open(`/generate-shipment/${shipmentId}`);
  }

  startResearch() {
    const { shipmentId } = this.state;
    const { history } = this.props;
    return history.push(`/shipments/${shipmentId}/startInvestigation`);
  }

  toggle(name) {
    const value = get(this.state, name);
    this.setState({ [name]: !value });
  }

  renderShipmentFields() {
    const { t } = this.props;
    const { shipment, shop } = this.state;
    if (!shipment) return null;

    const gatekeeperEnabled = get(shop, 'gatekeeperEnabled', false);
    const hasFreeShipments = get(shop, 'hasFreeShipments', false);
    const showDescription = get(shop, 'showDescription', false);
    const insuranceEnabled = get(shop, 'insuranceOptionEnabled', false);

    const {
      orderNumber,
      ref,
      labelUrl,
      status,
      shopName,
      network,
      returnReason,
      approveStatus,
      paymentStatus,
      isFree,
      start,
      stop,
      returnType,
      imageUrl,
      parentUrl,
      childUrls,
      itemDescriptions,
      userShouldntPay
    } = shipment;

    let fields = [
      {
        title: 'network',
        value: network
      }
    ];

    if (showDescription) {
      fields.push({
        title: 'description',
        value: isEmpty(itemDescriptions) ? '-' : itemDescriptions.join(', ')
      });
    }

    if (parentUrl)
      fields.push({
        title: 'parent',
        value: (
          <a
            className="shipment__label-url-link"
            href={parentUrl}
            target="_self"
            rel="noopener noreferrer"
          >
            {this.t('shipments.open')}
          </a>
        )
      });

    if (childUrls && childUrls.length > 0)
      childUrls.forEach((url, index) => {
        fields.push({
          title: 'childLabels',
          value: (
            <a
              className="shipment__label-url-link"
              href={url}
              target="_self"
              rel="noopener noreferrer"
            >
              {[this.t('shipments.open'), 'no', String(index + 1)].join(' ')}
            </a>
          )
        });
      });

    if (returnReason?.defect && imageUrl) {
      fields.push({
        title: 'image',
        value: (
          <button
            className="button button--bubble"
            style={{ width: 'auto', marginTop: '.3rem' }}
            onClick={() => this.setState({ shomImageModal: true })}
          >
            {t('shipments.open')}
          </button>
        )
      });
    }
    fields.push({
      title: 'returnType',
      value: this.t(`shipments.returnTypes.${returnType}`)
    });
    fields.push({ title: 'status', value: this.t(`status.${status}`) });

    if (gatekeeperEnabled) {
      fields.push({
        title: 'approveStatus',
        value: this.t(`statusApprove.${approveStatus}`)
      });
      fields.push({
        title: t('customerWillPay'),
        value: userShouldntPay ? t('general.no') : t('general.yes')
      });
    }

    const formattedReturnReason = getDefault(returnReason, langCode(), '-');
    fields.push({ title: 'returnReason', value: formattedReturnReason });
    const wantExchangeTo = getDefault(shipment, 'wantExchangeTo', false);
    fields.push({ title: 'wantExchangeTo', value: wantExchangeTo });
    const defect = get(shipment, 'defect');
    if (!isBlank(defect)) fields.push({ title: 'defect', value: defect });
    const customReturnReason = get(shipment, 'customReturnReason');
    if (customReturnReason)
      fields.push({ title: 'customReturnReason', value: customReturnReason });

    if (!isBlank(start))
      fields.push({
        title: 'timeslot',
        value: getReadableTime(start, stop)
      });

    const isDhlPickup = returnType === 'pickup' && network === 'DHL';
    if (!isDhlPickup) {
      let value = '-';
      if (!isBlank(labelUrl)) {
        value = (
          <a
            className="shipment__label-url-link"
            href={labelUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.t('shipments.openFile')}
          </a>
        );
      }
      fields.push({
        title: 'labelUrl',
        value
      });
    }

    fields = [
      ...fields,
      { title: 'orderNumber', value: orderNumber || '-' },
      { title: 'trackingCode', value: isBlank(ref) ? '-' : ref },
      { title: 'company', value: shopName }
    ];

    if (!hasFreeShipments) {
      fields.push({
        title: 'paymentsStatus',
        value: paymentStatus || (isFree && 'free') || '-'
      });
    }

    if (insuranceEnabled)
      fields.push({
        title: 'insured',
        value: this.getBooleanOrDefault(get(shipment, 'insured'))
      });

    return fields.map(field => this.renderField(field));
  }

  renderCustomerFields() {
    const { shipment, shop } = this.state;
    const gatekeeperEnabled = get(shop, 'gatekeeperEnabled', false);
    if (!shipment) return null;

    const { address, name, email, comments, phone } = shipment;
    const result = [
      { title: 'email', value: email },
      { title: 'name', value: name || '-' }
    ];
    if (gatekeeperEnabled) result.push({ title: 'phone', value: phone || '-' });
    return [
      ...result,
      { title: 'addressInf', value: address || '-' },

      { title: 'comments', value: comments || '-' }
    ].map(field => this.renderField(field));
  }

  renderField({ title, value }) {
    let formattedValue = value;

    if (typeof value === 'boolean')
      formattedValue = value ? this.t('general.yes') : this.t('general.no');

    return (
      <div className="data-field" key={title}>
        <div className="data-field__title">{this.t(`shipments.${title}`)}</div>
        <div className="data-field__value">{formattedValue}</div>
      </div>
    );
  }

  renderLastNote() {
    const { t } = this.props;
    const { shipment } = this.state;
    if (!shipment) return null;

    const note = last(get(shipment, 'notes'));
    if (!note) return null;

    return <Note note={note} t={t} />;
  }

  renderTrackingUpdates() {
    const { shipment } = this.state;
    return getDefault(shipment, 'trackingUpdates', []).map((update, index) => (
      <Message
        key={index}
        header={
          <div className="badge">
            {update.ref}
            {': '}
            {this.t(`status.${update.status}`)}
          </div>
        }
        body={update.explanation}
        footer={moment(update.timestamp).format('DD/MM/YYYY HH:mm')}
      />
    ));
  }

  updateApproveStatus(approvedStatus, values = {}) {
    const { shipmentId } = this.state;
    dispatch(
      shipmentActions.updateApproveStatus({
        ...values,
        shipmentId,
        status: approvedStatus
      })
    );
  }

  renderMultifunctionalButton() {
    const approveStatus = get(this.props, 'shipment.approveStatus');
    const { loading, user, shop } = this.state;
    const isAdmin = get(user, 'isAdmin', false);

    if (!shop?.gatekeeperEnabled) return <></>;
    if (approveStatus === 'approved') return <></>;
    if (approveStatus === 'declined')
      return (
        <Button
          disabled={isAdmin || loading.getShipment}
          onClick={() => this.setState({ showApproveModal: true })}
          text={this.t('statusApprove.declined')}
          className="button--bubble button--bubble--default"
          style={{ height: '10rem' }}
        />
      );

    if (['pending', 'awaiting_on_customer'].includes(approveStatus))
      return (
        <Button
          disabled={isAdmin || loading.getShipment}
          text={this.t('shipments.approve.dialog.approvalOptions')}
          className="button--bubble button--bubble--default"
          style={{ height: '10rem' }}
          onClick={() => this.setState({ showApproveModal: true })}
        />
      );

    // loading button
    return (
      <Button
        disabled
        text={<LoaderCSS visible />}
        className="button--bubble button--bubble--default"
        style={{ height: '10rem' }}
      />
    );
  }

  // TEST commit, delete it
  render() {
    const {
      t,
      history,
      shipment,
      showNotes,
      startInvestigation,
      noteCreated
    } = this.props;
    const {
      addNote,
      showApproveModal,
      shomImageModal,
      shop,
      loading,
      values
    } = this.state;

    const locations = get(shop, 'locations', []);
    const { orderNumber, returnType, imageUrl } = shipment || {};
    const isPickup = returnType === 'pickup';
    const shipmentId = get(shipment, 'id');
    if (showNotes) return <ShipmentNotes {...{ t, history, shipment }} />;
    if (startInvestigation)
      return <StartInvestigationForm {...{ t, history, shipment }} />;

    return (
      <>
        <MainContentContainer
          style={{ height: '100%' }}
          mainStyles={{
            width: '100%',
            minHeight: '100%',
            height: '100%',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.25rem'
          }}
          main={
            <>
              <Breadcrumbs>
                <Breadcrumbs.Item
                  onClick={() => history.push('/overview')}
                  tabIndex={0}
                  key={0}
                >
                  {t('general.overview')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  onClick={() => history.push('/shipments')}
                  tabIndex={-1}
                  key={-1}
                >
                  {t('general.overviewShipment')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item tabIndex={-2} key={-2}>
                  {t('general.shipment')} {orderNumber}
                </Breadcrumbs.Item>
              </Breadcrumbs>
              <SearchBar t={t} history={history} />
              <div
                className="shipment__container"
                style={{
                  height: 'calc(100vh - 15rem)',
                  width: '100%'
                }}
              >
                <div
                  name="left-column"
                  className="shipment__column"
                  style={{
                    minWidth: 'calc(33.5% - 1.5rem)',
                    width: 'calc(33.5% - 1.5rem)',
                    marginRight: '1.5rem',
                    justifyContent: 'space-between'
                  }}
                >
                  <ContentBox
                    style={{
                      paddingBottom: '1.5rem',
                      height: '80%',
                      flexGrow: 1
                    }}
                    containerStyle={{
                      flexDirection: 'column',
                      height: '100%',
                      borderRadius: '2.5rem',
                      overflow: 'scroll'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('shipments.shipmentDetails')}
                        >
                          {isPickup && (
                            <Button
                              onClick={this.editShipment}
                              iconName="edit"
                              className="button-edit"
                              style={{ width: '1.25rem' }}
                            />
                          )}
                          {shop && !shop?.partner && (
                            <Button
                              onClick={this.generateShipment}
                              iconName="duplicate"
                              className="button-edit"
                              style={{ width: '1.25rem' }}
                            />
                          )}
                        </ContentBox.Row.Header>
                      }
                      content={
                        loading.getShipment ? (
                          <LoaderCSS visible />
                        ) : (
                          this.renderShipmentFields()
                        )
                      }
                    />
                  </ContentBox>
                  {this.renderMultifunctionalButton()}
                </div>
                <div
                  name="central-column"
                  className="shipment__column"
                  style={{
                    minWidth: 'calc(33.5% - 1.5rem)',
                    width: 'calc(33.5% - 1.5rem)',
                    marginRight: '1.5rem'
                  }}
                >
                  <ContentBox
                    style={{
                      paddingBottom: '1.5rem',
                      height: '100%'
                    }}
                    containerStyle={{
                      flexDirection: 'column',
                      minHeight: 'calc(100% - 2.5rem)',
                      height: '100%',
                      borderRadius: '2.5rem',
                      overflow: 'scroll'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('shipments.customerData')}
                        >
                          {isPickup && (
                            <Button
                              onClick={this.editShipment}
                              iconName="edit"
                              className="button-edit"
                              style={{ width: '1.25rem' }}
                            />
                          )}
                        </ContentBox.Row.Header>
                      }
                      content={
                        loading.getShipment ? (
                          <LoaderCSS visible />
                        ) : (
                          this.renderCustomerFields()
                        )
                      }
                    />
                  </ContentBox>
                  <ContentBox
                    containerStyle={{
                      flexDirection: 'column',
                      minHeight: '100%',
                      width: '100%',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('notes.notes')}
                          style={{ width: 'auto' }}
                        >
                          <Button
                            iconName="newNote"
                            text={this.t('notes.addNote')}
                            className="button--secondary width--auto"
                            style={{
                              // height: 'calc(50% - 1.25rem)',
                              marginRight: '.5rem'
                            }}
                            onClick={() => this.toggle('addNote')}
                          />
                          {getDefault(shipment, 'notes', []).length > 1 && (
                            <Button
                              text={t('notes.allNotes')}
                              onClick={() =>
                                history.push(`/shipments/${shipmentId}/notes`)
                              }
                              className="button--secondary width--auto"
                            />
                          )}
                        </ContentBox.Row.Header>
                      }
                      content={
                        loading.getShipment ? (
                          <LoaderCSS visible />
                        ) : (
                          this.renderLastNote()
                        )
                      }
                    />
                  </ContentBox>
                </div>
                <div
                  name="tracking-updates"
                  className="shipment__column"
                  style={{
                    minWidth: '33%',
                    width: '33%'
                  }}
                >
                  <ContentBox
                    className="tracking-updates"
                    containerStyle={{
                      flexDirection: 'column',
                      width: '100%',
                      borderRadius: '2.5rem',
                      height: '100%'
                    }}
                    style={{
                      height: '100%'
                    }}
                  >
                    <ContentBox.Row
                      style={{ overflow: 'scroll', height: '100%' }}
                      header={
                        <ContentBox.Row.Header text={t('shipments.history')} />
                      }
                      content={
                        loading.getShipment ? (
                          <LoaderCSS visible />
                        ) : (
                          this.renderTrackingUpdates()
                        )
                      }
                    />
                    <ContentBox.Row
                      content={
                        <div
                          name="tracking-button-container"
                          style={{
                            position: 'relative',
                            bottom: 0
                          }}
                        >
                          <Button
                            disabled={isBlank(get(shipment, 'trackingUrl'))}
                            iconName="shipments"
                            text={t('shipments.trackShipment')}
                            onClick={this.trackShipment}
                            type="submit"
                            className="button--primary track-shipment-button"
                            style={{
                              minHeight: '3.0rem',
                              height: '3.0625rem',
                              marginTop: '2.5rem'
                            }}
                          />
                        </div>
                      }
                    />
                  </ContentBox>
                </div>
              </div>
            </>
          }
        />
        {addNote && (
          <AddNoteModal
            visible
            onClose={() => this.toggle('addNote')}
            t={t}
            shipmentId={shipmentId}
          />
        )}
        <SuccessUpdateModal />
        <ModalSuccess
          visible={noteCreated}
          onButtonClick={() => history.replace(`/shipments/${shipmentId}`)}
          text={t('form.noteCreated.text')}
          buttonText={t('form.noteCreated.buttonText')}
        />
        <ModalApprove
          t={t}
          loading={loading.approveShipment || loading.getShipment}
          visible={showApproveModal}
          onClose={() => this.setState({ showApproveModal: false })}
          updateApproveStatus={this.updateApproveStatus}
          valuez={values}
          locations={locations}
          shipment={shipment}
          shop={shop}
        />
        <ModalImage
          visible={shomImageModal}
          onClose={() => this.setState({ shomImageModal: false })}
          imageUrl={imageUrl}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    main: {
      loadingObj: {
        approveShipment: approveLoading,
        getShipment: getShipmentLoading
      }
    },
    shop: { shop },
    shipment: { shipment },
    auth: { user },
    note: {
      forShipmentDetailsPage: { notes }
    }
  } = state;

  return {
    loading: {
      approveShipment: approveLoading,
      getShipment: getShipmentLoading
    },
    shop,
    shipment,
    user,
    notes,
    values: getFormValues('shipmentApproveForm')(state)
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(ShipmentDetailsContainer);

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';
import { STATES_OPTIONS } from 'config/constants';

function ShipFromStateProvinceCode(props = {}) {
  const {
    disabled,
    errors,
    values,
    validate = [],
    change,
    onChange,
    t
  } = props;

  const shipFromCountryCode = get(values, 'shipFromCountryCode');
  const options = get(STATES_OPTIONS, shipFromCountryCode);

  if (!options) return null;

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      style={{ zIndex: 10 }}
      disabled={disabled}
      options={options}
      prompt={t('form.selectOption')}
      errors={[get(errors, 'shipFromStateProvinceCode')]}
      label={t('shipments.stateProvince')}
      name="shipFromStateProvinceCode"
      validate={validate}
      change={change}
      onChange={onChange}
    />
  );
}
export default withTranslation()(ShipFromStateProvinceCode);

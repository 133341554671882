import React from 'react';
import { Field as ReduxFormField, reduxForm } from 'redux-form';
import { get } from 'lodash';
import 'assets/scss/_login-box.scss';
import { required, repeated, minLength } from '../../utils/validations';
import Field from '../Field';
import Button from '../Button';

const validate = values => {
  const errors = {};
  const passwordRequired = required(values.password);
  errors.password = passwordRequired;
  if (values.password) errors.password = minLength(8)(values.password);
  errors.repeatedPassword = repeated(values.password)(values.repeatedPassword);
  return errors;
};

const ResetPassword = ({
  handleSubmit,
  errors,
  onSubmit,
  onRemoveError,
  t
}) => (
  <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="auth-container__header">
        {t('pages.resetPassword.header')}
      </div>
      <div className="auth-container__body">
        <ReduxFormField
          className=""
          component={Field}
          errors={get(errors, 'password', []).map(err => t(err))}
          placeholder={t('pages.resetPassword.password')}
          name="password"
          type="password"
          onChange={() => {
            if (errors.password) {
              onRemoveError('password');
            }
          }}
        />
        <ReduxFormField
          className=""
          component={Field}
          errors={errors.repeatedPassword}
          placeholder={t('pages.resetPassword.repeatPassword')}
          name="repeatedPassword"
          type="password"
          onChange={() => {
            if (errors.repeatedPassword) {
              onRemoveError('repeatedPassword');
            }
          }}
        />
      </div>
      <div className="auth-container__footer">
        <Button
          text={t('pages.resetPassword.submit')}
          type="submit"
          className="button--primary"
        />
      </div>
    </form>
  </div>
);

export default reduxForm({
  form: 'resetPassword',
  validate
})(ResetPassword);

import React from 'react';
import cn from 'classnames';
import LoaderCSS from 'components/LoaderCSS';
import Row from './Row';
import Content from './Content';

import './index.scss';

const ContentBox = ({
  children,
  className = '',
  style = {},
  containerStyle = {},
  header,
  withNoBorderRadius,
  loading = false
}) => (
  <div
    className={cn('content-box', {
      [className]: true
    })}
    style={style}
  >
    {header}
    <div
      className={cn('content-box__container', {
        'no-border-radius': withNoBorderRadius
      })}
      style={containerStyle}
    >
      {loading ? <LoaderCSS visible wide /> : children}
    </div>
  </div>
);

ContentBox.Row = Row;
ContentBox.Content = Content;
export default ContentBox;

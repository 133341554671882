import React from 'react';
import Switch from 'components/Switch';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import './index.scss';

export default function FieldSwitch2({
  onText = 'On',
  onValue = true,
  offText = 'Off',
  offValue = false,
  disabled = false,
  name,
  control,
  defaultValue,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <div className="field-switch">
          <div
            className={cn('field-switch__text', {
              'field-switch__text--highlighted': value === onValue
            })}
          >
            {onText}
          </div>

          <Switch
            on={onValue}
            off={offValue}
            defaultValue={defaultValue}
            onChange={val => onChange(val)}
            scale={1}
            disabled={disabled}
          />

          <div
            className={cn('field-switch__text', {
              'field-switch__text--highlighted': value === offValue
            })}
          >
            {offText}
          </div>
        </div>
      )}
    />
  );
}

import React from 'react';
import styled from 'styled-components';
import iconX from 'assets/images/x.svg';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: .5rem;
  background: none;
`;
const Icon = styled.img`
  transform: scale(0.7);
`;

function ButtonClose(props) {
  const { onClick } = props;

  return (
    <Button type="button" onClick={onClick}>
      <Icon src={iconX} />
    </Button>
  );
}

export default ButtonClose;

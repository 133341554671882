/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cn from 'classnames';
import './index.scss';

export default function Switch({
  className = '',
  styles = {},
  handleChange = () => {},
  scale = 1,
  value,
  defaultValue = false,
  disabled = false,
  children
}) {
  const labelStyle = { ...styles.label, transform: `scale(${scale})` };

  return (
    <div className={cn('switch-container', className)} style={styles.container}>
      <label className="switch" style={labelStyle} disabled={disabled}>
        <input
          type="checkbox"
          onChange={e => {
            handleChange(e.target.checked);
          }}
          defaultChecked={defaultValue}
          checked={value}
          disabled={disabled}
        />
        <span
          className={cn('slider round', { 'slider--disabled': disabled })}
        />
      </label>
      <span className="text">{children}</span>
    </div>
  );
}

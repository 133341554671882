import { TIMESLOTS } from './actions';

export const INITIAL_STATE = {
  timeslots: [],
  error: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { timeslots, error } = action;
  switch (action.type) {
    case TIMESLOTS.GET_OK:
      return {
        ...state,
        timeslots,
        error: null
      };
    case TIMESLOTS.GET_FAIL:
      return {
        ...state,
        timeslots: [],
        error
      };
    case TIMESLOTS.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    case TIMESLOTS.CLEAR_TIMESLOTS:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

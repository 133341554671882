import React from 'react';
import { dispatch } from 'store';
import { get } from 'lodash';
import cn from 'classnames';
import { Field as ReduxFormField, reduxForm } from 'redux-form';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import teamActions from 'features/team/actions';
import Field from 'components/Field';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import { required, email as emailValidation } from 'utils/validations';
import LoaderCSS from 'components/LoaderCSS';

import './index.scss';

const InviteMemberForm = props => {
  const {
    handleSubmit,
    errors,
    apiErrors,
    onRemoveError,
    invalid,
    submitting,
    pristine,
    history,
    t,
    loading
  } = props;

  const inviteMember = ({ firstName, lastName, email }) =>
    dispatch(teamActions.inviteMember(firstName, lastName, email));

  return (
    <MainContentContainer
      style={{ height: 'fit-content' }}
      mainStyles={{
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      main={
        <>
          <ContentBox
            header={
              <Breadcrumbs style={{ marginBottom: '2.5rem' }}>
                <Breadcrumbs.Item
                  onClick={() => history.push('/overview')}
                  tabIndex={0}
                >
                  {t('general.overview')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  onClick={() => history.push('/team')}
                  tabIndex={-1}
                >
                  {t('general.team')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item tabIndex={-2}>
                  {t('team.inviteTitle')}
                </Breadcrumbs.Item>
              </Breadcrumbs>
            }
            style={{ minWidth: '61.25rem', minHeight: '29.25rem' }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem'
            }}
          >
            <ContentBox.Row
              header={
                <ContentBox.Row.Header text={t('team.inviteTitle')} separated />
              }
              content={
                <form
                  onSubmit={handleSubmit(inviteMember)}
                  className="invite-member-form"
                >
                  <div className="invite-member-form__first-block">
                    <ReduxFormField
                      style={{
                        width: 'calc(50% - 1.125rem)',
                        marginBottom: '2.125rem'
                      }}
                      inputStyle={{ height: '3.75rem' }}
                      disabled={submitting}
                      className={cn('invite-member-form__field')}
                      component={Field}
                      errors={get(errors, 'firstName')}
                      placeholder={t('team.firstName')}
                      name="firstName"
                      validate={[required]}
                      onChange={() => {
                        if (get(errors, 'firstName')) {
                          onRemoveError('firstName');
                        }
                      }}
                    />
                    <ReduxFormField
                      disabled={submitting}
                      style={{
                        width: 'calc(50% - 1.125rem)',
                        marginBottom: '2.125rem'
                      }}
                      inputStyle={{ height: '3.75rem' }}
                      className={cn('invite-member-form__field')}
                      component={Field}
                      errors={get(errors, 'lastName')}
                      placeholder={t('team.lastName')}
                      name="lastName"
                      validate={[required]}
                      onChange={() => {
                        if (get(errors, 'lastName')) {
                          onRemoveError('lastName');
                        }
                      }}
                    />
                  </div>
                  <div className="invite-member-form__second-block">
                    <ReduxFormField
                      disabled={submitting}
                      style={{
                        marginBottom: '2.125rem'
                      }}
                      inputStyle={{
                        height: '3.75rem'
                      }}
                      className={cn('invite-member-form__field')}
                      component={Field}
                      errors={get(errors, 'email', []).concat(
                        get(apiErrors, 'email', []).map(err => t(err))
                      )}
                      placeholder={t('team.email')}
                      name="email"
                      validate={[emailValidation]}
                      onChange={() => {
                        if (get(errors, 'email')) {
                          onRemoveError('email');
                        }
                      }}
                    />
                  </div>
                  <div className="invite-member-form__actions">
                    <Button
                      iconName="addTeamMember"
                      style={{ height: '3.75rem' }}
                      disabled={invalid || submitting || pristine || loading}
                      text={t('team.invite')}
                      type="submit"
                      className="button--primary"
                    />
                  </div>
                  {loading && <LoaderCSS visible wide />}
                </form>
              }
            />
          </ContentBox>
        </>
      }
    />
  );
};

export default reduxForm({
  form: 'inviteMemberForm'
})(InviteMemberForm);

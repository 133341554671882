import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';

function compare(a, b) {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
}

const sortOptions = options => options.sort(compare);

function FieldSelectSearchable({
  fullWidth = true,
  name,
  options = [],
  defaultOption,
  isLoading,
  isDisabled,
  control,
  error,
  rules = {}
}) {
  const containerStyles = {};
  if (fullWidth) {
    containerStyles.minWidth = '100%';
  } else {
    containerStyles.flexGrow = 1;
  }

  const stylez = {
    container: styles => ({ ...styles, ...containerStyles })
  };

  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultOption ? +defaultOption.value : undefined}
        name={name}
        rules={rules}
        render={({ onChange, ref }) => (
          <Select
            ref={ref}
            styles={stylez}
            defaultValue={defaultOption}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable
            isSearchable
            name={name}
            options={sortOptions(options)}
            onChange={option => {
              let newValue = null;
              const value = get(option, 'value');
              if (value) newValue = +value;
              return onChange(newValue);
            }}
            getOptionLabel={option => `${option.text}`}
            getOptionValue={option => option.value}
            // menuIsOpen={true}
          />
        )}
      />
      {error}
    </>
  );
}

export default FieldSelectSearchable;

import { without } from 'lodash';
import { MAIN } from './actions';

const INITIAL_STATE = {
  loading: [],
  loadingObj: {},
  serverError: null,
  featureErrors: {},
  showSuccessPickupUpdateModal: false
};

export default (state = INITIAL_STATE, action = {}) => {
  const { featureName, message, showSuccessPickupUpdateModal } = action;
  const loadingObj = { ...state.loadingObj };
  switch (action.type) {
    case MAIN.START_LOADING:
      loadingObj[featureName] = true;
      return {
        ...state,
        loading: [...state.loading, featureName],
        loadingObj
      };
    case MAIN.STOP_LOADING:
      loadingObj[featureName] = false;
      return {
        ...state,
        loading: without([...state.loading], featureName),
        loadingObj
      };
    case MAIN.SET_SERVER_ERROR:
      return {
        ...state,
        serverError: message
      };
    case MAIN.CLEAR_SERVER_ERROR:
      return {
        ...state,
        serverError: null
      };
    case MAIN.SET_FEATURE_ERROR:
      return {
        ...state,
        featureErrors: { ...state.featureErrors, [featureName]: message }
      };
    case MAIN.CLEAR_FEATURE_ERROR:
      return {
        ...state,
        featureErrors: { ...state.featureErrors, [featureName]: null }
      };
    case MAIN.RESET_FEATURE_ERRORS:
      return {
        ...state,
        featureErrors: {}
      };
    case MAIN.SET_SHOW_SUCCESS_PICKUP_UPDATE_MODAL:
      return {
        ...state,
        showSuccessPickupUpdateModal
      };

    case MAIN.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import './index.scss';

const ModalSuccess = ({ onButtonClick, headerText, text, buttonText, visible }) => {
  return (
    <Modal
      visible={visible}
      className="modal--small"
      style={{
        padding: '2.875rem 4.125rem'
      }}
    >
      <>
        <div className="modal-success__header">{headerText}</div>
        <div className="modal-success__text">{text}</div>
        <div className="modal-success__buttons">
          <Button
            text={buttonText}
            className="button--primary width--auto"
            onClick={onButtonClick}
          />
        </div>
      </>
    </Modal>
  );
};

export default ModalSuccess;

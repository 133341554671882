/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { get } from 'lodash';
import { handleAPIError } from 'utils/sagaUtils';
import profileActions from './actions';
import authActions from '../auth/actions';
import mainActions from '../main/actions';
import { processToken } from '../auth/helper';
import {
  updateProfileNameRequest,
  updateProfilePasswordRequest,
  resetPasswordRequest,
  sendResetPasswordUrlRequest
} from './api';

export function* updateName(action) {
  yield put(mainActions.startLoading('updateName'));
  const { firstName, lastName } = action;

  try {
    const response = yield updateProfileNameRequest(firstName, lastName);
    const token = get(response, 'data.data.updateProfileName.jwtToken');
    const tokenPayload = processToken(token);
    yield put(authActions.loginOk(token, tokenPayload));
    yield put(profileActions.updateNameOk());
  } catch (error) {
    yield put(profileActions.updateNameFail(error, 'updateName'));
    handleAPIError(error, 'updateName');
  } finally {
    yield put(mainActions.stopLoading('updateName'));
  }
}

export function* sendResetPasswordUrl(action) {
  yield put(mainActions.startLoading('sendResetPasswordUrl'));

  const { email } = action;

  try {
    yield sendResetPasswordUrlRequest(email);
    yield put(profileActions.sendResetPasswordUrlOk());
  } catch (error) {
    yield put(
      profileActions.sendResetPasswordUrlFail(error, 'sendResetPasswordUrl')
    );
  }
  yield put(mainActions.stopLoading('sendResetPasswordUrl'));
}

export function* resetPassword(action) {
  yield put(mainActions.startLoading('resetPassword'));

  const {
    token: resetToken,
    newPassword
  } = action;

  try {
    const response = yield resetPasswordRequest(
      resetToken,
      newPassword
    );
    const data = get(response, 'data.data.resetProfilePasswordV1', {});
    const newAuthToken = get(data, 'jwtToken');
    const error = get(data, 'error');
    if (error) throw error;

    const tokenPayload = processToken(newAuthToken);
    yield put(authActions.loginOk(newAuthToken, tokenPayload));
    yield put(profileActions.resetPasswordOk());
    yield put(push('/'));
  } catch (error) {
    yield put(profileActions.resetPasswordFail(error, 'resetPassword'));
    handleAPIError(error, 'resetPassword');
  }
  yield put(mainActions.stopLoading('resetPassword'));
}
 
export function* updatePassword(action) {
  yield put(mainActions.startLoading('updatePassword'));

  const { oldPassword, newPassword } = action;

  try {
    const response = yield updateProfilePasswordRequest(
      oldPassword,
      newPassword
    );
    const token = get(response, 'data.data.updateProfilePassword.jwtToken');
    const tokenPayload = processToken(token);
    yield put(authActions.loginOk(token, tokenPayload));
    yield put(profileActions.updatePasswordOk());
    yield put(push('/profile'));
  } catch (error) {
    yield put(profileActions.updatePasswordFail(error, 'updatePassword'));
  }
  yield put(mainActions.stopLoading('updatePassword'));
}

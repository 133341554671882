import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldInput from 'components/Field';
import { get } from 'lodash';

function ShipFromStreet(props = {}) {
  const { disabled, errors, validate = [], t } = props;

  return (
    <Field
      className="other-class"
      disabled={disabled}
      errors={get(errors, 'shipFromStreet')}
      label={t('shipments.street')}
      name="shipFromStreet"
      component={FieldInput}
      validate={validate}
    />
  );
}
export default withTranslation()(ShipFromStreet);

import React from 'react';
import styled from 'styled-components';
import { Field as ReduxFormField, reduxForm } from 'redux-form';
import { get } from 'lodash';
import { required } from 'utils/validations';
import Field from '../../../components/Field';
import Button from '../../../components/Button';

const ErrorMessage = styled.div`
  display: flex;
  color: red;
  width: 100%;
  justify-content: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: 2rem;
`;

const LoginForm = ({
  handleSubmit,
  onEmailChange,
  error1,
  onSubmit,
  clearError,
  loading,
  t
}) => {

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="auth-container__header">{t('pages.login.header')}</div>
        <div className="auth-container__body">
          <ReduxFormField
            className=""
            component={Field}
            placeholder={t('general.email')}
            name="email"
            validate={[required]}
            onChange={event => {
              onEmailChange(get(event, 'target.value'));
              if (error1) clearError();
            }}
          />
          <ReduxFormField
            className=""
            component={Field}
            placeholder={t('general.password')}
            name="password"
            type="password"
            validate={[required]}
            onChange={() => {
              if (error1) clearError();
            }}
          />
        </div>
        <div className="auth-container__footer">
          <Button
            loading={loading}
            text={t('pages.login.submit')}
            type="submit"
            className="button--primary"
          />
        </div>
        <ErrorMessage visible={!!error1}>{error1}</ErrorMessage>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'userLoginForm'
})(LoginForm);

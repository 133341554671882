import { AUTH } from './actions';

export const INITIAL_STATE = {
  // email: null,
  user: null,
  // role: null,
  token: null,
  authenticated: false,
  showLogoutModal: false
};

export default (state = INITIAL_STATE, action = {}) => {
  const { user, token, error } = action;
  switch (action.type) {
    case AUTH.LOGIN_OK:
    case AUTH.REFRESH_TOKEN_OK:
      return {
        ...state,
        user,
        token,
        authenticated: true
      };
    case AUTH.LOGIN_FAIL:
      return {
        ...INITIAL_STATE
      };
    case AUTH.TOGGLE_LOGOUT_MODAL_OK:
      return {
        ...state,
        showLogoutModal: !state.showLogoutModal
      };
    case AUTH.TOGGLE_LOGOUT_MODAL_FAIL:
      return {
        ...INITIAL_STATE,
        error
      };
    case AUTH.USER_LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

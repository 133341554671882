export const STATS = {
  GET_DAILY_COUNTERS: 'STATS_GET_DAILY_COUNTERS',
  GET_DAILY_COUNTERS_FAIL: 'STATS_GET_DAILY_COUNTERS_FAIL',
  GET_DAILY_COUNTERS_OK: 'STATS_GET_DAILY_COUNTERS_OK',
  GET_TOTAL_COUNTERS: 'STATS_GET_TOTAL_COUNTERS',
  GET_TOTAL_COUNTERS_FAIL: 'STATS_GET_TOTAL_COUNTERS_FAIL',
  GET_TOTAL_COUNTERS_OK: 'STATS_GET_TOTAL_COUNTERS_OK',
  GET_SHOP_COUNTERS: 'STATS_GET_SHOP_COUNTERS',
  GET_SHOP_COUNTERS_FAIL: 'STATS_GET_SHOP_COUNTERS_FAIL',
  GET_SHOP_COUNTERS_OK: 'STATS_GET_SHOP_COUNTERS_OK'
};

export default {
  getDailyCounters: noDays => ({
    type: STATS.GET_DAILY_COUNTERS,
    noDays
  }),
  getDailyCountersOk: dailyCounters => ({
    type: STATS.GET_DAILY_COUNTERS_OK,
    dailyCounters
  }),
  getDailyCountersFail: error => ({
    type: STATS.GET_DAILY_COUNTERS_FAIL,
    error
  }),
  getTotalCounters: (from, to) => ({
    type: STATS.GET_TOTAL_COUNTERS,
    from,
    to
  }),
  getTotalCountersOk: (totalCounters, billedCounters) => ({
    type: STATS.GET_TOTAL_COUNTERS_OK,
    totalCounters,
    billedCounters
  }),
  getTotalCountersFail: error => ({
    type: STATS.GET_TOTAL_COUNTERS_FAIL,
    error
  }),
  getShopCounters: (from, to) => ({
    type: STATS.GET_SHOP_COUNTERS,
    from,
    to
  }),
  getShopCountersOk: (
    monthlyCounters,
    returnReasonCounters,
    billedCounters,
    shopCounters
  ) => ({
    type: STATS.GET_SHOP_COUNTERS_OK,
    monthlyCounters,
    returnReasonCounters,
    billedCounters,
    shopCounters
  }),
  getShopCountersFail: error => ({
    type: STATS.GET_SHOP_COUNTERS_FAIL,
    error
  })
};

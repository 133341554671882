import React from 'react';

import logo from 'assets/images/returnista-logo.svg';
import './index.scss';
import Small from './Small';

export const LOGO_SIZES = {
  SMALL: 'small',
  LARGE: 'large',
  POWERED_BY: 'powered_by'
};

export const Logo = ({ className = '', styles, type, ...rest }) => {
  switch (type) {
    case LOGO_SIZES.SMALL:
      return (
        <Small className={`logo ${className}`} styles={styles} {...rest} />
      );
    case LOGO_SIZES.POWERED_BY:
      return (
        <div className="logo logo--with-text">
          <span>POWERED BY</span>
        </div>
      );
    default:
      return <img className="logo__image" src={logo} alt="Volta!" />;
  }
};

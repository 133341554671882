import { ADDRESS } from './actions';

export const INITIAL_STATE = {
  city: null,
  streetName: null,
  error: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { city, streetName, error } = action;
  switch (action.type) {
    case ADDRESS.GET_OK:
      return {
        ...state,
        city,
        streetName,
        error: null
      };
    case ADDRESS.GET_FAIL:
      return {
        ...state,
        streetName: null,
        city: null,
        error
      };
    case ADDRESS.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    case ADDRESS.CLEAR_VALUES:
      return {
        ...state,
        streetName: null,
        city: null
      };
    default:
      return state;
  }
};

import moment from 'moment';
import { useState } from 'react';

const useFromToFilter = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  
  const formatDate = (date) => date ? moment(date).format("DD.MM.YYYY") : undefined

  return {
    from,
    setFrom: (date) => setFrom(formatDate(date)),
    to,
    setTo: (date) => setTo(formatDate(date)),
  };
};

export default useFromToFilter;

import React from 'react';
import { Field as ReduxFormField, reduxForm, initialize } from 'redux-form';

import { required } from 'utils/validations';
import Field from 'components/Field';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { dispatch } from 'store';
import profileActions from 'features/profile/actions';

const validate = values => {
  const errors = {};
  errors.firstName = required(values.firstName);
  errors.lastName = required(values.lastName);
  return errors;
};

const Form = ({ handleSubmit, onSubmit, errors, onClose, t }) => (
  <div>
    <div className="modal__header">
      <span>{t('profile.nameForm.introText')}</span>
    </div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column">
        <ReduxFormField
          className=""
          component={Field}
          name="firstName"
          label={t('profile.nameForm.firstName.label')}
          errors={errors ? errors.firstName : null}
        />
        <ReduxFormField
          className=""
          component={Field}
          name="lastName"
          label={t('profile.nameForm.lastName.label')}
          errors={errors ? errors.lastName : null}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <Button
          text={t('profile.cancel')}
          onClick={onClose}
          className="button--secondary width--auto"
        />
        <Button
          text={t('profile.update')}
          type="submit"
          className="button--primary width--auto ml-4"
        />
      </div>
    </form>
  </div>
);

const FormWithRedux = reduxForm({
  form: 'editProfileNameForm',
  validate
})(Form);

class EditNameModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { initialValues } = this.props;
    dispatch(initialize('editProfileNameForm', initialValues));
  }

  handleSubmit({ firstName, lastName }) {
    dispatch(profileActions.updateName(firstName, lastName));
  }

  render() {
    const { t, onClose } = this.props;

    return (
      <Modal visible className="modal--small">
        <FormWithRedux onClose={onClose} onSubmit={this.handleSubmit} t={t} />
      </Modal>
    );
  }
}

export default EditNameModal;

import React, { Component } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import SearchBar from 'components/SearchBar';
import investigationActions from 'features/investigation/actions';
import { dispatch } from 'store';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import TableInvestigations from 'components/TableInvestigations';
import Button from 'components/Button';
import './index.scss';

class Investigations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: null,
      sorting: {},
      loading: false,
      activeTab: 0,
      unresolved: { data: [], totalPages: 1, page: 1 },
      resolved: { data: [], totalPages: 1, page: 1 },
      all: { data: [], totalPages: 1, page: 1 }
    };

    this.t = props.t;
    this.history = props.history;
    this.tabs = ['unresolved', 'resolved', 'all'];
  }

  componentDidMount() {
    const { activeTab } = this.state;
    const tabName = this.tabs[activeTab];
    const {
      [tabName]: { page }
    } = this.state;
    dispatch(investigationActions.getInvestigations(page, 'unresolved'));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { role, unresolved, resolved, all, loading } = nextProps;

    if (!isEqual(unresolved, prevState.unresolved)) {
      newState = { ...newState, unresolved };
    }

    if (!isEqual(resolved, prevState.resolved)) {
      newState = { ...newState, resolved };
    }

    if (!isEqual(all, prevState.all)) {
      newState = { ...newState, all };
    }

    if (!isEqual(loading, prevState.loading)) {
      newState = { ...newState, loading };
    }

    if (!isEqual(role, prevState.role)) {
      newState = { ...newState, role };
    }
    return newState;
  }

  onTabClick(tabIndex) {
    const tabName = this.tabs[tabIndex];
    const {
      [tabName]: { page }
    } = this.state;
    dispatch(investigationActions.getInvestigations(page, tabName));
    this.setState({ activeTab: tabIndex });
  }
  render() {
    const { t, history } = this.props;
    const { sorting, loading, activeTab } = this.state;
    const tabName = this.tabs[activeTab];
    const { [tabName]: investigations } = this.state;

    return (
      <MainContentContainer
        style={{ height: '100%' }}
        mainStyles={{
          width: '98%',
          minHeight: '100%',
          height: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        main={
          <>
            <SearchBar t={t} history={history} />
            <ContentBox
              style={{
                minWidth: '100%',
                width: '100%',
                flexGrow: 1,
                display: 'flex'
              }}
              containerStyle={{ flexDirection: 'column', width: '100%' }}
            >
              <ContentBox.Row
                header={
                  <>
                    <ContentBox.Row.Header
                      text={t('general.investigations')}
                    />
                    <div className="tabs-buttons">
                      {this.tabs.map((tab, index) => (
                        <Button
                          className={cn('tab-button', 'button--purple', {
                            'tab-button--active': activeTab === index
                          })}
                          onClick={() => this.onTabClick(index)}
                        >
                          {t(`investigations.${tab}`)}
                        </Button>
                      ))}
                    </div>
                  </>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                content={
                  <TableInvestigations
                    investigations={investigations.data}
                    sorting={sorting}
                    totalPages={investigations.totalPages}
                    page={investigations.page}
                    history={this.history}
                    loading={loading}
                    scope={tabName}
                    t={this.t}
                  />
                }
              />
            </ContentBox>
          </>
        }
      />
    );
  }
}

const mapStateToProps = ({
  // auth: {
  //   user: { role }
  // },
  investigation: {
    investigations: { unresolved, resolved, all }
  },
  main: {
    loadingObj: { getInvestigations: loading }
  }
}) => ({
  // role,
  unresolved,
  resolved,
  all,
  loading
});
export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Investigations);

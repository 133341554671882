import React from 'react';

function BreadcrumbsItem({ onClick, tabIndex, children }) {
  if (!tabIndex || !onClick)
    return <div className="breadcrumbs__item">{children}</div>;

  return (
    <div
      className="breadcrumbs__item"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
}

export default BreadcrumbsItem;

import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = () => ({
  //   loading: state.main.loading,
});

const MainContainer = props => {
  const { route } = props;
  const height = route.header ? 'calc(100% - 3.75rem)' : '100%';
  const backgroundColor = route.customBgColor ? route.customBgColor : '#ecefef';

  return (
    <div style={{ minHeight: '100%', width: '100%', }}>
      {route.header && <route.header key="header" {...props} />}
      <div style={{ display: 'flex', width: '100%', minHeight: height }}>
        {/* {route.sidebar && <route.sidebar key="sidebar" {...this.props} />} */}
        {route.main && (
          <route.main key="main" backgroundColor={backgroundColor} {...props} />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(MainContainer);

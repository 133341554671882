import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';

function NumberOfBoxes(props = {}) {
  const { disabled, errors, validate = [], change, t } = props;

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      disabled={disabled}
      style={{ zIndex: 2 }}
      options={[
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 }
      ]}
      prompt={t('form.selectAmountOfBoxes')}
      errors={[get(errors, 'numberOfBoxes')]}
      label={t('shipments.amountOfBoxes')}
      name="numberOfBoxes"
      validate={validate}
      change={change}
    />
  );
}
export default withTranslation()(NumberOfBoxes);

import { get } from 'lodash';

const userFlags = values => {
  const isAdmin = get(values, 'role') === 'admin';
  const isShopAdmin = get(values, 'role') === 'shop_admin';
  return {
    isAdmin,
    isShopAdmin
  };
};
export default userFlags;

import { post } from '../rootApi';

export function getTimeslotsRequest(postalCode, shopId, network) {
  const GET_TIMESLOTS_QUERY = `
  {
    timeslots(postalCode: "${postalCode}", shopId: "${shopId}", network: "${network}") {
      id
      start
      stop
    }
  }
 `;

  return post({ query: GET_TIMESLOTS_QUERY });
}

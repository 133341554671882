import React from 'react';
import './index.scss';

function Message({ header, body, footer }) {
  return (
    <div className="message">
      <div className="message__main">
        {header && <span className="message__header">{header}</span>}
        {body && <span className="message__body">{body}</span>}
      </div>
      {footer && <div className="message__footer">{footer}</div>}
    </div>
  );
}

export default Message;

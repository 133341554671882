/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';
import { get, find, isEmpty } from 'utils/lodash';
import IconCaret from 'components/IconCaret';
import { isBlank } from 'utils/textHelper';
import './index.scss';

export default class extends React.Component {
  constructor(props) {
    super(props);

    const { prompt } = props;
    this.state = {
      open: false,
      value: '',
      textValue: prompt
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      meta: { pristine: before }
    } = this.props;
    const {
      meta: { pristine: after }
    } = prevProps;

    if (before && !after) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: null, textValue: null });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { options, input } = nextProps;

    const nextValue = get(input, 'value');
    if (nextValue !== prevState.value && !isEmpty(options)) {
      const option = find(options, { value: nextValue });
      newState = {
        ...newState,
        value: nextValue,
        textValue: get(option, 'text')
      };
    }
    return newState;
  }

  onChange(value) {
    // change value in the store
    const {
      change,
      input: { name, onChange: onChangeParent },
      options
    } = this.props;

    change(name, value);
    onChangeParent(value);
    const option = find(options, ['value', value]);
    this.setState({ value, textValue: get(option, 'text') });
  }

  render() {
    const {
      meta,
      options,
      prompt,
      disabled,
      className,
      style,
      label,
      labelStyle,
      labelClassName
    } = this.props;
    let { errors } = this.props;
    errors = [
      ...(errors || []),
      ...(meta && meta.error && meta.touched ? [meta.error] : [])
    ];
    const { open, value, textValue } = this.state;
    const selected = !isBlank(value);

    return (
      <div
        className="field-select__box"
        tabIndex="-1"
        onBlur={() => this.setState({ open: false })}
        onClick={() => !disabled && this.setState({ open: !open })}
      >
        {label && (
          <span
            className={`field-select__label ${labelClassName}`}
            style={labelStyle}
          >
            {label}
          </span>
        )}
        <div
          className={cn('field-select__container', className, {
            'field-select__container--selected': selected,
            'field-select__container--disabled': disabled,
            'field-select__container--open': open
          })}
          style={style}
        >
          <div className={cn('field-select')}>
            <div className={cn('field-select__options-container')}>
              <div
                className={cn('field-select__prompt', {
                  'field-select__prompt--disabled': disabled
                })}
              >
                <span>{selected && !open ? textValue : prompt}</span>
                <div className={cn('field-select__caret')}>
                  <IconCaret solid />
                </div>
              </div>
              <div
                className={cn('field-select__options', {
                  'field-select__options--open': open
                })}
              >
                {options.map((option, index) => (
                  <div
                    className={cn('field-select__option', {
                      'field-select__option--selected': option.value === value
                    })}
                    key={index}
                    onClick={() =>
                      this.onChange(
                        option.value === value ? null : option.value
                      )
                    }
                  >
                    {option.text}
                    <div
                      className={cn('field-select__caret')}
                      alt="to take place"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {errors.map((error, i) => (
          <div key={i} className="input-box__error">
            {error}
          </div>
        ))}
      </div>
    );
  }
}

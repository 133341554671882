import { takeLatest } from 'redux-saga/effects';

import { login, refreshToken, toggleLogoutModal } from './auth/saga';
import authActions, { AUTH } from './auth/actions';

import {
  getShipments,
  searchShipments,
  getShipment,
  createLabel as createLabelForShipment,
  createShipment,
  updatePickup,
  cancelShipment,
  updateApproveStatus
} from './shipment/saga';
import { SHIPMENTS } from './shipment/actions';
import shipmentActions from './shipment/actions';

import {
  getInvestigations,
  toggleInvestigationStatus,
  startInvestigation
} from './investigation/saga';
import { INVESTIGATIONS } from './investigation/actions';

import { getNotes, addNote } from './note/saga';
import { NOTES } from './note/actions';

import { getTeam, inviteMember, deleteMember } from './team/saga';
import { TEAM } from './team/actions';

import { getAddress } from './address/saga';
import addressActions from './address/actions';

import { getTimeslots } from './timeslot/saga';
import timeslotActions from 'features/timeslot/actions';

import {
  getDailyCounters,
  getTotalCounters,
  getShopCounters
} from './stats/saga';
import { STATS } from './stats/actions';

import { getShop, getShops } from './shop/saga';
import shopActions from './shop/actions';

import {
  updateName,
  sendResetPasswordUrl,
  updatePassword,
  resetPassword
} from './profile/saga';
import { PROFILE } from './profile/actions';

export default function* rootSaga() {
  yield takeLatest(authActions.login, login);
  yield takeLatest(AUTH.TOGGLE_LOGOUT_MODAL, toggleLogoutModal);
  yield takeLatest(authActions.refreshToken, refreshToken);

  // shipments
  yield takeLatest(SHIPMENTS.SEARCH, searchShipments);
  yield takeLatest(SHIPMENTS.GET, getShipments);
  yield takeLatest(shipmentActions.getShipment, getShipment);
  yield takeLatest(SHIPMENTS.CREATE_LABEL, createLabelForShipment);
  yield takeLatest(shipmentActions.updatePickup, updatePickup);
  yield takeLatest(shipmentActions.createShipment, createShipment);
  yield takeLatest(SHIPMENTS.CANCEL_ONE, cancelShipment);
  yield takeLatest(SHIPMENTS.UPDATE_APPROVE_STATUS, updateApproveStatus);

  yield takeLatest(addressActions.getAddress, getAddress);
  yield takeLatest(timeslotActions.getTimeslots, getTimeslots);

  // investigations
  yield takeLatest(INVESTIGATIONS.START, startInvestigation);
  yield takeLatest(INVESTIGATIONS.GET, getInvestigations);
  yield takeLatest(INVESTIGATIONS.TOGGLE_STATUS, toggleInvestigationStatus);

  // notes
  yield takeLatest(NOTES.GET, getNotes);
  yield takeLatest(NOTES.ADD, addNote);

  // team
  yield takeLatest(TEAM.GET, getTeam);
  yield takeLatest(TEAM.INVITE_MEMBER, inviteMember);
  yield takeLatest(TEAM.DELETE_MEMBER, deleteMember);

  // profile
  yield takeLatest(PROFILE.UPDATE_NAME, updateName);
  yield takeLatest(PROFILE.SEND_RESET_PASSWORD_URL, sendResetPasswordUrl);
  yield takeLatest(PROFILE.RESET_PASSWORD, resetPassword);
  yield takeLatest(PROFILE.UPDATE_PASSWORD, updatePassword);

  // shop
  yield takeLatest(shopActions.getShops, getShops);
  yield takeLatest(shopActions.getShop, getShop);

  // stats
  yield takeLatest(STATS.GET_DAILY_COUNTERS, getDailyCounters);
  yield takeLatest(STATS.GET_SHOP_COUNTERS, getShopCounters);
  yield takeLatest(STATS.GET_TOTAL_COUNTERS, getTotalCounters);
}

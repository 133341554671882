import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import IconSquare from 'components/IconSquare';
// import ReactSVG from 'react-svg';
// import { isEqual } from 'lodash';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import './index.scss';

class FaqContainer extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.faqItems = [
      {
        question: 'Wat kan ik met het dashboard?',
        answer: (
          <div>
            <p>
              In het dashboard kan je alle zendingen die jouw klanten hebben
              aangemaakt inzien.
            </p>
            <p>
              Je kan hier (afhankelijk van het integratie-niveau) handige
              inzichten vergaren over het retourproces. In deze FAQ worden de
              meest gestelde vragen beantwoord, heb je een vraag die er niet
              bijstaat? Neem contact op met{' '}
              <a href="mailto:quinten@returnista.nl">quinten@returnista.nl</a>
            </p>
            <p>Een aantal termen staat hier uitgelegd:</p>
            Webshop: jij Pickup: een afhaling aan huis bij de consument
            <br />
            Consument: De consument die een label of pickup heeft ingepland
            <br />
            Zending: een pakketje van de user Vervoerder: UPS of DHL
            <br />
          </div>
        )
      },
      {
        question: 'Een zending is niet opgehaald, wat nu?',
        answer: (
          <div>
            <p>
              Het is erg vervelend dat het pakketje niet is opgehaald, helaas
              kan dit voorkomen. De vervoerder heeft het adres niet kunnen
              vinden of er is een probleem met de planning van de chauffeur.
              Returnista stuurt na het verstrijken van het tijdvak en mail naar
              de user om hier een oplossing voor te bieden. We maken dan een
              nieuwe afspraak of bieden een andere nette oplossing
            </p>
          </div>
        )
      },
      {
        question: 'Een zending heeft geen trackingupdates, wat nu?',
        answer: (
          <div>
            <p>
              Pickups: voor pickups kan het tot 12 uur duren voordat de eerste
              tracking info doorkomt in het dashboard, we houden dit in de
              gaten. Mocht er een zending zijn waarvan er onduidelijke tracking
              is, neem dan contact op via de chat of via de onderzoeks-knop. De
              ‘start een onderzoek’ knop is te vinden door op een zending te
              klikken
            </p>
          </div>
        )
      },
      {
        question: 'Een zending lijkt verloren te zijn gegaan (vermissing)',
        answer: (
          <div>
            <p>
              {' '}
              Dit gebeurt gelukkig niet veel, maar het komt voor. Als dit
              gebeurt kan je deze stappen volgen:
            </p>
            <ol>
              <li>Klik op de betreffende zending</li>
              <li>Klik op ‘Start een onderzoek’</li>
              <li>Geef aan wat er met de zending aan de hand is</li>
              <li>
                Returnista, de vervoerder en jij ontvangen een bevestigingsmail
                van de start van het onderzoek
              </li>
              <li>Returnista begeleidt het onderzoek</li>
              <li>
                Als de claim wordt goedgekeurd, wordt dit volgens de gemaakte
                afspraken verwerkt
              </li>
              <li>
                De status van het onderzoek kan je volgen onder het kopje
                ‘onderzoeken’
              </li>
              <li>
                We streven ernaar een onderzoek binnen 3 dagen af te ronden, het
                kan echter voorkomen dat dit langer duurt
              </li>
            </ol>
          </div>
        )
      }
    ];

    this.state = {
      openItemIndex: null
    };
  }

  toggleItem(index, event) {
    const { openItemIndex } = this.state;
    if (event && event.keyCode !== 13) {
      return;
    }
    const newOpenItemIndex = openItemIndex === index ? null : index;
    this.setState({ openItemIndex: newOpenItemIndex });
  }

  render() {
    const { openItemIndex } = this.state;
    return (
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '98%',
          minHeight: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        main={
          <ContentBox
            style={{ minWidth: '100%', width: '100%', minHeight: '100%' }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem',
              marginBottom: '2.5rem'
            }}
          >
            <ContentBox.Row
              header={
                <ContentBox.Row.Header separated text={this.t('faq.title')} />
              }
              content={
                <div className="faq__items">
                  {this.faqItems.map((item, index) => (
                    <div
                      className={cn(
                        'faq__item__container',
                        openItemIndex === index
                          ? 'faq__item__container--open'
                          : 'faq__item__container--closed'
                      )}
                    >
                      <div
                        className={cn('faq__item', {
                          'faq__item--open': openItemIndex === index
                        })}
                        role="button"
                        tabIndex={index}
                        onKeyDown={event => this.toggleItem(index, event)}
                        onClick={() => this.toggleItem(index)}
                      >
                        <div className="faq__item__question__container">
                          <div className="faq__item__question">
                            {item.question}
                          </div>
                          <IconSquare
                            icon={openItemIndex === index ? 'eye' : 'eyeClosed'}
                            className="faq__item__icon"
                          />
                        </div>
                        <div className="faq__item__separator" />
                        <div className="faq__item__answer">{item.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </ContentBox>
        }
      />
    );
  }
}

export default compose(
  withRouter,
  withTranslation()
)(FaqContainer);

import React from 'react';
import { isEmpty } from 'lodash';
import TablePerformancePerMerchant from 'components/TablePerformancePerMerchant';

export default function PerformancePerMerchant({ data, t }) {
  if (isEmpty(data)) return null;

  return (
    <>
      <TablePerformancePerMerchant data={data} t={t} />
    </>
  );
}

import { createPromiseAction } from '@adobe/redux-saga-promise';

export const ADDRESS = {
  GET: 'ADDRESS_GET',
  GET_FAIL: 'ADDRESS_GET_FAIL',
  GET_OK: 'ADDRESS_GET_OK',
  CLEAR_VALUES: 'ADDRESS_CLEAR_VALUES',
  CLEAR_ERRORS: 'ADDRESS_CLEAR_ERRORS'
};

export default {
  getAddress: createPromiseAction(ADDRESS.GET),
  getAddressOk: ({ city, streetName }) => ({
    type: ADDRESS.GET_OK,
    city,
    streetName
  }),
  getAddressFail: error => ({
    type: ADDRESS.GET_FAIL,
    error
  }),
  clearAddress: () => ({
    type: ADDRESS.CLEAR_VALUES
  }),
  clearErrors: () => ({
    type: ADDRESS.CLEAR_ERRORS
  })
};

/* eslint-disable func-names */
/* eslint-disable no-extend-native */
Object.size = obj => {
  return Object.keys(obj).length;
};

String.prototype.truncate = function (num) {
  if (this.length <= (num - 3)) {
    return this;
  }
  return `${this.slice(0, num)}...`;
};
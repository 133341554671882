import { createPromiseAction } from '@adobe/redux-saga-promise';

export const SHIPMENTS = {
  GET: 'SHIPMENTS_GET',
  GET_FAIL: 'SHIPMENTS_GET_FAIL',
  GET_OK: 'SHIPMENTS_GET_OK',

  SEARCH: 'SHIPMENTS_SEARCH',
  SEARCH_FAIL: 'SHIPMENTS_SEARCH_FAIL',
  SEARCH_OK: 'SHIPMENTS_SEARCH_OK',

  GET_ONE: 'SHIPMENTS_GET_ONE',
  GET_ONE_FAIL: 'SHIPMENTS_GET_ONE_FAIL',
  GET_ONE_OK: 'SHIPMENTS_GET_ONE_OK',

  CLEAR_SHIPMENT: 'SHIPMENTS_CLEAR_SHIPMENT',

  CREATE_LABEL: 'SHIPMENTS_CREATE_LABEL',
  CREATE_LABEL_FAIL: 'SHIPMENTS_CREATE_LABEL_FAIL',
  CREATE_LABEL_OK: 'SHIPMENTS_CREATE_LABEL_OK',

  CREATE_SHIPMENT: 'SHIPMENTS_CREATE_SHIPMENT',

  UPDATE_ONE: 'SHIPMENTS_UPDATE_ONE',
  UPDATE_ONE_FAIL: 'SHIPMENTS_UPDATE_ONE_FAIL',
  UPDATE_ONE_OK: 'SHIPMENTS_UPDATE_ONE_OK',

  UPDATE_PICKUP: 'SHIPMENTS_UPDATE_PICKUP',
  UPDATE_PICKUP_FAIL: 'SHIPMENTS_UPDATE_PICKUP_FAIL',
  UPDATE_PICKUP_OK: 'SHIPMENTS_UPDATE_PICKUP_OK',

  CANCEL_ONE: 'SHIPMENTS_CANCEL_ONE',
  CANCEL_ONE_FAIL: 'SHIPMENTS_CANCEL_ONE_FAIL',
  CANCEL_ONE_OK: 'SHIPMENTS_CANCEL_ONE_OK',

  UPDATE_APPROVE_STATUS: 'SHIPMENTS_UPDATE_APPROVE_STATUS',
  UPDATE_APPROVE_STATUS_FAIL: 'SHIPMENTS_UPDATE_APPROVE_STATUS_FAIL',
  UPDATE_APPROVE_STATUS_OK: 'SHIPMENTS_UPDATE_APPROVE_STATUS_OK',

  SET_PAGE: 'SHIPMENTS_SET_PAGE',
  SET_SHOP_ID: 'SHIPMENTS_SET_SHOP_ID',
  CLEAR_ERRORS: 'SHIPMENTS_CLEAR_ERRORS'
};

export default {
  getShipments: ({
    scope,
    page,
    sorting,
    isAdmin,
    withAuthor,
    shopId,
    from,
    to,
    byStatus,
    byOrigin
  }) => ({
    scope,
    type: SHIPMENTS.GET,
    page,
    sorting,
    isAdmin,
    withAuthor,
    shopId,
    from,
    to,
    byStatus,
    byOrigin
  }),
  getShipmentsOk: (pageName, { totalPages, page, shipments }) => ({
    type: SHIPMENTS.GET_OK,
    featureName: 'getShipments',
    pageName,
    totalPages,
    page,
    shipments
  }),
  getShipmentsFail: (pageName, error) => ({
    type: SHIPMENTS.GET_FAIL,
    featureName: 'getShipments',
    pageName,
    error
  }),
  searchShipments: (searchKey, page, sorting, isAdmin) => ({
    type: SHIPMENTS.SEARCH,
    searchKey,
    page,
    sorting,
    isAdmin
  }),
  searchShipmentsOk: ({ totalPages, page, shipments }) => ({
    type: SHIPMENTS.SEARCH_OK,
    featureName: 'getShipments',
    totalPages,
    page,
    shipments
  }),
  searchShipmentsFail: error => ({
    featureName: 'getShipments',
    type: SHIPMENTS.SEARCH_FAIL,
    error
  }),
  getShipment: createPromiseAction(SHIPMENTS.GET_ONE),
  clearShipment: () => ({
    type: SHIPMENTS.CLEAR_SHIPMENT
  }),
  getShipmentOk: shipment => ({
    featureName: 'getShipments',
    type: SHIPMENTS.GET_ONE_OK,
    shipment
  }),
  getShipmentFail: error => ({
    featureName: 'getShipments',
    type: SHIPMENTS.GET_ONE_FAIL,
    error
  }),
  createLabel: id => ({
    type: SHIPMENTS.CREATE_LABEL,
    id
  }),
  createLabelOk: () => ({
    type: SHIPMENTS.CREATE_LABEL_OK,
    featureName: 'createLabel'
  }),
  createLabelFail: error => ({
    type: SHIPMENTS.CREATE_LABEL_FAIL,
    featureName: 'createLabel',
    error
  }),
  createShipment: createPromiseAction(SHIPMENTS.CREATE_SHIPMENT),
  updatePickup: createPromiseAction(SHIPMENTS.UPDATE_PICKUP),
  updatePickupOk: () => ({
    type: SHIPMENTS.UPDATE_PICKUP_OK,
    featureName: 'updatePickup'
  }),
  updatePickupFail: error => ({
    type: SHIPMENTS.UPDATE_PICKUP_FAIL,
    featureName: 'updatePickup',
    error
  }),
  cancelShipment: (shipmentId, shopId) => ({
    type: SHIPMENTS.CANCEL_ONE,
    shipmentId,
    shopId
  }),
  cancelShipmentOk: () => ({
    type: SHIPMENTS.CANCEL_ONE_OK,
    featureName: 'cancelShipment'
  }),
  cancelShipmentFail: error => ({
    type: SHIPMENTS.CANCEL_ONE_FAIL,
    featureName: 'cancelShipment',
    error
  }),
  approveShipment: values => ({
    type: SHIPMENTS.APPROVE_ONE,
    values
  }),
  approveShipmentOk: () => ({
    type: SHIPMENTS.APPROVE_ONE_OK
  }),
  approveShipmentFail: error => ({
    type: SHIPMENTS.APPROVE_ONE_FAIL,
    error
  }),
  updateApproveStatus: values => ({
    type: SHIPMENTS.UPDATE_APPROVE_STATUS,
    values
  }),
  updateApproveStatusOk: () => ({
    type: SHIPMENTS.UPDATE_APPROVE_STATUS_OK
  }),
  updateApproveStatusFail: error => ({
    type: SHIPMENTS.UPDATE_APPROVE_STATUS_FAIL,
    error
  }),
  setPage: (forPage, page) => ({
    type: SHIPMENTS.SET_PAGE,
    featureName: forPage,
    page
  }),
  setShopId: shopId => ({
    type: SHIPMENTS.SET_SHOP_ID,
    shopId
  }),
  clearErrors: () => ({
    type: SHIPMENTS.CLEAR_ERRORS
  })
};

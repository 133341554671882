/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import cn from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import './index.scss';

const labels365 = data => {
  const date = data[0].label.substring(0, 2);
  const items = data.filter(item => item.label.substring(0, 2) === date);
  return items;
};

const labels30 = data => data.filter((value, index) => index % 2 === 0);

function getLabels(data) {
  if (data.length < 15) {
    return data;
  } else if (data.length <= 30) {
    return labels30(data);
  } else {
    return labels365(data);
  }
}

function Chart({ data = [] }) {
  const el = document.getElementById('root');
  // const style = window.getComputedStyle(el, null).getPropertyValue('font-size');
  // const fontSize = parseFloat(style);
  const computedStyle = window.getComputedStyle(el, null);
  const strHeight = computedStyle.getPropertyValue('height');
  const strWidth = computedStyle.getPropertyValue('width');
  const defaultHeight = parseFloat(strHeight);
  const defaultWidth = parseFloat(strWidth);

  const px = rems => {
    return Math.round((rems * defaultHeight) / 83, -2);
  };

  const maxValue = Math.max(...data.map(item => +item.value));
  const activeHeight = px(20);
  const xLegendHeight = px(2.4);
  const activeWidth = defaultWidth / 2.3;
  const yLegendWidth = px(4);

  const barGap = activeWidth / data.length;
  const barWidth = data.length > 30 ? barGap : barGap / 2;
  const gridHeight = activeHeight - px(0.77);
  // generate possible round values for grid
  const ceilings = [];
  for (let i = 1; i < 10; i++) {
    ceilings.push(i);
  }
  for (let i = 10; i <= 10000; i += 10) {
    ceilings.push(i);
  }
  const ceiling = ceilings.find(ceil => maxValue <= ceil);
  const maxGridValue = Math.ceil(maxValue / ceiling) * ceiling;
  const grade = ceilings.find(item => {
    const temp = maxGridValue / item;
    return temp > 5 && temp <= 10;
  });
  const degree = gridHeight / maxGridValue;
  const gridLinesAmount = maxGridValue / grade;
  const paddingTop = px(2);

  const renderBars = () => {
    const rects = data.map((item, index) => {
      return (
        <Tippy
          key={index}
          placement="bottom"
          content={
            <>
              {item.fullLabel} - {item.value}
            </>
          }
        >
          <rect
            key={index}
            className={cn('chart__bar', {
              'chart__bar--low': +item.value < maxValue * 0.4
            })}
            x={activeWidth - index * barGap - barWidth - barWidth / 2}
            height={+item.value * degree}
            width={barWidth}
          />
        </Tippy>
      );
    });

    return rects;
  };

  const renderXLegend = () => {
    const labels = getLabels(data);
    // const labels = data.length > 30 ? monthlyLabels(data) : data;
    const barGapLocal = activeWidth / labels.length;
    const barWidthLocal = data.length > 30 ? 0 : barGapLocal / 2;

    const texts = labels.map((item, index) => {
      return (
        <text
          key={index}
          x={index * barGapLocal + barWidthLocal / 2}
          y={px(1.54)}
          className="chart__bar__x-legend-text"
        >
          {item.label}
        </text>
      );
    });

    return texts;
  };

  const renderYLegend = () => {
    const halfLetterHeight = px(0.09);
    const cellHeight = activeHeight / gridLinesAmount - halfLetterHeight;
    const texts = [];
    for (let i = 0; i <= gridLinesAmount + 1; i++) {
      texts.push(
        <text
          key={i}
          x="50%"
          y={activeHeight - i * cellHeight + px(2.3)}
          className="chart__bar__y-legend-text"
          textAnchor="middle"
        >
          {Math.round((maxGridValue / gridLinesAmount) * i)}
        </text>
      );
    }

    return texts;
  };

  const renderGrid = () => {
    const cellHeight = gridHeight / gridLinesAmount;
    const lines = [];
    for (let i = 0; i <= gridLinesAmount + 1; i++) {
      lines.push(
        <line
          key={i}
          x1="0"
          y1={cellHeight * i}
          x2={activeWidth}
          y2={cellHeight * i}
          stroke="#a19fc8"
          strokeWidth={px(0.15)}
          strokeOpacity="0.22"
        />
      );
    }
    return lines;
  };

  return (
    <div className="chart__container">
      <svg
        width={yLegendWidth}
        height={activeHeight + xLegendHeight + paddingTop}
      >
        <g>{renderYLegend()}</g>
      </svg>
      <div className="chart__main">
        <svg
          width={activeWidth}
          height={activeHeight + paddingTop}
          transform="rotate(180)"
        >
          <g id="chart__bars" className="bars">
            {renderGrid()}
            {renderBars()}
          </g>
        </svg>
        <svg width={activeWidth} height={xLegendHeight}>
          <g id="chart__legend">{renderXLegend()}</g>
        </svg>
      </div>
    </div>
  );
}

export default Chart;

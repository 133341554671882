import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import get from 'lodash/get';
import { exportUrl } from 'features/rootApi';
import useDidMount from 'hooks/useDidMount';
import SearchBar from 'components/SearchBar';
import shipmentActions from 'features/shipment/actions';
import shopActions from 'features/shop/actions';
import { dispatch } from 'store';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import TableShipments from 'components/TableShipments';
import ShopSelector from 'components/ShopSelector';
import DateFilterForm from 'components/DateFilterForm';
import Button from 'components/Button';
import useFromToFilter from 'hooks/useFromToFilter';
import { isBlank } from 'utils/textHelper';
import Switch from 'components/Switch';
import styled from 'styled-components';
import { ORIGIN_OPTIONS, STATUS_OPTIONS } from 'config/constants';
import i18n from 'config/i18n';

const AdvancedSearchBar = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

function Shipments(props) {
  const { isAdmin, shipmentsData, shopId, t } = props;
  const page = get(shipmentsData, 'page', 1);
  const [sorting, setSorting] = useState({});
  const [status, setStatus] = useState();
  const [origin, setOrigin] = useState();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(true);
  const [shopsLoading, setShopsLoading] = useState({});
  const [allShopOptions, setAllShopOptions] = useState([]);
  const { from, setFrom, to, setTo } = useFromToFilter();

  const didMount = useDidMount();
  const doOnSecondRender = fn => {
    if (shipmentsData) {
      if (didMount) return fn();

      return undefined;
    }
    return fn();
  };

  const fetchShipments = () => {
    const fetch = () =>
      dispatch(
        shipmentActions.getShipments({
          scope: 'forShipmentsPage',
          page,
          sorting,
          isAdmin,
          shopId,
          from,
          to,
          byStatus: status,
          byOrigin: origin
        })
      );

    if (page > 1) {
      doOnSecondRender(() => {
        fetch();
      });
      return;
    }

    fetch();
  };

  const updatePage = newPage => {
    doOnSecondRender(() =>
      dispatch(shipmentActions.setPage('forShipmentsPage', newPage))
    );
  };

  const setShopId = newShopId => {
    doOnSecondRender(() => dispatch(shipmentActions.setShopId(newShopId)));
  };

  const onSort = (sortKey, sortCriteria) =>
    setSorting({ sortKey, sortCriteria });

  const getShops = async () => {
    setShopsLoading(true);

    let options;
    try {
      options = await dispatch(shopActions.getShops());
    } finally {
      setShopsLoading(false);
    }

    setAllShopOptions(options);
  };

  useEffect(() => {
    getShops();
    // eslint-disable-next-line
  }, []);

  useEffect(fetchShipments, [page, shopId, from, to, origin, status]);
  useEffect(() => {
    updatePage(1);
    // eslint-disable-next-line
  }, [sorting, shopId]);

  let shopName = t('general.allShops');
  const selectedOption = allShopOptions.find(option => option.value === shopId);
  if (selectedOption) shopName = selectedOption.text;

  const titleParts = [t('general.recentShipments')];
  if (allShopOptions.length > 1) titleParts.push(shopName);
  const titleText = titleParts.join(' - ');

  return (
    <MainContentContainer
      style={{ height: '100%' }}
      mainStyles={{
        width: '98%',
        minHeight: '100%',
        height: '100%',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      main={
        <>
          <SearchBar />
          <ContentBox
            style={{
              minWidth: '100%',
              width: '100%',
              flexGrow: 1,
              display: 'flex'
            }}
            containerStyle={{ flexDirection: 'column', width: '100%' }}
          >
            <ContentBox.Row
              header={
                <ContentBox.Row.Header text={titleText}>
                  <Switch
                    handleChange={val => setShowAdvancedSearch(!!val)}
                    value={showAdvancedSearch}
                    styles={{
                      container: { marginLeft: '.5rem', marginRight: '.5rem' }
                    }}
                  >
                    {showAdvancedSearch
                      ? 'Hide advanced search options'
                      : 'Show advanced search options'}
                  </Switch>
                </ContentBox.Row.Header>
              }
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1
              }}
              content={
                <>
                  <AdvancedSearchBar visible={showAdvancedSearch}>
                    {allShopOptions.length > 1 && (
                      <ShopSelector
                        options={allShopOptions}
                        onSelect={value => setShopId(value)}
                        loading={shopsLoading}
                        selectedValue={shopId}
                        containerStyles={{ marginRight: '.5rem' }}
                      />
                    )}
                    <DateFilterForm t={t} setFrom={setFrom} setTo={setTo} />
                    <Select
                      styles={{
                        container: styles => ({
                          ...styles,
                          minWidth: '17rem',
                          marginRight: '1rem'
                        })
                      }}
                      defaultValue={STATUS_OPTIONS.find(
                        el => el.value === status
                      )}
                      isClearable
                      isSearchable
                      name="status"
                      options={STATUS_OPTIONS}
                      placeholder='By status'
                      onChange={option => setStatus(option?.value)}
                      getOptionLabel={option => `${option.text}`}
                      getOptionValue={option => option.value}
                    />
                    <Select
                      styles={{
                        container: styles => ({
                          ...styles,
                          minWidth: '17rem',
                          marginRight: '1rem' 
                        })
                      }}
                      defaultValue={ORIGIN_OPTIONS.find(
                        el => el.value === origin
                      )}
                      isClearable
                      isSearchable
                      name="origin"
                      options={ORIGIN_OPTIONS}
                      onChange={option => setOrigin(option?.value)}
                      getOptionLabel={option => `${option.text}`}
                      getOptionValue={option => option.value}
                      placeholder='By origin'
                      />
                    <Button
                      disabled={isBlank(from) || (isAdmin && isBlank(shopId))}
                      onClick={() => {
                        window.open(
                          exportUrl({
                            from,
                            to,
                            byStatus: status,
                            byOrigin: origin,
                            shopId,
                            locale: i18n.language
                          }),
                          '_blank'
                        );
                      }}
                      text={t('shipments.export')}
                      className="button--secondary"
                      style={{ height: '2.65rem', marginLeft: '.5rem' }}
                    />
                  </AdvancedSearchBar>
                  <TableShipments
                    featureName="getShipments"
                    scope="forShipmentsPage"
                    onPageChange={value => updatePage(value)}
                    onSort={onSort}
                    sorting={sorting}
                    isAdmin={isAdmin}
                  />
                </>
              }
            />
          </ContentBox>
        </>
      }
    />
  );
}

const mapStateToProps = ({
  auth: { user },
  shipment: { forShipmentsPage: shipmentsData, shopId }
}) => ({
  isAdmin: get(user, 'isAdmin', false),
  shipmentsData,
  shopId
});
export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Shipments);

import en from './en';
import nl from './nl';

export default {
  en: {
    translation: en
  },
  nl: {
    translation: nl
  }
};

import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations';
import 'moment/locale/nl';

i18n
  .on('languageChanged', (lng) => {
    moment.locale(lng);
  })
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
//    lng: 'nl',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });


export function langCode() {
  let code = i18n.language.substring(0, 2);
  if (!['en', 'nl'].includes(code)) code = 'en';
  return code;
}

export default i18n;

/* eslint-disable consistent-return */
import { get } from 'lodash';
import { compare } from 'utils/textHelper';

export function wrapShop(shop) {
  if (!shop) return shop;

  const countryOptionsRaw = get(shop, 'countryOptions', []);
  const countryOptions = countryOptionsRaw.sort(compare);
  const returnReasons = get(shop, 'returnReasons', {});
  return { ...shop, countryOptions, returnReasons };
}

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

const IE_STATES = {
  N0: 'Carlow',
  N1: 'Cavan',
  N2: 'Clare',
  N3: 'Cork',
  N4: 'Donegal',
  N5: 'Dublin',
  N6: 'Galway',
  N7: 'Kerry',
  N8: 'Kildare',
  N9: 'Kilkenny',
  NA: 'Laois',
  NG: 'Leitrim',
  NI: 'Limerick',
  NK: 'Longford',
  NL: 'Louth',
  NN: 'Mayo',
  NO: 'Meath',
  NP: 'Monaghan',
  NQ: 'Offaly',
  NR: 'Roscommon',
  NW: 'Sligo',
  NX: 'Tipperary NR',
  NY: 'Tipperary  SR',
  NZ: 'Waterford',
  '10': 'Westmeath',
  '11': 'Wexford',
  '12': 'Wicklow'
};

const collectOptions = states => {
  const options = [];
  Object.keys(states).forEach(code => {
    const title = states[code];
    const option = {
      text: title,
      value: code
    };
    options.push(option);
  });

  return options;
};

export const STATES_OPTIONS = {
  ie: collectOptions(IE_STATES),
  us: collectOptions(US_STATES)
};

export const COUNTRIES_WITH_STATES = {
  pickup: ['us', 'ie'],
  dropoff: ['us', 'ie']
}; // for UPS only

export const TIMESLOT_FIELDS = {
  ID: 'id',
  START: 'start',
  STOP: 'stop'
};

export const SHIPMENT_FIELDS = {
  ID: 'id',
  RETURN_TYPE: 'returnType',
  NUMBER_OF_BOXES: 'numberOfBoxes',
  POSTAL_CODE: 'postalCode',
  POSTAL_CODE_END: 'postalCodeEnd',
  HOUSE_NUMBER: 'houseNumber',
  HOUSE_ADDITION: 'houseAddition',
  SHIP_FROM_COUNTRY_CODE: 'shipFromCountryCode',
  SHIP_FROM_STREET: 'shipFromStreet',
  SHIP_FROM_CITY: 'shipFromCity',
  SHIP_FROM_POSTAL_CODE: 'shipFromPostalCode',
  SHIP_FROM_HOUSE_NUMBER: 'shipFromHouseNumber',
  SHIP_FROM_ADDRESS_LINE: 'shipFromAddressLine',
  SHIP_FROM_STATE_PROVINCE_CODE: 'shipFromStateProvinceCode',
  TIMESLOT_ID: 'timeslotId',
  START: 'start',
  STOP: 'stop',
  NAME: 'name',
  EMAIL: 'email',
  ORDER_NUMBER: 'orderNumber',
  COMMENTS: 'comments',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  INSURED: 'insured',
  NETWORK: 'network',
  PHONE: 'phone',
  PAYMENT_URL: 'paymentUrl',
  PAYMENT_STATUS: 'paymentStatus',
  RETURN_REASON_ID: 'returnReasonId',
  CUSTOM_RETURN_REASON: 'customReturnReason',
  CONSENT: 'consent',
  IMAGE: 'image',
  PROCEEDED: 'proceeded',
  APPROVE_STATUS: 'approveStatus',
  IS_INTERNATIONAL: 'isInternational',
  LABEL_URL: 'labelUrl',
  STEP: 'step',
  SIZE: 'size',
  LOCALE: 'locale'
};

export const NETWORKS = {
  UPS: 'ups',
  DHL: 'dhl',
  DPD: 'dpd',
  BRENGER: 'brenger',
  HOMERR: 'homerr'
};

export const RETURN_TYPES = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff'
};

export const SIZES = {
  LETTERBOX: 'letterbox',
  PARCEL: 'parcel'
};

export const SHOP_FIELDS = {
  ID: 'id',
  REPAIR_SHOP: 'repairShop',
  GATEKEEPER_ENABLED: 'gatekeeperEnabled',
  PRICELISTS: 'pricelists',
  COUNTRIES: 'countries',
  SHOP_OPTIONS: 'shopOptions',
  START_WITH_SHOPS_PAGE: 'startWithShopsPage',
  SAME_SHOP: 'sameShop',
  NETWORKS: 'networks',
  RETURN_TYPES: 'returnTypes',
  INITIAL_PRICELISTS: 'priceLists',
  AVAILABLE_SERVICES: 'availableServices',
  PAPERLESS_ORIGINS: 'paperlessOrigins',
  PAPERLESS_AVAILABLE: 'paperlessAvailable'
};

export const RETURN_REASON_FIELDS = {
  OFFERS_EXCHANGE: 'offersExchange',
  WRONG_ITEM: 'wrongItem',
  DEFECT: 'defect'
};

export const FLOWS = {
  PICKUP: 'order-pickup',
  DROPOFF: 'drop-off'
};

export const PATHS = {
  DETAILS: 'details',
  ADDRESS: 'address',
  CONFIRMATION: 'confirmation',
  PARCELS: 'parcels',
  TIMESLOT: 'time-slot',
  RETURN_TYPE: 'return-type'
};

export const APPROVE_STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DECLINED: 'declined'
};

export const PAYMENT_STATUSES = {
  PENDING: ['open'],
  FAILED: ['failed', 'expired', 'canceled'],
  PAID: ['paid']
};

export const LOCAL_ADDRESS_FIELDS = {
  POSTAL_CODE: 'postalCode',
  POSTAL_CODE_END: 'postalCodeEnd',
  HOUSE_NUMBER: 'houseNumber',
  HOUSE_ADDITION: 'houseAddition',
  STREET: 'street',
  CITY: 'city'
};

export const INTERNATIONAL_ADDRESS_FIELDS = {
  SHIP_FROM_ADDRESS_LINE: 'shipFromAddressLine',
  SHIP_FROM_CITY: 'shipFromCity',
  SHIP_FROM_COUNTRY_CODE: 'shipFromCountryCode',
  SHIP_FROM_HOUSE_NUMBER: 'shipFromHouseNumber',
  SHIP_FROM_POSTAL_CODE: 'shipFromPostalCode',
  SHIP_FROM_STATE_PROVINCE_CODE: 'shipFromStateProvinceCode',
  SHIP_FROM_STREET: 'shipFromStreet'
};

export const STATUS_OPTIONS = [
  {
    text: 'All',
    value: null
  },
  {
    text: 'New',
    value: 'new'
  },
  {
    text: 'Delivered',
    value: 'delivered'
  },
  {
    text: 'Unfulfilled',
    value: 'unfulfilled'
  },
  {
    text: 'Incident',
    value: 'incident'
  }
];

export const ORIGIN_OPTIONS = [
  {
    text: 'All',
    value: 'all'
  },
  {
    text: 'By customer',
    value: 'by_customer'
  },
  {
    text: 'By support',
    value: 'by_admin'
  },
  {
    text: 'By shop admin',
    value: 'by_shop_admin'
  }
];

import { STATS } from './actions';

export const INITIAL_STATE = {
  dailyCounters: [],
  totalCounters: {},
  monthlyCounters: [],
  billedCounters: {},
  returnReasonCounters: [],
  shopCounters: {},
  error: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { dailyCounters, totalCounters, monthlyCounters, billedCounters, returnReasonCounters, shopCounters, error } = action;
  switch (action.type) {
    case STATS.GET_DAILY_COUNTERS_OK:
      return {
        ...state,
        dailyCounters,
        error: null
      };
    case STATS.GET_DAILY_COUNTERS_FAIL:
      return {
        ...state,
        dailyCounters: [],
        error
      };
    case STATS.GET_TOTAL_COUNTERS_OK:
      return {
        ...state,
        totalCounters,
        billedCounters,
        error: null
      };
    case STATS.GET_TOTAL_COUNTERS_FAIL:
      return {
        ...state,
        totalCounters: {},
        billedCounters: {},
        error
      };
    case STATS.GET_SHOP_COUNTERS_OK:
      return {
        ...state,
        monthlyCounters,
        returnReasonCounters,
        billedCounters,
        shopCounters,
        error: null
      };
    case STATS.GET_SHOP_COUNTERS_FAIL:
      return {
        ...state,
        monthlyCounters: [],
        returnReasonCounters: [],
        billedCounters: {},
        error
      };
    default:
      return state;
  }
};

import React from 'react';
import Table, { ALIGNMENT } from 'components/Table';
import 'components/TableShipments/index.scss';
import './index.scss';

// In this component we utilize the styles from the TableShipments component
// because there it's not any difference

const TablePerformancePerCountry = ({ data, t }) => {
  const headers = {
    total: t('stats.total'),
    totalDropoffs: t('stats.totalDropoff', { network: t('stats.total') }),
    dhlDropoffs: t('stats.totalDropoff', { network: 'DHL' }),
    upsDropoffs: t('stats.totalDropoff', { network: 'UPS' }),
    totalPickups: t('stats.totalPickup', { network: t('stats.total') }),
    dhlPickups: t('stats.totalPickup', { network: 'DHL' }),
    upsPickups: t('stats.totalPickup', { network: 'UPS' }),
    totalFree: t('stats.totalFree'),
    createdByAdmins: t('stats.byCreator.byAdmins'),
    createdByShopAdmins: t('stats.byCreator.byShopAdmins'),
  };

  const columns = header =>
    header.map((code, index) => ({
      id: code,
      title: headers[code],
      accessor: d => d[index],
      alignment: index > 0 ? ALIGNMENT.CENTER : ALIGNMENT.LEFT
    }));

  return (
    <div className="table-shipments__container">
      <div className="shipments-table__table-container">
        <Table
          className="table-performace-per-merchant"
          data={data.body}
          columns={columns(data.header)}
          t={t}
        />
      </div>
    </div>
  );
};

export default TablePerformancePerCountry;

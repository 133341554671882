import { PROFILE } from './actions';

export const INITIAL_STATE = {
  errors: {}
};

export default (state = INITIAL_STATE, action = {}) => {
  const { error, actionName } = action;
  const errors = { [actionName]: error };
  switch (action.type) {
    case PROFILE.UPDATE_NAME_FAIL:
    case PROFILE.SEND_RESET_PASSWORD_URL_FAIL:
    case PROFILE.UPDATE_PASSWORD_FAIL:
    case PROFILE.RESET_PASSWORD_FAIL:
      return {
        ...state,
        errors
      };
    case PROFILE.REMOVE_ERROR:
      return {
        ...state,
        errors: {}
      };
    case PROFILE.RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

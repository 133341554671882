import { isBlank } from 'utils/textHelper';
import { post } from '../rootApi';

// GET SHOP
export function getShopRequest(shopId) {
  const paramsLine = isBlank(shopId) ? '' : `(shopId: "${shopId}")`;
  const query = `
  {
    shop${paramsLine} {
      id
      code
      name
      slug
      frontendAssetId
      shopUrl
      hasFreeShipments
      termsAndConditionsUrl
      networks
      countries
      countryOptions
      paperlessOrigins
      insuranceOptionEnabled
      gatekeeperEnabled
      multiDestinationsEnabled
      showDescription
      requireDescription
      simpleLabels
      showPersonalFieldsForDropoff
      showNameField
      showReturnReasons
      hasExchangeOption
      locations {
        id
        name 
        postalCode
        city
        street
        houseNumber
        default
        creatingLabels
      }
      priceLists {
        network
        pickupParcelPrice
        dropoffParcelPrice
        dropoffLetterboxPrice
        optionalPrice
        countryCode
      }
    }
  }
  `;
  return post({ query });
}

// GET ALL SHOPS
export function getShopsRequest() {
  const query = `
  {
    shops {
      id
      name
      nickname
    }
  }
 `;

  return post({ query });
}

/* eslint-disable no-bitwise */
import React from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { dispatch } from 'store';
import noteActions from 'features/note/actions';
import i18n, { langCode } from 'config/i18n';
import Breadcrumbs from 'components/Breadcrumbs';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import Button from 'components/Button';
import Note from 'components/Note';
import TeamMemberAvatar from 'components/TeamMemberAvatar';
import EditNameModal from './EditNameModal';
import EditPasswordModal from './EditPasswordModal';
import './index.scss';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editPassword: false,
      editName: false,
      notes: [],
      user: props.user
    };
  }

  componentDidMount() {
    const {
      user: { sub: userId }
    } = this.props;
    dispatch(noteActions.getNotes('forProfilePage', 1, { userId }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    const { user, notes } = nextProps;
    if (!isEqual(user, prevState.user)) {
      newState.user = user;
      newState.editName = false;
      newState.editPassword = false;
    }

    if (!isEqual(notes, prevState.notes)) newState.notes = notes;

    return newState;
  }

  changeLanguage() {
    const newLocale = langCode() === 'nl' ? 'en' : 'nl';
    i18n.changeLanguage(newLocale);
  }

  trigger(name) {
    const value = get(this.state, name);
    this.setState({ [name]: !value });
  }

  render() {
    const { history, t } = this.props;
    const { notes, user, editName, editPassword } = this.state;
    return (
      <>
        <MainContentContainer
          style={{ height: 'fit-content' }}
          mainStyles={{
            width: '98%',
            height: '100%',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          main={
            <>
              <Breadcrumbs style={{ marginBottom: '2.5rem' }}>
                <Breadcrumbs.Item
                  onClick={() => history.push('/overview')}
                  tabIndex={0}
                >
                  {t('general.overview')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  onClick={() => history.push('/team')}
                  tabIndex={-1}
                >
                  {t('general.team')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('general.profile')}</Breadcrumbs.Item>
              </Breadcrumbs>
              <div className="profile-container">
                <ContentBox
                  withNoBorderRadius
                  style={{
                    minWidth: '50%',
                    width: '50%'
                  }}
                  containerStyle={{
                    flexDirection: 'column',
                    minHeight: '100%'
                  }}
                >
                  <ContentBox.Row
                    header={<ContentBox.Row.Header text={t('team.detail')} />}
                    content={
                      <>
                        <TeamMemberAvatar user={user} />
                        <div className="profile__info-fields">
                          <span>{t('general.email')}:</span> {user.email}
                        </div>
                        <div className="profile__buttons">
                          <Button
                            style={{ height: '3.75rem' }}
                            text={t('profile.editNameButton')}
                            className="button--primary"
                            onClick={() => this.trigger('editName')}
                          />
                          <Button
                            style={{ height: '3.75rem' }}
                            text={t('profile.changePasswordButton')}
                            className="button--primary"
                            onClick={() => this.trigger('editPassword')}
                          />
                          <Button
                            style={{ height: '3.75rem' }}
                            text={t('profile.changeLanguage')}
                            className="button--primary"
                            onClick={() => this.changeLanguage()}
                          />
                        </div>
                      </>
                    }
                  />
                </ContentBox>
                <ContentBox
                  withNoBorderRadius
                  style={{
                    minWidth: '50%',
                    width: '50%'
                  }}
                  containerStyle={{
                    flexDirection: 'column',
                    minHeight: '100%',
                    height: '100%'
                  }}
                >
                  <ContentBox.Row
                    style={{ overflowY: 'scroll' }}
                    header={<ContentBox.Row.Header text={t('notes.myNotes')} />}
                    content={
                      <>
                        {notes.map((note, index) => (
                          <Note key={index} note={note} t={t} />
                        ))}
                      </>
                    }
                  />
                </ContentBox>
              </div>
            </>
          }
        />
        {editName && (
          <EditNameModal
            initialValues={{
              firstName: user.first_name,
              lastName: user.last_name
            }}
            visible
            onClose={() => this.trigger('editName')}
            t={t}
          />
        )}
        {editPassword && (
          <EditPasswordModal
            visible
            onClose={() => this.trigger('editPassword')}
            t={t}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  email: state.auth.email,
  errors: state.profile.errors,
  notes: state.note.forProfilePage.notes
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Profile);

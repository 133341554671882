import React from 'react';
import { isEmpty } from 'lodash';
import TablePerCountryPerMerchant from 'components/TablePerCountryPerMerchant';

export default function PerCountryPerMerchant({ data, t }) {
  if (isEmpty(data)) return null;

  return (
    <>
      <TablePerCountryPerMerchant data={data} t={t} />
    </>
  );
}

import React from 'react';
import ReactSVG from 'react-svg';
import logo from 'assets/images/returnista-logo.svg';

import './index.scss';

export default ({ loading }) => {
  if (!loading) {
    document.body.classList.remove('overflow--hidden');
    return null;
  }

  document.body.classList.add('overflow--hidden');
  return (
    <div className="loader">
      <ReactSVG src={logo} />
      <span className="loader__header">Loading</span>
      <span className="loader__header">…</span>
    </div>
  );
};

import { put, call, select } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { get } from 'lodash';
import { handleAPIError } from 'utils/sagaUtils';
import { capitalize } from 'utils/textHelper';
import { getShopRequest, getShopsRequest } from './api';
import shopActions from './actions';
import { wrapShop } from './helper';
import { getUser } from 'utils/selectors';
import userFlags from 'utils/userFlags';

export function* getShop(action) {
  yield call(implementPromiseAction, action, function*() {
    const { payload: shopId } = action;

    try {
      const response = yield getShopRequest(shopId);
      const rawShop = get(response, 'data.data.shop');
      const shop = wrapShop(rawShop);

      yield put(shopActions.getShopOk(shop));
    } catch (error) {
      handleAPIError(error);
      throw error;
    }
  });
}

export function* getShops(action) {
  yield call(implementPromiseAction, action, function*() {
    let response;

    try {
      response = yield getShopsRequest();
    } catch (error) {
      handleAPIError(error);
      throw error;
    }
    const user = yield select(getUser);
    const { isAdmin } = userFlags(user);
    const responseData = get(response, 'data.data.shops');
    const options = responseData.map(shopData => {
      let text = shopData.nickname || shopData.name;
      if (isAdmin)
        text = shopData.nickname
          ? [shopData.name, capitalize(shopData.nickname)].join(' - ')
          : shopData.name;

      return {
        value: shopData.id,
        text: capitalize(text)
      };
    });

    return options;
  });
}

export default {
  countries: {
    at: 'Oostenrijk',
    be: 'België',
    bg: 'Bulgarije',
    cz: 'Tsjechische Republiek',
    de: 'Duitsland',
    dk: 'Denemarken',
    ee: 'Estland',
    es: 'Spanje',
    fi: 'Finland',
    fr: 'Frankrijk',
    gb: 'Groot Brittanië',
    gr: 'Griekenland',
    hr: 'Kroatië',
    hu: 'Hongarije',
    ie: 'Ierland',
    it: 'Italië',
    lt: 'Litouwen',
    lu: 'Luxemburg',
    lv: 'Letland',
    mc: 'Monaco',
    nl: 'Nederland',
    pl: 'Polen',
    pt: 'Portugal',
    ro: 'Roemenië',
    se: 'Zweden',
    si: 'Slovenië',
    sk: 'Slowakije'
  },
  fullMonths: {
    jan: 'Januari',
    feb: 'Februari',
    mar: 'Maart',
    apr: 'April',
    may: 'Mei',
    jun: 'Juni',
    jul: 'Juli',
    aug: 'Augustus',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'December'
  },
  errors: {
    format: {
      invalid_ship_from_house_number: "Invalid house number"
    },
    cancel: {
      after_pickup:
        'Verzending kan niet worden geannuleerd omdat de ophaaldatum in het verleden ligt.',
      incomplete_shipment:
        'Verzending kan niet worden geannuleerd omdat deze onvolledig is.',
      already:
        'Verzending kan niet worden geannuleerd omdat deze al is geannuleerd.',
      label:
        'Verzending kan niet worden geannuleerd omdat het een aflevering betreft'
    },
    locations: {
      invalid_address: 'Geen combinatie van postcode en huisnummer',
      required: 'Sommige leveranciers worden niet gekozen',
      timeout: 'Het antwoord neemt te veel tijd in beslag. Probeer het later nog eens',
    },
    numberOfBoxes: {
      wrongNumber: 'Aantal dozen verschilt van het gevraagde aantal'
    },
    timeslots: {
      uncovered_postal_code: 'Geen tijdsloten voor dit postcode',
      invalid: 'Ongeldig tijdslot, kies een ander'
    },
    user: {
      already_confirmed: 'Deze account is al bevestigd',
      already_reset_password: 'Deze link is al gebruikt',
      already_exists: 'Gebruiker met dit e-mailadres bestaat al.',
      reset_password_token_expired:
        'Reset wachtwoord link is verlopen. Vraag een nieuw aan.'
    },
    passwords: {
      blank: 'Er is geen wachtwoord opgegeven',
      too_short: 'Het opgegeven wachtwoord was te kort',
      too_long: 'Het opgegeven wachtwoord was te lang',
      invalid: 'Het opgegeven wachtwoord heeft een verkeerd formaat'
    },
    closeButtonText: 'Gotcha',
    contactAdmin: 'Neem dan contact op met admin.',
    unknown_error: 'Onbekende fout. Neem dan contact op met admin.'
  },
  locales: {
    english: 'Engels',
    dutch: 'Nederlands',
    french: 'Frans',
    deutsch: 'Deutsch'
  },
  pages: {
    login: {
      header: 'Welkom bij Returnista, log in om verder te gaan',
      submit: 'Login',
      back: 'Terug naar Inloggen'
    },
    requestResetPassword: {
      header: 'We hebben uw e-mail nodig om u een resetlink te sturen',
      submit: 'Verder',
      headerAfter:
        'Wij hebben je een reset e-mail gestuurd. Klik op de link in de mail om jouw wachtwoord te resetten.',
      linkTo: 'Wachtwoord vergeten? Klik hier.'
    },
    resetPassword: {
      header: 'Stel een nieuw wachtwoord in',
      password: 'Nieuw paswoord',
      repeatPassword: 'Herhaal nieuw wachtwoord',
      submit: 'Bewaar nieuw wachtwoord'
    },
    investigationForm: {
      investigation: 'Onderzoek',
      addComment: 'Voeg een comment toe',
      info:
        '*De info op deze pagina word uit de shipment pagina gehaald en kan je hier niet aanpassen.',
      placeholder:
        'Type hier een comment die je aan het onderzoek wilt toevoegen.Type a comment that you want to add to the survey.',
      primatyButtonText: 'Start onderzoek'
    }
  },
  form: {
    addNote: {
      body: {
        label: 'Type hier jouw notitie'
      },
      buttonText: 'Opslaan'
    },
    cancelShipment: {
      text: 'Ben je zeker dat je de zending wilt annuleren?',
      notConfirm: 'Niet annuleren',
      confirm: 'Annuleren'
    },
    logout: {
      text: 'Ben je zeker dat je wilt uit loggen?',
      no: 'Annuleren',
      yes: 'Log uit'
    },
    shipmentUpdated: {
      text: 'Wijzigingen opgeslaan en verstuurd.',
      buttonText: 'Sluiten'
    },
    investigationStarted: {
      headerText: 'Onderzoek is gestart.',
      text: 'De vervoeder komt binnen 2 werkdagen met een reactie.',
      buttonText: 'Sluiten'
    },
    noteCreated: {
      text: 'Notitie opgeslaan.',
      buttonText: 'Sluiten'
    },
    locale: { prompt: 'Kies een taal' },
    selectNetwork: 'Selecteer vervoerder',
    selectAmountOfBoxes: 'Selecteer aantal dozen',
    selectOption: 'Kies optie',
    selectCountry: 'Selecteer land',
    selectTimeslot: 'Selecteer tijdvak',
    selectShop: 'Selecteer winkel',
    selectDestination: 'Selecteer een bestemmingspunt',
    selectReturnType: 'Selecteer het retourtype',
    stateProvince: "Staat/Provincie"
  },
  general: {
    allShops: 'Alle winkels',
    free: 'Gratis',
    overview: 'Overzicht',
    dutch: 'Nederlands',
    edit: 'Bewerk',
    editShipment: 'Wijzig zending',
    email: 'E-mail',
    english: 'Engels',
    password: 'Wachtwoord',
    logout: 'Uitloggen',
    myAccount: 'Mijn account',
    newTeamMember: 'Nieuwe teamgenoot',
    no: 'Nee',
    ongoingShipments: 'Lopende zendigen',
    recentShipments: 'Recente zendingen',
    overviewShipment: 'Overzicht zendingen',
    profile: 'Profiel',
    save: 'Opslaan',
    search: 'Zoek',
    shipment: 'Zending',
    shipments: 'Zendingem',
    shipmentsPerDay: 'Aantal zendingen per dag',
    statistics: 'Statistieken',
    team: 'Team',
    yes: 'Ja',
    hide: 'Verbergen',
    investigations: 'Onderzoeken',
    from: 'Van',
    to: 'Tot',
    noData: 'Geen gegevens'
  },
  investigations: {
    unresolved: 'Onopgelost',
    resolved: 'Opgelost',
    all: 'Alles',
    startInvestigation: 'Start een onderzoek',
    closeInvestigation: 'Sluit het onderzoek',
    investigationResolved: 'Onderzoek gesloten',
    areYouSure: 'Weet u zeker dat u het onderzoek wilt afsluiten?',
    resolve: 'Onderzoek afsluiten',
    closeDialog: 'Annuleren'
  },
  home: {
    monthOverview: 'Overzicht'
  },

  login: {
    resetPassword: 'Wachtwoord resetten'
  },
  notes: {
    addNote: 'Voeg notitie toe',
    archive: 'Archiveren',
    myNotes: 'Mijn notities',
    noNoteAvailable: 'Geen opmerkingen beschikbaar',
    notes: 'Notities',
    order: 'Order',
    pickupDetails: 'Bekijk zending',
    recentNotes: 'Recente notities',
    viewNotes: 'Bekijk notities',
    allNotes: 'Alle opmerkingen',
    save: 'Opslaan'
  },
  profile: {
    nameForm: {
      introText: 'U staat op het punt uw naam bij te werken',
      firstName: {
        label: 'Voornaam'
      },
      lastName: {
        label: 'Achternaam'
      }
    },
    passwordForm: {
      introText: 'U staat op het punt uw wachtwoord te wijzigen',
      oldPassword: {
        label: 'Huidig wachtwoord'
      },
      newPassword: {
        label: 'Nieuw paswoord'
      },
      repeatedNewPassword: {
        label: 'Bevestig nieuw wachtwoord'
      },
      successText: 'Wachtwoord succesvol bijgewerkt',
      successButtonText: 'Terug naar profiel'
    },
    cancel: 'Annuleer',
    update: 'Bijwerken',
    editNameButton: 'Naam bewerken',
    changePasswordButton: 'Verander wachtwoord',
    changeLanguage: 'Verander de taal in Engels'
  },
  search: {
    header: 'Zoek resultaten op',
    placeholder:
      'Type e-mail, postcode, ordernummer, naam, telefoon of volgnummer',
    search: 'Zoek'
  },
  shipments: {
    approve: {
      doApprove: 'Goedkeuren',
      doDecline: 'Weigeren',
      markAsAwaiting: 'In afwachting van de klant',
      makePendingAgain: 'Pending',
      dialog: {
        destination: 'Bestemming van de zending',
        customerWillPay: 'De klant zal moeten betalen',
        approvalOptions: 'Goedkeuringsmogelijkheden'
      },
      itsBrenger: 'Deze zending zal worden verwerkt door Brenger'
    },
    customerWillPay: 'De klant zal moeten betalen',
    approveStatus: 'Status goedkeuren',
    approveStatusCurrent: 'Huidige goedkeuringsstatus',
    accepted: 'Geaccepteerd',
    addressInf: 'Adres informatie',
    streetAddress: 'Straat',
    amountOfBoxes: 'Aantal dozen',
    cancel: 'Annuleer Zending',
    cancelled: 'Geannuleerd',
    city: 'Stad',
    comments: 'Opmerkingen',
    company: 'Bedrijf',
    country: 'Herkomst',
    customerData: 'Klant gegevens',
    createdAt: 'Gemaakt bij',
    date: 'Datum',
    details: 'Details',
    defect: 'Defect',
    description: 'Description',
    edit: 'Wijzig',
    editEmail: 'Wijzig & Sturen',
    editShipment: 'Wijzig de zending',
    email: 'Email',
    endTimeSlot: 'Einde tijdvak',
    export: 'Exporteren',
    exportIncidents: 'Incidenten',
    exportCSV: 'Exporteren naar CSV',
    finished:
      'Oh nee, we hebben geen zending gevonden. Probeer een andere zoekterm graag! 🤯',
    freeShipment: 'Gratis zending',
    image: 'Geüpload beeld',
    hasComments:
      'De klant heeft een koerier instructies toegevoegd aan de zending.',
    hasNotes: 'Deze zending bevat een notitie.',
    history: 'Historie',
    houseNum: 'Huisnummer',
    houseAddition: 'Huisnummer toevoeging',
    hub: 'HUB',
    inProgress:
      'Een momentje, we laden de zendingen in waar jij naar op zoek bent...',
    labelUrl: 'Label URL',
    label: 'Label',
    locale: 'Taal',
    name: 'Naam',
    network: 'Vervoerder',
    noMore: 'Er zijn geen zendingen meer die we kunnen laten zien',
    orderInf: 'Order informatie',
    orderNum: 'Ordernr.',
    orderNumber: 'Order nummer',
    paid: 'Betaald?',
    parent: 'Bovenliggende zending',
    childLabels: 'Kinderlabels',
    paymentStatus: 'Betalingsstatus',
    pickUpAddress: 'Ophaaladres',
    pickedUp: 'Opgehaald',
    pickupDate: 'Afhaal moment',
    pickupDateDetails: 'Ophaalmoment',
    phone: 'Telefoonnummer',
    postCode: 'Post code',
    returnReason: 'Retour reden',
    returned: 'Retour aangemaakt',
    shipment: 'Zending',
    shipmentDetails: 'Zending gegevens',
    shop: 'Winkel',
    startTimeSlot: 'Start tijdvak',
    timeslot: 'Tijdslot',
    timeslotsFor: 'Tijdslots voor {{postalCode}}',
    status: 'Status',
    street: 'Straat',
    trackingCode: 'Trackingcode',
    trackShipment: 'Volg zending',
    openFile: 'Link naar het bestand',
    creationDate: 'Aanmaakdatum',
    insured: 'Verzekerd',
    awaitingCustomerAction: 'Wachten op klant',
    paymentsStatus: 'Betalingsstatus',
    location: 'Bestemming',
    generateShipment: 'Zending aanmaken',
    generateLabel: 'Label aanmaken',
    allShipments: 'Alle zendingen',
    refreshShipments: 'Ververs zendingen',
    open: 'Open',
    file: 'Bestand',
    returnType: 'Retourtype',
    returnTypes: {
      dropoff: 'Drop-off',
      pickup: 'Pickup'
    },
    houseNumber: 'Huisnummer',
    houseNumberSuffix: 'Huisnummer achtervoegsel',
    dropoff: 'Dropoff',
    pickup: 'Pickup',
    wantExchangeTo: 'Wil ruilen',
    customReturnReason: "Andere reden van retour",
    wasChanged: "Zending werd gewijzigd"
  },
  sidebar: {
    overview: 'Overzicht',
    statistics: 'Statistieken',
    shipments: 'Zendingen',
    team: 'Team',
    generateShipment: 'Zending aanmaken',
    profile: 'Profiel',
    investigations: 'Onderzoeken'
  },
  stats: {
    details: 'Details',
    name: 'Naam',
    others: 'Anderen',
    retourRedenen: 'Retour redenen',
    shipmentsMonth: 'Zendingen maand',
    shipmentsPerNetwerk: 'Zendingen per netwerk',
    shipmentsTotal: 'Totaal zendingen',
    shipmentsLocal: 'Lokale zendingen',
    shipmentsForeign: 'Buitenlandse zendingen',
    dayWithCount: '{{count}} dag',
    dayWithCount_plural: '{{count}} dagen',
    total: 'Totaal',
    totalShipments: '{{network}} zendingen',
    totalDropoff: '{{network}} drop-offs',
    totalPickup: '{{network}} pick-ups',
    performancePerMerchant: 'Prestaties per handelaar',
    performancePerCountryPerMerchant: 'Prestaties per land per handelaar',
    shopName: 'Shop naam',
    filter: 'Selecteer datumbereik',
    load: 'Laden',
    insurances: 'Verzekeringen',
    country: 'Land',
    amount: 'Bedrag',
    monthly: 'Maandelijks',
    numberOfPackages: 'Aantal pakketten',
    returnReasons: 'Reden van retour',
    allTime: 'Voor altijd',
    byPricing: {
      title: 'Op prijs',
      totalFree: 'Vrij',
      totalPaid: 'Betaald'
    },
    totalFree: 'Gratis',
    byReturnType: {
      title: 'Per omgaande type',
      totalDropoffs: 'Afleveren',
      totalPickups: 'Afhalingen'
    },
    countryCounters: {
      title: 'Per land'
    },
    byCreator: {
      title: 'By maker',
      byAdmins: 'Door admins',
      byShopAdmins: 'Door shop admins'
    },
  },
  status: {
    cancelled: 'Cancelled',
    new: 'Aangemeld',
    delivered: 'Geleverd bij het DC',
    completed: 'Voltooid',
    unfulfilled: 'De zending kon niet worden opgehaald',
    incident: 'Incident',
    transit: 'Transit',
    in_depot: 'Wordt gesorteerd',
    collected: 'Het pakket is opgehaald',
    scheduled: 'Gepland',
    out_delivery: 'Onderweg naar het DC',
    requested: 'Aangevraagd'
  },
  statusApprove: {
    declined: 'Afgewezen',
    approved: 'Goedgekeurd',
    pending: 'Pending',
    awaiting_on_customer: 'In afwachting van de klant'
  },
  team: {
    accountTitle: 'Mijn account',
    detail: 'Account gegevens',
    editAccount: 'Wijzig',
    email: 'E-mail',
    firstName: 'Voornaam',
    invite: 'Uitnodigen',
    inviteTitle: 'Nieuwe teamgenoot uitnodigen',
    inviteNewTeamMember: 'Nodig een team member uit',
    lastName: 'Achternaam',
    name: 'Naam',
    password: 'Wachtwoord',
    title: 'Klantenservice team',
    memberInvited:
      'Hooray! {{name}} is uitgenodigd om deel te worden van jouw team!',
    backToTeam: 'Terug naar team',
    delete: 'Lid verwijderen',
    notDelete: 'Laat het',
    areYouSure: 'Weet je zeker dat je een lid wilt verwijderen'
  },
  roles: {
    shop_admin: 'Shop Admin',
    app: 'App',
    admin: 'Admin',
    customer: 'Klant'
  }
};

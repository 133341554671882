import React from 'react';
import { isEmpty } from 'lodash';
import numeral from 'numeral'
import '../MonthlyCounters/index.scss';

export default function BilledCounters({ dataObj = {}, translationPrefix, t }) {
  if (isEmpty(dataObj)) return false;

  return (
    <div className="monthly-counters">
      {Object.keys(dataObj)
        .map((name, index) => (
          <div className="monthly-counter" key={`monthly-${index}`}>
            <div>{t(`${translationPrefix}.${name}`)}</div>
            <div>{numeral(dataObj[name]).format('0,0')}</div>
          </div>
        ))
        .reduce((result, item) => [
          result,
          <hr className="monthly-counter__line" />,
          item
        ])}
    </div>
  );
}

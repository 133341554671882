import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldInput from 'components/Field';
import { get } from 'lodash';

function Email(props = {}) {
  const { disabled, errors, validate = [], change, t } = props;

  return (
    <Field
      name="email"
      className="other-class"
      disabled={disabled}
      errors={get(errors, 'email')}
      label={t('shipments.email')}
      component={FieldInput}
      validate={validate}
      change={change}
    />
  );
}
export default withTranslation()(Email);

import { post } from '../rootApi';

// INVESTIGATIONS
export function getInvestigationsRequest(scope, perPage, pageNum) {
  const paramsLine = `scope: "${scope}", page: ${pageNum}, perPage: ${perPage}`;
  const GET_INVESTIGATIONS = `
  {
    investigations(${paramsLine}) {
      totalPages,
      page,
      data {
        id
        shipmentId
        status
        orderNumber
        email
        country
        resolved
        createdAt
        shopName
      }
    }
  }
 `;

  return post({ query: GET_INVESTIGATIONS });
}

// TOGGLE INVESTIGATION STATUS
export function toggleInvestigationStatusRequest(id, resolved) {
  return post({
    query: `mutation toggleInvestigationStatus($id: String!, $resolved: Boolean!) {
      toggleInvestigationStatus(id: $id, resolved: $resolved)
    }`,
    variables: { id, resolved }
  });
}

// START INVESTIGATION
export function startInvestigationRequest(shipmentId, message) {
  return post({
    query: `mutation startInvestigation($id: String!, $message: String) {
      startInvestigation(id:$id, message:$message)
    }`,
    variables: { id: shipmentId, message }
  });
}

import { put } from 'redux-saga/effects';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { handleAPIError } from 'utils/sagaUtils';
import {
  getTeamMembersRequest,
  inviteTeamMemberRequest,
  deleteTeamMemberRequest
} from './api';
import mainActions from '../main/actions';
import teamActions from './actions';

export function* getTeam() {
  yield put(mainActions.startLoading('getTeam'));
  try {
    const response = yield getTeamMembersRequest();
    const data = get(response, 'data.data.team');
    yield put(teamActions.getTeamOk(data));
  } catch (error) {
    yield put(teamActions.getTeamFail(error));
    handleAPIError(error, 'getTeam');
  } finally {
    yield put(mainActions.stopLoading('getTeam'));
  }
}

export function* inviteMember(action) {
  yield put(mainActions.startLoading('inviteMember'));

  try {
    const { firstName, lastName, email } = action;
    yield inviteTeamMemberRequest(firstName, lastName, email);
    yield put(teamActions.inviteMemberOk());
    yield put(mainActions.clearFeatureError('inviteMember'));

    yield put(
      push({
        pathname: '/team/memberInvited',
        state: { name: `${firstName} ${lastName}` }
      })
    );
  } catch (error) {
    yield put(teamActions.inviteMemberFail(error));
    handleAPIError(error, 'inviteMember');
  } finally {
    yield put(mainActions.stopLoading('inviteMember'));
  }
}
export function* deleteMember(action) {
  yield put(mainActions.startLoading('deleteMember'));

  try {
    const { email } = action;
    yield deleteTeamMemberRequest(email);
    yield put(teamActions.deleteMemberOk());
    yield put(teamActions.getTeam());
  } catch (error) {
    yield put(teamActions.deleteMemberFail(error));
    handleAPIError(error, 'deleteMember');
  } finally {
    yield put(mainActions.stopLoading('deleteMember'));
  }
}

import moment from 'moment';
import { find } from 'lodash'

export const getReadableTime = (start, stop) => {
  const startMoment = moment(start).format('DD MMM YYYY HH:mm');
  const stopHour = moment(stop).format('HH:mm');
  return [startMoment, ' - ', stopHour].join('');
};

export const formatTimeslot = ts => {
  const text = getReadableTime(ts.start, ts.stop);
  return { text, value: ts.id };
};

export const formatTimeslots = timeslots =>
  timeslots.map(ts => formatTimeslot(ts));

export const addTimeslot = (timeslots, timeslotRaw) => {
  const timeslot = formatTimeslot(timeslotRaw);
  const ts = find(timeslots, { value: timeslot.value })
  return ts ? timeslots : [timeslot, ...timeslots]
}
import React from 'react';
import Modal from 'components/Modal';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './index.scss';

export default function ModalImage({ visible, onClose, imageUrl }) {
  return (
    <Modal
      visible={visible}
      withCloseButton
      onClose={onClose}
      className="modal--small"
      style={{
        padding: '2.5rem 4rem',
        height: '95vh',
        width: '90vw'
      }}
    >
      <TransformWrapper wheel={{ step: 2 }}>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className="modal-image-tools">
              <button onClick={zoomIn} className="button modal-image__control">
                +
              </button>
              <button onClick={zoomOut} className="button modal-image__control">
                -
              </button>
            </div>
            <TransformComponent>
              <img
                src={imageUrl}
                alt="Defect"
                style={{ maxHeight: '80vh', maxWidth: '80vw' }}
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </Modal>
  );
}

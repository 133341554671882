export const PER_PAGE = {
  team: 7,
  returns: 10,
  returnOrder: 4,
  disabledCustomers: 5,
  shipments: {
    forOverviewPage: 50,
    forShipmentsPage: 12,
    forSearchPage: 12,
    forGenerateShipmentPage: 10,
  },
  notes: {
    forOverviewPage: 7,
    forProfilePage: 4,
    forShipmentDetailsPage: 8
  },
  investigations: 12
};
export const roles = {
  isShopAdmin: 'shop_admin',
  isAdmin: 'admin',
  isApp: 'app'
};

export const baseAuthUrl = process.env.REACT_APP_BASE_AUTH_API;
export const adminApiUrl = process.env.REACT_APP_ADMIN_API;
export const graphQlBase = process.env.REACT_APP_BASE_API;
export const noStatisticsShopsIds =
  process.env.REACT_APP_NO_STATISTICS_SHOPS_IDS || [];

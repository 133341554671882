import React, { Component } from 'react';
import { dispatch } from 'store';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { isEqual, get, isEmpty } from 'lodash';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import SearchBar from 'components/SearchBar';
import ButtonSelect from 'components/ButtonSelect';
import statsActions from 'features/stats/actions';
import Chart from 'components/Chart';
import LoaderCSS from 'components/LoaderCSS';
import TableShipmentsPerCountry from 'components/TableShipmentsPerCountry';
import BilledCounters from './BilledCounters';
import ReturnTypeCounters from './ReturnTypeCounters';
import MonthlyCounters from './MonthlyCounters';
import LocalCounters from './LocalCounters';
import ForeignCounters from './ForeignCounters';
import PerCountryPerMerchant from './PerCountryPerMerchant';
import PerformancePerMerchant from './PerformancePerMerchant';
import DateRangeForm from './DateRangeForm';
import Counter from './Counter';
import ReturnReasonPie from './ReturnReasonPie';
import './index.scss';

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shop: null,
      from: null,
      to: null,
      user: {},
      dailyCounters: [],
      monthlyCounters: [],
      returnReasonCounters: [],
      totalCounters: [],
      billedCounters: {},
      loading: {
        getDailyCounters: false,
        getShopCounters: false,
        getTotalCounters: false
      }
    };
    this.reload = this.reload.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const {
      shop,
      dailyCounters,
      monthlyCounters,
      returnReasonCounters,
      totalCounters,
      billedCounters,
      shopCounters,
      loading,
      user
    } = nextProps;

    if (!isEqual(shop, prevState.shop)) {
      newState = { ...newState, shop };
    }

    if (!isEqual(loading, prevState.loading)) {
      newState = { ...newState, loading };
    }

    if (!isEqual(dailyCounters, prevState.dailyCounters)) {
      newState = { ...newState, dailyCounters };
    }

    if (!isEqual(returnReasonCounters, prevState.returnReasonCounters)) {
      newState = { ...newState, returnReasonCounters };
    }

    if (!isEqual(monthlyCounters, prevState.monthlyCounters)) {
      newState = { ...newState, monthlyCounters };
    }

    if (!isEqual(totalCounters, prevState.totalCounters)) {
      newState = { ...newState, totalCounters };
    }

    if (!isEqual(billedCounters, prevState.billedCounters)) {
      newState = { ...newState, billedCounters };
    }

    if (!isEqual(shopCounters, prevState.shopCounters)) {
      newState = { ...newState, shopCounters };
    }

    if (!isEqual(user, prevState.user)) {
      newState = { ...newState, user };
    }

    return newState;
  }

  componentDidMount() {
    const {
      user: { isNotAdmin }
    } = this.state;
    dispatch(statsActions.getDailyCounters(14));
    if (isNotAdmin) dispatch(statsActions.getShopCounters());
  }

  onDateChange(field, value) {
    this.setState({ [field]: value });
  }

  reload() {
    const {
      from,
      to,
      user: { isNotAdmin, isAdmin }
    } = this.state;
    if (isNotAdmin) dispatch(statsActions.getShopCounters(from, to));
    if (isAdmin) dispatch(statsActions.getTotalCounters(from, to));
  }

  render() {
    const {
      shop,
      from,
      to,
      billedCounters,
      dailyCounters,
      monthlyCounters,
      returnReasonCounters,
      totalCounters,
      shopCounters,
      loading
    } = this.state;
    const { t, history, user } = this.props;
    const isAdmin = get(user, 'isAdmin');
    const isNotAdmin = get(user, 'isNotAdmin');
    const returnReasons = get(shop, 'returnReasons', {});
    const { showReturnReasons, gatekeeperEnabled } = shop || {};
    const showPie = isNotAdmin && (showReturnReasons || gatekeeperEnabled);

    return (
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '100%',
          height: 'auto',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
        containerStyles={{
          height: 'auto !important',
          minHeight: '100% !important'
        }}
        main={
          <>
            <SearchBar t={t} history={history} />
            <div className="stats__top-widgets">
              <ContentBox
                className=""
                style={{ width: '75%' }}
                containerStyle={{
                  border: 'none',
                  borderRadius: '2.5rem',
                  height: '100%'
                }}
              >
                <ContentBox.Row
                  header={
                    <ContentBox.Row.Header text={t('home.monthOverview')}>
                      <LoaderCSS visible={loading.getDailyCounters} />
                      <ButtonSelect
                        options={[
                          {
                            text: t('stats.dayWithCount', { count: 14 }),
                            value: 14
                          },
                          {
                            text: t('stats.dayWithCount', { count: 30 }),
                            value: 30
                          },
                          {
                            text: t('stats.dayWithCount', { count: 365 }),
                            value: 365
                          }
                        ]}
                        onSelect={noDays =>
                          dispatch(statsActions.getDailyCounters(noDays))
                        }
                      />
                    </ContentBox.Row.Header>
                  }
                  content={<Chart data={dailyCounters} t={t} />}
                />
              </ContentBox>
              {isNotAdmin && (
                <ContentBox
                  className=""
                  style={{ minWidth: '25%' }}
                  containerStyle={{
                    height: '100%',
                    border: 'none',
                    borderRadius: '2.5rem'
                  }}
                >
                  <ContentBox.Row
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    header={
                      <ContentBox.Row.Header
                        className="monthly-counters__header"
                        text={t('stats.numberOfPackages')}
                      >
                        <span>{t('stats.monthly')}</span>
                        <LoaderCSS visible={loading.getShopCounters} wide />
                      </ContentBox.Row.Header>
                    }
                    content={<MonthlyCounters data={monthlyCounters} t={t} />}
                  />
                </ContentBox>
              )}
            </div>
            {isNotAdmin && (
              <div className="stats__middle-widgets">
                <div
                  className="stats__middle-widgets--column"
                  style={{ minWidth: '55%' }}
                >
                  <ContentBox
                    containerStyle={{
                      paddingTop: '2.5rem',
                      border: 'none',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header text={t('stats.filter')} />
                      }
                      content={
                        <DateRangeForm
                          from={from}
                          to={to}
                          t={t}
                          onSubmit={this.reload}
                          onDateChange={this.onDateChange}
                          loading={loading.getTotalCounters}
                        />
                      }
                    />
                  </ContentBox>
                  <div className="stats__middle-widgets">
                    {showPie && (
                      <ContentBox
                        className=""
                        style={{ minWidth: '20%' }}
                        containerStyle={{
                          height: '100%',
                          border: 'none',
                          borderRadius: '2.5rem'
                        }}
                      >
                        <ContentBox.Row
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                          header={
                            <ContentBox.Row.Header
                              className="monthly-counters__header"
                              text={t('stats.returnReasons')}
                            >
                              <LoaderCSS
                                visible={loading.getShopCounters}
                                wide
                              />
                            </ContentBox.Row.Header>
                          }
                          content={
                            <ReturnReasonPie
                              backendData={returnReasonCounters}
                              returnReasons={returnReasons}
                              t={t}
                            />
                          }
                        />
                      </ContentBox>
                    )}
                    <ContentBox
                      className=""
                      style={{ flexGrow: 1 }}
                      containerStyle={{
                        height: '100%',
                        minWidth: '20rem',
                        border: 'none',
                        borderRadius: '2.5rem'
                      }}
                    >
                      <ContentBox.Row
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        header={
                          <ContentBox.Row.Header
                            className="monthly-counters__header"
                            text={t('stats.byPricing.title')}
                          >
                            <LoaderCSS visible={loading.getShopCounters} wide />
                          </ContentBox.Row.Header>
                        }
                        content={
                          <BilledCounters
                            dataObj={get(
                              shopCounters,
                              'totalCounters.byPricing'
                            )}
                            translationPrefix="stats.byPricing"
                            t={t}
                          />
                        }
                      />
                    </ContentBox>
                    <ContentBox
                      className=""
                      style={{ flexGrow: 1 }}
                      containerStyle={{
                        height: '100%',
                        minWidth: '20rem',
                        border: 'none',
                        borderRadius: '2.5rem'
                      }}
                    >
                      <ContentBox.Row
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        header={
                          <ContentBox.Row.Header
                            className="monthly-counters__header"
                            text={t('stats.byReturnType.title')}
                          >
                            <LoaderCSS visible={loading.getShopCounters} wide />
                          </ContentBox.Row.Header>
                        }
                        content={
                          <ReturnTypeCounters
                            dataObj={get(
                              shopCounters,
                              'totalCounters.byReturnType'
                            )}
                            t={t}
                          />
                        }
                      />
                    </ContentBox>
                  </div>
                  <div className="stats__middle-widgets">
                    <ContentBox
                      className=""
                      style={{ width: 'calc(50% - 1.5rem)' }}
                      containerStyle={{
                        height: '100%',
                        minWidth: '20rem',
                        border: 'none',
                        borderRadius: '2.5rem'
                      }}
                    >
                      <ContentBox.Row
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        header={
                          <ContentBox.Row.Header
                            className="monthly-counters__header"
                            text={t('stats.byCreator.title')}
                          >
                            <LoaderCSS visible={loading.getShopCounters} wide />
                          </ContentBox.Row.Header>
                        }
                        content={
                          <BilledCounters
                            dataObj={get(
                              shopCounters,
                              'totalCounters.byCreator'
                            )}
                            translationPrefix="stats.byCreator"
                            t={t}
                          />
                        }
                      />
                    </ContentBox>
                  </div>
                </div>

                {get(shopCounters, 'totalCounters.byReturnType') && (
                  <ContentBox
                    className=""
                    style={{ flexGrow: '1' }}
                    containerStyle={{
                      height: '100%',
                      minWidth: '20rem',
                      border: 'none',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      style={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      header={
                        <ContentBox.Row.Header
                          className="monthly-counters__header"
                          text={t('stats.countryCounters.title')}
                        >
                          <LoaderCSS visible={loading.getShopCounters} wide />
                        </ContentBox.Row.Header>
                      }
                      content={
                        <TableShipmentsPerCountry
                          rows={get(shopCounters, 'countryCounters')}
                          t={t}
                          filterZeroes
                        />
                      }
                    />
                  </ContentBox>
                )}
              </div>
            )}
            {isAdmin && (
              <>
                <ContentBox
                  className=""
                  style={{ width: '100%' }}
                  containerStyle={{
                    paddingTop: '2.5rem',
                    border: 'none',
                    borderRadius: '2.5rem',
                    minHeight: '30rem'
                  }}
                >
                  <ContentBox.Row
                    header={<ContentBox.Row.Header text={t('stats.filter')} />}
                    content={
                      <>
                        <DateRangeForm
                          from={from}
                          to={to}
                          t={t}
                          onSubmit={this.reload}
                          onDateChange={this.onDateChange}
                          loading={loading.getTotalCounters}
                        />
                        {!isEmpty(totalCounters) && (
                          <>
                            <div className="stats__local-and-foreign">
                              <div style={{ width: '50%' }}>
                                <ContentBox
                                  className=""
                                  style={{ width: '100%' }}
                                  containerStyle={{
                                    border: 'none',
                                    borderRadius: '2.5rem'
                                  }}
                                >
                                  <ContentBox.Row
                                    header={
                                      <ContentBox.Row.Header
                                        text={t('stats.shipmentsLocal')}
                                      />
                                    }
                                    content={
                                      <LocalCounters
                                        counters={get(
                                          totalCounters,
                                          'localShipmentCounters'
                                        )}
                                        t={t}
                                      />
                                    }
                                  />
                                </ContentBox>
                                <div className="stats__horizontal-flex">
                                  <ContentBox
                                    className=""
                                    containerStyle={{
                                      border: 'none',
                                      borderRadius: '2.5rem'
                                    }}
                                  >
                                    <ContentBox.Row
                                      header={
                                        <ContentBox.Row.Header
                                          text={t('stats.insurances')}
                                        />
                                      }
                                      content={
                                        <Counter
                                          title={t('stats.insurances')}
                                          style={{ width: '10rem' }}
                                          value={get(
                                            totalCounters,
                                            'insurances'
                                          )}
                                        />
                                      }
                                    />
                                  </ContentBox>
                                  <ContentBox
                                    style={{ width: '100%' }}
                                    containerStyle={{
                                      border: 'none',
                                      borderRadius: '2.5rem'
                                    }}
                                  >
                                    <ContentBox.Row
                                      header={
                                        <ContentBox.Row.Header
                                          text={t('stats.byPricing.title')}
                                        />
                                      }
                                      content={
                                        <div className="stats__horizontal-flex">
                                          <Counter
                                            title={t(
                                              'stats.byPricing.totalPaid'
                                            )}
                                            style={{ width: '10rem' }}
                                            value={get(billedCounters, 'paid')}
                                          />
                                          <Counter
                                            title={t(
                                              'stats.byPricing.totalFree'
                                            )}
                                            style={{ width: '10rem' }}
                                            value={get(billedCounters, 'free')}
                                          />
                                        </div>
                                      }
                                    />
                                  </ContentBox>
                                </div>
                              </div>
                              <ContentBox
                                className=""
                                style={{ width: '50%' }}
                                containerStyle={{
                                  border: 'none',
                                  borderRadius: '2.5rem'
                                }}
                              >
                                <ContentBox.Row
                                  header={
                                    <ContentBox.Row.Header
                                      text={t('stats.shipmentsForeign')}
                                    />
                                  }
                                  content={
                                    <ForeignCounters
                                      counters={get(
                                        totalCounters,
                                        'foreignShipmentCounters.counters'
                                      )}
                                      rows={get(
                                        totalCounters,
                                        'foreignShipmentCounters.table'
                                      )}
                                      t={t}
                                    />
                                  }
                                />
                              </ContentBox>
                            </div>
                            <ContentBox
                              className=""
                              containerStyle={{
                                border: 'none',
                                borderRadius: '2.5rem'
                              }}
                            >
                              <ContentBox.Row
                                header={
                                  <ContentBox.Row.Header
                                    text={t('stats.performancePerMerchant')}
                                  />
                                }
                                content={
                                  <PerformancePerMerchant
                                    data={get(
                                      totalCounters,
                                      'merchantCounters'
                                    )}
                                    t={t}
                                  />
                                }
                              />
                            </ContentBox>
                          </>
                        )}
                      </>
                    }
                  />
                </ContentBox>
                {!isEmpty(totalCounters) && (
                  <ContentBox
                    className=""
                    containerStyle={{
                      border: 'none',
                      borderRadius: '2.5rem'
                    }}
                  >
                    <ContentBox.Row
                      header={
                        <ContentBox.Row.Header
                          text={t('stats.performancePerCountryPerMerchant')}
                        />
                      }
                      content={
                        <PerCountryPerMerchant
                          data={get(totalCounters, 'perCountryPerMerchant')}
                          t={t}
                        />
                      }
                    />
                  </ContentBox>
                )}
              </>
            )}
          </>
        }
      />
    );
  }
}

const mapStateToProps = ({
  shop: { shop },
  stats: {
    totalCounters,
    monthlyCounters,
    dailyCounters,
    returnReasonCounters,
    billedCounters,
    shopCounters
  },
  auth: { user },
  main: {
    loadingObj: { getTotalCounters, getDailyCounters, getShopCounters }
  }
}) => ({
  shop,
  user,
  loading: {
    getTotalCounters,
    getDailyCounters,
    getShopCounters
  },
  returnReasonCounters,
  totalCounters,
  monthlyCounters,
  dailyCounters,
  billedCounters,
  shopCounters
});
export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Stats);

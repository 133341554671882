import React from 'react';
import ReactSVG from 'react-svg';
import closeIcon from 'assets/images/close.svg';
import './index.scss';

export default ({
  visible,
  onClose = () => {},
  className = '',
  children,
  headerText,
  withCloseButton,
  style
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={`${className} modal`}>
      <div
        className="modal__content"
        style={style}
      >
        {headerText && <div className="modal__header">{headerText}</div>}
        {withCloseButton && (
          <ReactSVG
            onClick={onClose}
            src={closeIcon}
            className="modal__close-button"
          />
        )}
        {children}
      </div>
    </div>
  );
};

import { put } from 'redux-saga/effects';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { PER_PAGE } from 'config.js';
import { handleAPIError } from 'utils/sagaUtils';
import {
  getInvestigationsRequest,
  toggleInvestigationStatusRequest,
  startInvestigationRequest
} from './api';
import mainActions from '../main/actions';
import investigationActions from './actions';
import shipmentActions from '../shipment/actions';

export function* getInvestigations(action) {
  yield put(mainActions.startLoading('getInvestigations'));
  const { scope, page } = action;

  try {
    const response = yield getInvestigationsRequest(
      scope,
      PER_PAGE.investigations,
      page
    );
    const data = get(response, 'data.data.investigations');
    yield put(investigationActions.getInvestigationsOk(scope, data));
  } catch (error) {
    yield put(investigationActions.getInvestigationsFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getInvestigations'));
  }
}

export function* toggleInvestigationStatus(action) {
  yield put(mainActions.startLoading('toggleInvestigationStatus'));
  const { investigationId, resolved, options } = action;
  const { scope, page, shipmentId } = options;
  try {
    yield toggleInvestigationStatusRequest(investigationId, resolved);
    yield put(investigationActions.toggleInvestigationStatusOk());
    if (page && scope)
      yield put(investigationActions.getInvestigations(page, scope));
    if (shipmentId) yield put(shipmentActions.getShipment(shipmentId));
  } catch (error) {
    handleAPIError(error, 'toggleInvestigationStatus');
  } finally {
    yield put(mainActions.stopLoading('toggleInvestigationStatus'));
  }
}

export function* startInvestigation(action) {
  yield put(mainActions.startLoading('startInvestigation'));
  const { shipmentId, message } = action;

  try {
    yield startInvestigationRequest(shipmentId, message);
    yield put(investigationActions.startInvestigationOk());
    yield put(push(`/shipments/${shipmentId}/investigationStarted`));
  } catch (error) {
    yield put(investigationActions.startInvestigationFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('startInvestigation'));
  }
}

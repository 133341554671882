import styled from 'styled-components';
import ModalOriginal from 'components/Modal2';

export const Modal = styled(ModalOriginal)`
  padding-top: 1.5rem;
  min-width: 30rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #000033;

  align-items: left;
  flex-direction: column;
  margin-bottom: 1.5625rem;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8125rem;
  width: 100%;
  background: #cbaaff;
  border-radius: 0.25rem;

  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4500b5;
  margin-top: 3.375rem;

  &:hover {
    background: #cbaaff;
  }
`;

import React from 'react';
import { Field as ReduxFormField, reduxForm } from 'redux-form';

import { required } from 'utils/validations';
import FieldTextArea from 'components/FieldTextArea';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { dispatch } from 'store';
import noteActions from 'features/note/actions';

const validate = values => {
  const errors = {};
  errors.body = required(values.body);
  return errors;
};

const Form = ({ handleSubmit, onSubmit, errors, t }) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <ReduxFormField
      className=""
      component={FieldTextArea}
      name="body"
      placeholder={t('form.addNote.body.label')}
      inputStyle={{ height: '12.5rem' }}
      errors={errors ? errors.firstName : null}
    />
    <Button
      text={t('form.addNote.buttonText')}
      type="submit"
      className="button--primary width--100"
    />
  </form>
);

const FormWithRedux = reduxForm({
  form: 'addNote',
  validate
})(Form);

class AddNoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit({ body }) {
    const { shipmentId } = this.props;
    dispatch(noteActions.addNote(body, shipmentId));
  }

  render() {
    const { t, onClose } = this.props;

    return (
      <Modal
        visible
        className="modal--small"
        onClose={onClose}
        headerText="Notitie"
        withCloseButton
        style={{
          padding: '2.875rem 4.125rem'
        }}
      >
        <FormWithRedux onSubmit={this.handleSubmit} t={t} />
      </Modal>
    );
  }
}

export default AddNoteModal;

import React from 'react';
import ReactSVG from 'react-svg';
import shipments from 'assets/images/shipments.svg';
import newNote from 'assets/images/newNote.svg';
import envelope from 'assets/images/envelope.svg';
import magnifier from 'assets/images/search.svg';
import edit from 'assets/images/edit.svg';
import team from 'assets/images/team.svg';
import tickSimple from 'assets/images/tickSimple.svg';
import crossSimple from 'assets/images/crossSimple.svg';
import duplicate from 'assets/images/duplicate.svg';
import './index.scss';

const icons = {
  shipments: {
    src: shipments,
    classNames: 'shipments-icon--light'
  },
  newNote: {
    src: newNote,
    classNames: 'new-note-icon'
  },
  magnifier: {
    src: magnifier,
    classNames: 'magnifier-icon'
  },
  edit: {
    src: edit,
    classNames: 'edit-icon'
  },
  addTeamMember: {
    src: envelope,
    classNames: 'add-member-icon'
  },
  team: {
    src: team,
    classNames: 'membed-invited-icon'
  },
  tickSimple: {
    src: tickSimple,
    classNames: 'membed-invited-icon'
  },
  crossSimple: {
    src: crossSimple,
    classNames: 'membed-invited-icon'
  },
  duplicate: {
    src: duplicate,
    classNames: 'generate-icon'
  }
};

const generateIcon = iconName => {
  const icon = icons[iconName];
  if (!icon) return null;

  return (
    <ReactSVG src={icon.src} className={`button-icon ${icon.classNames}`} />
  );
};

export default ({
  iconName,
  children,
  text,
  disabled,
  style,
  textStyle,
  className,
  onClick,
  type
}) => {
  let buttonClasses = `button ${disabled ? 'button--disabled' : ''}`;

  if (className) buttonClasses += ` ${className}`;

  return (
    <button
      className={buttonClasses}
      style={style}
      disabled={disabled}
      onClick={disabled ? () => {} : onClick}
      type={type || 'button'}
    >
      {text || iconName ? (
        <>
          {generateIcon(iconName)}
          <span className="button__text" style={textStyle}>
            {text}
          </span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const NAVIGATION = {
  EXPAND: 'NAVIGATION_EXPAND',
  COLLAPSE: 'NAVIGATION_COLLAPSE',
  TOGGLE: 'NAVIGATION_TOGGLE'
};

export default {
  expand: () => ({
    type: NAVIGATION.EXPAND
  }),
  collapse: () => ({
    type: NAVIGATION.COLLAPSE
  }),
  toggle: () => ({
    type: NAVIGATION.TOGGLE
  })
};

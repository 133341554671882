import React from 'react';
import Table, { ALIGNMENT } from 'components/Table';
import 'components/TableShipments/index.scss';
import './index.scss';

// In this component we utilize the styles from the TableShipments component
// because there it's not any difference
const TablePerCountryPerMerchant = ({ data, t }) => {
  const columns = header =>
    header.map((code, index) => ({
      id: code,
      title: index > 0 ? t(`countries.${code}`) : t(`stats.${code}`),
      accessor: d => d[index],
      alignment: index > 0 ? ALIGNMENT.CENTER : ALIGNMENT.LEFT
    }));

  return (
    <div className="table-shipments__container">
      <div className="shipments-table__table-container">
        <Table
          withBorder={false}
          data={data.body}
          columns={columns(data.header)}
          t={t}
        />
      </div>
    </div>
  );
};

export default TablePerCountryPerMerchant;

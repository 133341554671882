/* eslint-disable no-return-assign */
import { isNil, get } from 'lodash';

export const isBlank = value => value === '' || isNil(value);

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getDefault = (object, path = '', defaultValue) => {
  const value = get(object, path);
  return isBlank(value) ? defaultValue : value;
};

export const pickDefault = (object, fields = [], defaultValue) => {
  const result = {};
  fields.forEach(
    field => (result[field] = getDefault(object, field, defaultValue))
  );
  return result;
};

export function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.text.toUpperCase();
  const bandB = b.text.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export function truncate(str, num) {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

import React from 'react';
import './index.scss';

function Counter({ title, value, style = {} }) {
  return (
    <div className="stats-counter" style={style}>
      <div className="stats-counter__title">{title}</div>
      <div className="stats-counter__value">{value}</div>
    </div>
  );
}

export default Counter;

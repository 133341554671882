/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import IconSuare from 'components/IconSquare';
import AuthLayout from 'components/AuthLayout';
import RequestResetPassword from 'components/RequestResetPassword';
import { dispatch } from 'store';
import profileActions from 'features/profile/actions';

class RequestResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      email: props.match.params.email || ''
    };

    this.handleReset = this.handleReset.bind(this);
    this.handleRemoveError = this.handleRemoveError.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleReset({ email }) {
    dispatch(profileActions.sendResetPasswordUrl(email));
    this.setState({
      submitted: true
    });
  }

  handleRemoveError() {
    dispatch(profileActions.removeError());
  }

  handleLoginClick() {
    this.props.history.push('/login');
  }

  render() {
    const { error, t } = this.props;
    const { submitted, email } = this.state;

    if (submitted) {
      return (
        <AuthLayout modalClass="auth-container__request-reset_password--submitted">
          <div>
            <div className="auth-container__header__icon-container">
              <IconSuare icon="envelope" />
            </div>
            <span className="auth-container__header">
              {t('pages.requestResetPassword.headerAfter')}
            </span>
            <div className="auth-container__switch" style={{marginTop: '2.5rem'}}>
              <Link to="/">{t('pages.login.back')}</Link>
            </div>
          </div>
        </AuthLayout>
      );
    }

    return (
      <AuthLayout
        switchPath="/"
        switchContent={t('pages.login.back')}
        modalClass="auth-container__request-reset_password"
      >
        <RequestResetPassword
          errors={error}
          onSubmit={this.handleReset}
          onRemoveError={this.handleRemoveError}
          t={t}
          email={email}
        />
      </AuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  error: state.profile.error
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(RequestResetPasswordContainer);

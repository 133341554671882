import { isEmpty } from 'lodash';
import { post } from '../rootApi';

export function getNotesRequest({ perPage, page, sorting, userId }) {
  let paramsLine = `page: ${page}, perPage: ${perPage}`;
  if (sorting !== null && !isEmpty(sorting))
    paramsLine = paramsLine.concat(
      `, sortKey: ${sorting.sortKey.toUpperCase()}, sortCriteria: ${
        sorting.sortCriteria
      }`
    );
  if (userId) paramsLine = paramsLine.concat(`, userId: ${userId}`);

  const GET_NOTES = `
    {
      notes(${paramsLine}) {
      id
      createdAt
      message
      shipment {
        id
        orderNumber
      }
      author {
        fullName
        role
      }
    }
  }
`;
  return post({ query: GET_NOTES });
}

export function createNoteRequest(message, shipmentId) {
  const query = `
    mutation createNote($message:String!, $shipmentId :String!){
      createNote(message: $message, shipmentId: $shipmentId) {
        id
        createdAt
        message
        shipment {
          id
          orderNumber
        }
    }
  }
`;

  const variables = { message, shipmentId };
  return post({ query, variables });
}
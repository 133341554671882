import { SHOP } from './actions';

export const INITIAL_STATE = {
  shop: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { data } = action;
  switch (action.type) {
    case SHOP.GET_OK:
      return {
        ...state,
        shop: data
      };
    default:
      return state;
  }
};

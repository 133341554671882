import authActions from 'features/auth/actions';
import mainActions from 'features/main/actions';
import { get } from 'lodash';
import { dispatch } from 'store';

// eslint-disable-next-line import/prefer-default-export
export const handleAPIError = (errorData, featureName) => {
  const errors = get(errorData, 'response.data.errors', []);
  // const validationError = get(error, 'response.data.error');
  const errMsg = get(errors[0], 'message');
  const status = get(errorData, 'response.status');

  if (status === 422 && featureName) {
    // save error message for user notification
    dispatch(mainActions.setFeatureError(featureName, errMsg));
    return errMsg;
  }

  if (status === 500) {
    dispatch(mainActions.setServerError(errMsg));
  }
  if ([401, 403].includes(status)) {
    dispatch(authActions.logout());
  }
};

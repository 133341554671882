import React from 'react';
import { get } from 'lodash';
import './index.scss';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: ''
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const newValue = get(nextProps, 'input.value');
    if (newValue !== prevState.value) {
      newState = { ...newState, value: newValue };
    }
    return newState;
  }

  handleBlur(e) {
    const { input, onCustomBlur } = this.props;
    if (input && input.onBlur) {
      input.onBlur(e);
    }

    if (onCustomBlur) {
      onCustomBlur(e.target);
    }

    this.setState({
      focused: false
    });
  }

  handleChange(event) {
    const { onChange } = this.props;
    const { target } = event;
    const { value } = target;

    this.setState({
      value
    });

    if (onChange) {
      onChange(event);
    }
  }

  handleFocus(e) {
    const { input, onCustomFocus } = this.props;
    if (input && input.onFocus) {
      input.onFocus(e);
    }

    if (onCustomFocus) {
      onCustomFocus(e.target);
    }

    this.setState({
      focused: true
    });
  }

  render() {
    const {
      children,
      onChange,
      onCustomFocus,
      onCustomBlur,
      onFocus,
      onBlur,
      className = '',
      labelClassName = '',
      type = 'text',
      label,
      style,
      text,
      disabled,
      labelStyle,
      input,
      meta,
      inputStyle,
      ...rest
    } = this.props;

    let { errors } = this.props;
    errors = [
      ...(errors || []),
      ...(meta && meta.error && meta.touched ? [meta.error] : [])
    ];
    const disabledClassName = disabled ? 'input-box__input--disabled' : '';
    const { focused, value } = this.state;

    return (
      <div
        className={`input-box ${
          errors.length > 0 ? 'input-box--with-errors' : ''
        } ${disabled ? 'input-box--disabled' : ''} ${
          get(input, 'value') ? 'input-box--has-value' : ''
        } ${focused ? 'input-box--has-focus' : ''}`}
        style={style}
      >
        {label ? (
          <span
            className={`input-box__label ${labelClassName}`}
            style={labelStyle}
          >
            {label}
          </span>
        ) : (
          children
        )}
        <textarea
          disabled={disabled}
          type={type}
          onChange={this.handleChange}
          {...input}
          className={`input-box__input ${className} ${disabledClassName}`}
          style={inputStyle}
          {...rest}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={value}
        />
        {errors.map((error, i) => (
          <div key={i} className="input-box__error">
            {error}
          </div>
        ))}
      </div>
    );
  }
}

import React from 'react';
import cn from 'classnames';
import solidArrows from 'assets/images/arrowsSelection.svg';
import filterArrows from 'assets/images/arrowsFilter.svg';
import ReactSVG from 'react-svg';
import './index.scss';

export default ({ direction, solid }) => {
  if (solid)
    return (
      <div className="icon-caret__container--solid">
        <ReactSVG src={solidArrows} />
      </div>
    );

  return (
    <div className="icon-caret__container">
      <ReactSVG
        src={filterArrows}
        className={cn({ up: direction === 'ASC', down: direction === 'DESC' })}
      />
    </div>
  );
};

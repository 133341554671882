import React from 'react';
import moment from 'moment';
import Message from 'components/Message';
import { isNil } from 'lodash';
import i18n from 'config/i18n';
import './index.scss';

function Note({ note, t }) {
  if (isNil(note)) return null;

  let { author } = note;
  if (!author)
    author = {
      role: t('roles.customer')
    };

  let { message } = note;
  if (i18n.exists(message)) message = t(message);

  return (
    <Message
      header={
        <a href={`/shipments/${note.shipment.id}`}>
          {t('notes.order')}: {note.shipment.orderNumber || '-'}
        </a>
      }
      body={message}
      footer={[
        moment(note.createdAt).format('DD/MM/YYYY HH:mm'),
        `[${author.role}]`,
        author.fullName
      ].join(' ')}
    />
  );
}

export default Note;

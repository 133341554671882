import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import ReactSVG from 'react-svg';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FieldSelectSearchable from 'components/FieldSelectSearchable';
import FieldSwitch2 from 'components/FieldSwitch2';
import FieldNumber from 'components/FieldNumber';
import get from 'lodash/get';
import some from 'lodash/some';
import isNil from 'lodash/isNil';
import { getDefault } from 'utils/textHelper';
import styled from 'styled-components';
import deleteIcon from 'assets/images/closeFat.svg';
import './index.scss';

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
`;

const AddLabelLink = styled.button`
  margin-top: 0.3rem;
  align-items: center;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 2.375rem;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 0 1rem;
  color: grey;

  &:hover {
    color: black;
  }
`;

const ButtonRemove = styled.button`
  min-height: 100%;
  padding: 0.5rem 1.5rem;
  display: flex;
  border: none;
  background: none;
  align-items: center;
  justify-content: center;
`;
const LabelLine = styled.div`
  display: flex;
  width: 100%;
  gap: 0.8rem;
  align-items: center;
`;
const ErrorMessage = styled.div`
  color: red;
  width: 100%;
`;
const badgeBackgrounds = {
  awaiting_on_customer: 'hsla(29, 92%, 53%, .7)',
  pending: 'hsla(29, 92%, 53%, .7)',
  declined: 'hsla(0, 92%, 53%, .7)'
};

const Badge = styled.div`
  padding: 0.5rem 1rem;
  background: ${({ theme }) => badgeBackgrounds[theme]};
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 800;
`;

const BrengerAlert = styled.div`
  margin-top: 1.5rem;
  font-size: 1.2rem;
`;

function renderError(errors, keyList) {
  for (let i = 0; i < keyList.length; i++) {
    const key = keyList[i];
    const error = get(errors, key);
    if (error) return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  return null;
}

const ModalApprove = ({
  shipment,
  shop = {},
  loading = false,
  locations = [],
  updateApproveStatus,
  visible,
  onClose,
  t
}) => {
  const multiDestinationsEnabled = get(shop, 'multiDestinationsEnabled');
  const gatekeeperEnabled = get(shop, 'gatekeeperEnabled');
  const shipmentIsFree = get(shipment, 'isFree');
  const approveStatus = get(shipment, 'approveStatus');
  const numberOfBoxes = getDefault(shipment, 'numberOfBoxes', 0);
  const isBrenger = get(shipment, 'network') === 'BRENGER';
  const network = get(shipment, 'network');

  const fileteredLocations = locations.filter(l => {
    if (network === 'BRENGER') {
      return ['nl', 'be'].includes(l.countryCode);
    }

    if (network === 'DHL') {
      return l.countryCode === 'nl';
    }

    return true;
  });

  const options = [];
  const optionIndex = {};
  let defaultOption;
  fileteredLocations.forEach(l => {
    const option = {
      text: l.name,
      value: l.id,
      address: `${l.street}, ${l.houseNumber}, ${l.postalCode} ${l.city}`
    };
    options.push(option);
    optionIndex[l.id] = option;
    if (!defaultOption && l.default) defaultOption = option;
  });

  const hasMultipleLocations = locations.length > 1;
  const {
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger
  } = useForm({
    mode: 'onSubmit'
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'locationPackages'
  });

  useEffect(() => {
    if (numberOfBoxes <= 0 || ![false, true].includes(multiDestinationsEnabled))
      return;

    let amount = numberOfBoxes;
    let locationId = null;
    if (multiDestinationsEnabled === true && !isBrenger) amount = 1;

    if (gatekeeperEnabled && !multiDestinationsEnabled && defaultOption)
      locationId = defaultOption.value;
    setValue('locationPackages', [{ locationId, amount }], {
      shouldValidate: true
    });
  // eslint-disable-next-line
}, [multiDestinationsEnabled, numberOfBoxes]);

  const validate = () => {
    const locationPackages = get(getValues(), 'locationPackages', []);

    const anyEmpty = some(
      locationPackages,
      ({ locationId }) => isNil(locationId) || locationId === 0
    );

    if (anyEmpty) {
      setError('locationId', {
        type: 'manual',
        message: t('errors.locations.required')
      });
    } else {
      clearErrors('locationId');
    }

    const amounts = locationPackages.map(item => item.amount);
    const number = amounts.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
    if (number >= numberOfBoxes) {
      clearErrors('amountOfBoxes');
    } else {
      setError('amountOfBoxes', {
        type: 'manual',
        message: t('errors.numberOfBoxes.wrongNumber')
      });
    }
  };

  return (
    <Modal
      visible={visible}
      headerText={t('shipments.approve.dialog.approvalOptions')}
      withCloseButton
      onClose={onClose}
      closeOnBlur
      className="modal--small modal-approve"
      style={{
        padding: '2.875rem 4.125rem'
      }}
    >
      <form
        onSubmit={handleSubmit(values =>
          updateApproveStatus('approved', values)
        )}
        className="create-label-form"
      >
        <div>
          {multiDestinationsEnabled && (
            <div className="info-field">
              <div className="info-field__label">
                {t('shipments.amountOfBoxes')}
              </div>
              <div className="info-field__value">{shipment.numberOfBoxes}</div>
            </div>
          )}
          {hasMultipleLocations && !isBrenger && (
            <>
              <div> {t('shipments.approve.dialog.destination')}</div>
              <LabelsContainer>
                {fields.map((item, index) => (
                  <LabelLine key={item.id}>
                    <FieldSelectSearchable
                      fullWidth={!multiDestinationsEnabled}
                      name={`locationPackages[${index}].locationId`}
                      defaultOption={optionIndex[item.locationId]}
                      control={control}
                      rules={{ validate }}
                      options={options}
                      disabled={false}
                      prompt={t('form.selectDestination')}
                      error={get(
                        errors,
                        `locationPackages[${index}].locationId`
                      )}
                      containerStyles={{ flexGrow: 1 }}
                    />
                    <FieldNumber
                      visible={multiDestinationsEnabled}
                      control={control}
                      name={`locationPackages[${index}].amount`}
                      setValue={setValue}
                      rules={{
                        validate,
                        valueAsNumber: true
                      }}
                      disabled={false}
                      error={get(errors, `locationPackages[${index}].amount`)}
                      defaultValue={item.amount}
                    />
                    {multiDestinationsEnabled && (
                      <ButtonRemove
                        type="button"
                        onClick={async () => {
                          await remove(item.id);
                          trigger();
                        }}
                      >
                        <ReactSVG src={deleteIcon} />
                      </ButtonRemove>
                    )}
                  </LabelLine>
                ))}
              </LabelsContainer>
              {multiDestinationsEnabled && !isBrenger && (
                <>
                  <AddLabelLink
                    type="button"
                    onClick={async () => {
                      await append({ locationId: null, amount: 1 });
                      trigger();
                    }}
                  >
                    {t('shipments.approve.dialog.addDestination')}
                  </AddLabelLink>
                  {renderError(errors, ['amountOfBoxes', 'locationId'])}
                </>
              )}
            </>
          )}
        </div>

        <div className="info-field">
          <div className="info-field__label">
            {t('shipments.approve.dialog.customerWillPay')}
          </div>
          <FieldSwitch2
            disabled={shipmentIsFree}
            defaultValue={shipmentIsFree}
            control={control}
            name="userShouldntPay"
            error={get(errors, 'userShouldntPay.message')}
            offText={t('general.no')}
            offValue
            onValue={false}
            onText={t('general.yes')}
          />
        </div>

        <div className="info-field">
          <div className="info-field__label">
            {t('shipments.approveStatusCurrent')}
          </div>
          <Badge theme={approveStatus}>
            {t(`statusApprove.${approveStatus}`)}
          </Badge>
        </div>

        {isBrenger && (
          <BrengerAlert>{t('shipments.approve.itsBrenger')}</BrengerAlert>
        )}

        <div className="modal-approve__buttons">
          {approveStatus !== 'approved' && (
            <Button
              disabled={loading}
              text={t('shipments.approve.doApprove')}
              type="submit"
              className="button--primary width--auto"
            />
          )}
          {['pending', 'declined'].includes(approveStatus) && (
            <Button
              disabled={loading}
              text={t('shipments.approve.markAsAwaiting')}
              className="button--orange width--auto"
              onClick={() => updateApproveStatus('awaiting_on_customer')}
            />
          )}
          {['declined', 'awaiting_on_customer'].includes(approveStatus) && (
            <Button
              disabled={loading}
              text={t('shipments.approve.makePendingAgain')}
              className="button--orange width--auto"
              onClick={() => updateApproveStatus('pending')}
            />
          )}
          {['pending', 'awaiting_on_customer'].includes(approveStatus) && (
            <Button
              disabled={loading}
              text={t('shipments.approve.doDecline')}
              className="button--red width--auto"
              onClick={() => updateApproveStatus('declined')}
            />
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ModalApprove;

import { put, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { getAuth } from 'utils/selectors';
import { loginRequest } from '../rootApi';
import { refreshTokenRequest } from './api';
import authActions from './actions';
import { processToken } from './helper';
import mainActions from '../main/actions';
import { handleAPIError } from '../../utils/sagaUtils';

export function* login(action) {
  yield call(implementPromiseAction, action, function*() {
    const { email, password } = action?.payload;

    let response;
    try {
      response = yield loginRequest(email, password);
    } catch (e) {
      yield put(authActions.loginFail());

      if (!e?.response)
        throw Error('Something went wrong. Please try again later.');

      throw Error('Unknown combination of username and / or password');
    }

    const token = get(response, 'headers.authorization');
    const tokenPayload = processToken(token);
    yield put(authActions.loginOk(token, tokenPayload));
  });
}

export function* refreshToken(action) {
  yield call(implementPromiseAction, action, function*() {
    let response;
    const auth = yield select(getAuth);
    const token = get(auth, 'token');

    try {
      response = yield refreshTokenRequest(token);
    } catch (e) {
      response = get(e, 'response');

      if (!response) {
        yield put(
          authActions.loginFail({
            email: 'Something went wrong. Please try again.'
          })
        );
        return;
      }

      const property = [401, 403].includes(response.status)
        ? 'email'
        : 'password';

      let message;
      switch (response.status) {
        case 401:
          message = 'Unknown combination of username and / or password';
          break;
        case 403:
          message = 'Too many login attempts - user is blocked';
          break;
        default:
          message = null;
      }

      yield put(
        authActions.loginFail({
          [property]: message
        })
      );
    }

    const newToken = get(response, 'data.data.refreshToken.jwtToken');
    const tokenPayload = processToken(newToken);
    yield put(authActions.refreshTokenOk(newToken, tokenPayload));
  });
}

export function* toggleLogoutModal() {
  yield put(mainActions.startLoading('toggleLogoutModal'));
  try {
    yield put(authActions.toggleLogoutModalOk());
  } catch (error) {
    yield put(authActions.toggleLogoutModalFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('toggleLogoutModal'));
  }
}

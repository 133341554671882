import React from 'react';
import ReactSVG from 'react-svg';
import { isNil } from 'lodash';
import teamMemberIcon from 'assets/images/teamMember.svg';
import './index.scss';

function TeamMemberIcon({ user }) {
  if (isNil(user)) return null;

  return (
    <div className="icon">
      <div key={user.email} className="team-member-avatar">
        <div className="team-member-avatar__icon">
          <ReactSVG src={teamMemberIcon} />
        </div>
        <div className="team-member-avatar__name">
          {user.firstName || user.first_name} {user.lastName || user.last_name}
        </div>
        <div className={`team-member-avatar__role--${user.role}`}>{user.role}</div>
      </div>
    </div>
  );
}

export default TeamMemberIcon;

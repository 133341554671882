import React from 'react';

function ButtonSelect({ options, onSelect, value }) {
  const onChange = event => onSelect(event.target.value);

  return (
    <select value={value} className="button-select" onChange={onChange}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>{option.text}</option>
      ))}
    </select>
  );
}

export default ButtonSelect;

export const MAIN = {
  START_LOADING: 'MAIN_START_LOADING',
  STOP_LOADING: 'MAIN_STOP_LOADING',
  SET_SERVER_ERROR: 'MAIN_SET_SERVER_ERROR',
  CLEAR_SERVER_ERROR: 'MAIN_CLEAR_SERVER_ERROR',
  SET_FEATURE_ERROR: 'MAIN_SET_FEATURE_ERROR',
  CLEAR_FEATURE_ERROR: 'MAIN_CLEAR_FEATURE_ERROR',
  RESET_FEATURE_ERRORS: 'MAIN_RESET_FEATURE_ERRORS',
  RESET: 'MAIN_RESET',
  SET_SHOW_SUCCESS_PICKUP_UPDATE_MODAL: 'MAIN_SET_SHOW_SUCCESS_PICKUP_UPDATE_MODAL'
};

export default {
  setServerError: message => ({
    type: MAIN.SET_SERVER_ERROR,
    message
  }),
  clearServerError: () => ({
    type: MAIN.CLEAR_SERVER_ERROR
  }),
  setFeatureError: (featureName, message) => ({
    type: MAIN.SET_FEATURE_ERROR,
    featureName,
    message
  }),
  clearFeatureError: featureName => ({
    type: MAIN.CLEAR_FEATURE_ERROR,
    featureName
  }),
  resetFeatureErrors: () => ({
    type: MAIN.RESET_FEATURE_ERRORS
  }),
  startLoading: featureName => ({
    type: MAIN.START_LOADING,
    featureName
  }),
  stopLoading: featureName => ({
    type: MAIN.STOP_LOADING,
    featureName
  }),
  setShowSuccessPickupUpdateModal: showSuccessPickupUpdateModal => ({
    type: MAIN.SET_SHOW_SUCCESS_PICKUP_UPDATE_MODAL,
    showSuccessPickupUpdateModal
  }),
  reset: () => ({
    type: MAIN.RESET
  })
};

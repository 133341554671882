import React from 'react';
import { Provider } from 'react-redux';
import { setApiToken } from 'features/rootApi';
import 'utils/monkeyPatches.js';
import { decodeToken } from 'features/auth/helper';
import moment from 'moment';
// import userActions from './actions/user';
import authActions from 'features/auth/actions';
import { store, dispatch } from 'store';
import Router from './Router';

export default class extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();
    if (auth.authenticated) {
      this.updateToken(auth.token);
      // dispatch(userActions.getUserInfo());
    }

    this.state = {
      token: auth.token
    };
  }

  componentDidMount() {
    const { token } = this.state;
    if (!token) return;

    const tokenPayload = decodeToken(token);
    const issuedAt = moment(tokenPayload.iat);
    const now = moment();
    const stop = moment.unix(issuedAt);
    const duration = moment.duration(now.diff(stop));
    const days = duration.asDays();
    if (days > 1) this.refreshToken(token);
  }

  async refreshToken(token) {
    await dispatch(authActions.refreshToken(token));
  }

  async updateToken(token) {
    if (token) {
      setApiToken(token);
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    );
  }
}

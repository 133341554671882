import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { dispatch } from 'store.js';
import teamActions from 'features/team/actions';
import TeamMember from 'components/TeamMember';
import teamMemberAddIcon from 'assets/images/teamMemberAdd.svg';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import ContentBoxRow from 'components/ContentBox/Row';
import ContentBoxRowHeader from 'components/ContentBox/InnerHeader';
import InviteMemberForm from './InviteMemberForm';
import MemberInvited from './MemberInvited';

import './index.scss';

export class TeamContainer extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.history = props.history;
    this.delete = this.delete.bind(this);
    this.state = {
      members: [],
      userToDelete: null,
      loading: false,
      apiErrors: {}
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { members, loading, apiErrors } = nextProps;

    if (!isEqual(apiErrors, prevState.apiErrors)) {
      newState = { ...newState, apiErrors };
    }
    if (!isEqual(members, prevState.members)) {
      newState = { ...newState, members };
    }

    if (!isEqual(loading, prevState.loading)) {
      newState = { ...newState, loading };
    }

    return newState;
  }

  componentDidMount() {
    dispatch(teamActions.getTeam());
  }

  delete() {
    const { userToDelete } = this.state;
    dispatch(teamActions.deleteMember(userToDelete));
  }

  render() {
    const { t, history, addMember, memberInvited } = this.props;
    const { members, userToDelete, loading, apiErrors } = this.state;

    if (addMember)
      return <InviteMemberForm {...{ t, history, loading, apiErrors }} />;
    if (memberInvited) return <MemberInvited {...{ t, history }} />;

    return (
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '98%',
          minHeight: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        main={
          <ContentBox
            style={{ minWidth: '100%', width: '100%', minHeight: '100%' }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem',
              marginBottom: '2.5rem'
            }}
          >
            <ContentBoxRow
              header={<ContentBoxRowHeader text={t('general.team')} />}
              content={
                <div className="team__container">
                  <div
                    className="team__add-member-button"
                    onClick={() => history.push('team/addMember')}
                  >
                    <div className="team-member-avatar__icon">
                      <ReactSVG src={teamMemberAddIcon} />
                    </div>
                    <span className="team-member-avatar__name team-member-avatar__text">
                      {t('team.inviteNewTeamMember')}
                    </span>
                  </div>
                  {members.map((member, index) => (
                    <TeamMember
                      user={member}
                      key={index}
                      showConfirmationDialog={member.email === userToDelete}
                      onDelete={id => this.setState({ userToDelete: id })}
                      confirmDelete={this.delete}
                      t={t}
                    />
                  ))}
                </div>
              }
            />
          </ContentBox>
        }
      />
    );
  }
}

const mapStateToProps = ({
  main: {
    loadingObj: { inviteMember: loading },
    featureErrors: { inviteMember: error }
  },
  team: { members }
}) => ({
  members,
  loading,
  apiErrors: { email: [error] }
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(TeamContainer);

import { TEAM } from './actions';

export const INITIAL_STATE = {
  members: [],
  error: null
};

export default (state = INITIAL_STATE, action = {}) => {
  const { members, error } = action;
  switch (action.type) {
    case TEAM.GET_OK:
      return {
        ...state,
        members,
        error: null
      };
    case TEAM.GET_FAIL:
      return {
        ...state,
        members: [],
        error
      };
    case TEAM.INVITE_MEMBER_OK:
      return {
        ...state,
        error: null
      };
    case TEAM.INVITE_MEMBER_FAIL:
      return {
        ...state,
        error
      };
      case TEAM.DELETE_MEMBER_OK:
        return {
          ...state,
          error: null
        };
      case TEAM.DELETE_MEMBER_FAIL:
        return {
          ...state,
          error
        };
    default:
      return state;
  }
};

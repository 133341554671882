import React from 'react';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import Button from 'components/Button';
import ReactSVG from 'react-svg';
import image from 'assets/images/balloons.svg';
import './index.scss';

function MemberInvited({ t, history }) {
  const {
    location: {
      state: { name }
    }
  } = history;

  return (
    <MainContentContainer
      style={{ height: 'fit-content' }}
      mainStyles={{
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      main={
        <>
          <ContentBox
            style={{ minWidth: '61.25rem', minHeight: '29.25rem' }}
            containerStyle={{
              flexDirection: 'column',
              minHeight: '100%',
              borderRadius: '2.5rem'
            }}
          >
            <div className="member-invited__image">
              <ReactSVG src={image} />
            </div>
            <div className="member-invited__header">
              {t('team.memberInvited', { name })}
            </div>
            <div className="member-invited__button">
              <Button
                iconName="team"
                style={{ height: '3.75rem' }}
                text={t('team.backToTeam')}
                className="button--primary member-invited-button"
                onClick={() => history.push('/team')}
              />
            </div>
          </ContentBox>
        </>
      }
    />
  );
}

export default MemberInvited;

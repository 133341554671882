import { INITIAL_STATE } from '../features/auth/reducer';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    const parsedState = JSON.parse(serializedState);

    const mergedState = {
      auth: { ...INITIAL_STATE, ...parsedState.auth }
    };

    return mergedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // somehow ?
  }
};

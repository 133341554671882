import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import freeze from 'redux-freeze';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import { promiseMiddleware } from '@adobe/redux-saga-promise';

import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { get } from 'lodash';
import { setApiToken } from 'features/rootApi';
import history from './history';
import rootSaga from './features/rootSaga';
import reducers from './features/rootReducer';
import { loadState, saveState } from './utils/localStorage';

const persistedState = loadState();
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const middleware = [
  promiseMiddleware,
  routerMiddleware,
  sagaMiddleware,
  freeze
];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const savedToken = get(persistedState, 'auth.token');
setApiToken(savedToken);

export const store = createStore(
  reducers,
  persistedState,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

export const { dispatch } = store;

let previousAuthState = store.getState().auth;

sagaMiddleware.run(rootSaga);
store.subscribe(async () => {
  const { auth } = store.getState();

  if (!auth.authenticated) {
    saveState();
  }

  if (!previousAuthState.token && auth.token && auth.authenticated) {
    saveState({ auth });
  }

  previousAuthState = { ...auth };
});

import React from 'react';
import LoaderCSS from 'components/LoaderCSS';
import InnerHeader from '../InnerHeader';
import Content from '../Content';

const Row = ({ header, content, style, contentStyle, loading }) => (
  <div className="content-box__row" style={style}>
    {header}
    <div className="content-box__row__content" style={contentStyle}>
      {loading ? <LoaderCSS visible wide /> : content}
    </div>
  </div>
);

Row.Header = InnerHeader;
Row.Content = Content;
export default Row;

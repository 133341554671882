import { get, isEmpty, isNil } from 'utils/lodash';
// import { PostalCodeConstructor } from 'utils/postalCode';

import {
  SHIPMENT_FIELDS,
  RETURN_TYPES,
  NETWORKS,
  APPROVE_STATUSES,
  PAYMENT_STATUSES,
  // LOCAL_ADDRESS_FIELDS,
  COUNTRIES_WITH_STATES
} from 'config/constants';
import { isBlank } from 'utils/textHelper';

const {
  RETURN_TYPE,
  NETWORK,
  SHIP_FROM_COUNTRY_CODE,
  APPROVE_STATUS,
  PROCEEDED,
  SIZE,
  PAYMENT_STATUS,
  LABEL_URL
} = SHIPMENT_FIELDS;

const getOrderFlags = (props = {}) => {
  const { values: order } = props;
  if (!order || isEmpty(order)) return { noData: true };

  const paymentStatus = get(order, PAYMENT_STATUS);
  const isEditing = get(order, 'isEditing');
  // const price = get(order, "price");
  const network = get(order, NETWORK);
  const shipFromCountryCode = get(order, SHIP_FROM_COUNTRY_CODE);
  const size = get(order, SIZE);
  const labelUrl = get(order, LABEL_URL);
  const noLabel = isBlank(labelUrl);
  const approveStatus = get(order, APPROVE_STATUS);
  const returnType = get(order, RETURN_TYPE);
  const proceeded = get(order, PROCEEDED);
  const isPickup = returnType === RETURN_TYPES.PICKUP;
  const isDropoff = returnType === RETURN_TYPES.DROPOFF;
  const isDhl = network && network.toLowerCase() === NETWORKS.DHL;
  const isUps = network && network.toLowerCase() === NETWORKS.UPS;
  const isDpd = network && network.toLowerCase() === NETWORKS.DPD;
  const isHomerr = network && network.toLowerCase() === NETWORKS.HOMERR;
  const isBrenger = network && network.toLowerCase() === NETWORKS.BRENGER;
  const isDhlPickup = isDhl && isPickup;
  const isUpsPickup = isUps && isPickup;
  const isUpsDropoff = isUps && isDropoff;
  const isDhlDropoff = isDhl && isDropoff;
  const isDpdDropoff = isDpd && isDropoff;
  const isApproved =
    !isNil(approveStatus) && approveStatus === APPROVE_STATUSES.APPROVED;
  const approvePending = [APPROVE_STATUSES.PENDING].includes(approveStatus);
  const paymentFailed = PAYMENT_STATUSES.FAILED.includes(paymentStatus);
  const paymentPending = PAYMENT_STATUSES.PENDING.includes(paymentStatus);
  const isInternational =
    !isBlank(shipFromCountryCode) && shipFromCountryCode !== 'nl';

  const isLocal = !isBlank(shipFromCountryCode) && shipFromCountryCode === 'nl';

  // const { POSTAL_CODE, POSTAL_CODE_END } = LOCAL_ADDRESS_FIELDS;
  // const postalCode = PostalCodeConstructor(
  //   get(order, POSTAL_CODE),
  //   get(order, POSTAL_CODE_END)
  // );

  const showTermsAndConditions = !isEditing;
  const addressNeedsState =
    isUps &&
    get(COUNTRIES_WITH_STATES, returnType, []).includes(shipFromCountryCode);

  return {
    order,
    approveStatus,
    returnType,
    network,
    proceeded,
    isPickup,
    isDropoff,
    isDhl,
    isUps,
    isDpd,
    isHomerr,
    isBrenger,
    isUpsPickup,
    isUpsDropoff,
    isDhlPickup,
    isDhlDropoff,
    isDpdDropoff,
    isLocal,
    isInternational,
    isApproved,
    approvePending,
    paymentFailed,
    paymentPending,
    noLabel,
    size,
    shipFromCountryCode,
    // postalCode,
    showTermsAndConditions,
    addressNeedsState
  };
};

export default getOrderFlags;

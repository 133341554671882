/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { getDefault } from 'utils/textHelper';
import { isEqual, get } from 'lodash';
import { dispatch } from 'store.js';
import shipmentActions from 'features/shipment/actions';
import shopActions from 'features/shop/actions';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import SearchBar from 'components/SearchBar';
import TableShipments from 'components/TableShipments';
import './index.scss';

class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchKey: props.match.params.searchKey,
      sorting: {}
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  getShop = async () => {
    try {
      dispatch(shopActions.getShop());
    } catch (e) {
      //
    }
  };

  componentDidMount() {
    const { isAdmin } = this.props;
    if (!isAdmin) this.getShop();
    const { searchKey } = this.state;
    dispatch(shipmentActions.searchShipments(searchKey, 1));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { match } = nextProps;

    let searchKey = getDefault(match, 'params.searchKey', '');
    searchKey = decodeURI(searchKey);
    if (!isEqual(searchKey, prevState.searchKey)) {
      newState = { ...newState, searchKey };
      dispatch(shipmentActions.searchShipments(searchKey, 1));
    }

    return newState;
  }

  onPageChange(page) {
    const { searchKey } = this.state;
    dispatch(shipmentActions.searchShipments(searchKey, page));
  }

  render() {
    const { t, isAdmin } = this.props;
    const { searchKey, sorting } = this.state;

    return (
      <MainContentContainer
        style={{ height: '100%' }}
        mainStyles={{
          width: '98%',
          minHeight: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        main={
          <>
            <SearchBar value={searchKey} />
            <ContentBox
              style={{
                minWidth: '100%',
                width: '100%',
                flexGrow: 1,
                display: 'flex'
              }}
              containerStyle={{ flexDirection: 'column', width: '100%' }}
            >
              <ContentBox.Row
                header={
                  <ContentBox.Row.Header
                    text={
                      <>
                        {t('search.header')} <b>{searchKey}</b>
                      </>
                    }
                  />
                }
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                content={
                  <TableShipments
                    featureName="searchShipments"
                    scope="forSearchPage"
                    onPageChange={this.onPageChange}
                    sorting={sorting}
                    isAdmin={isAdmin}
                  />
                }
              />
            </ContentBox>
          </>
        }
      />
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  isAdmin: get(user, 'isAdmin', false)
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(SearchResults);

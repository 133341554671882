import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import main from './main/reducer';
import auth from './auth/reducer';
import navigation from './navigation/reducer';
import shipment from './shipment/reducer';
import note from './note/reducer';
import timeslots from './timeslot/reducer';
import address from './address/reducer';
import team from './team/reducer';
import profile from './profile/reducer';
import stats from './stats/reducer';
import shop from './shop/reducer';
import investigation from './investigation/reducer';
import { AUTH } from './auth/actions';

const appReducer = combineReducers({
  form,
  auth,
  main,
  navigation,
  note,
  shipment,
  timeslots,
  address,
  team,
  profile,
  stats,
  shop,
  investigation
});

export default (state, action) => {
  if (action.type === AUTH.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';
import { roles } from 'config';
import { setApiToken } from '../rootApi';

export function decodeToken(token){
  return jwt_decode(token);
}

export function processToken(token) {
  const tokenPayload = decodeToken(token)
  Object.keys(roles).forEach(method => {
    tokenPayload[method] = tokenPayload.role === roles[method];
  });
  tokenPayload.isNotAdmin = !tokenPayload.isAdmin;
  setApiToken(token);
  return tokenPayload;
}

import React from 'react';
import { Field as ReduxFormField, reduxForm } from 'redux-form';
import 'assets/scss/_login-box.scss';
import { required } from 'utils/validations';
import Field from '../Field';
import Button from '../Button';

const RequestResetPassword = ({
  handleSubmit,
  errors,
  onSubmit,
  onRemoveError,
  t
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div>
      <div className="auth-container__header">
        {t('pages.requestResetPassword.header')}
      </div>
      <div className="auth-container__body">
        <ReduxFormField
          className=""
          component={Field}
          errors={errors}
          placeholder={t('general.email')}
          name="email"
          validate={[required]}
          onChange={() => {
            onRemoveError();
          }}
        />
      </div>
      <div className="auth-container__footer">
        <Button
          text={t('pages.requestResetPassword.submit')}
          type="submit"
          className="button--primary"
        />
      </div>
    </div>
  </form>
);

export default reduxForm({
  form: 'requestResetPasswordUrl'
})(RequestResetPassword);

import { createPromiseAction } from '@adobe/redux-saga-promise';

export const SHOP = {
  GET: 'SHOP_GET',
  GET_OK: 'SHOP_GET_OK',
  GET_ALL: 'SHOP_GET_ALL'
};

export default {
  getShop: createPromiseAction(SHOP.GET),
  getShopOk: data => ({
    type: SHOP.GET_OK,
    data
  }),
  getShops: createPromiseAction(SHOP.GET_ALL)
};

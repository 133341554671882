import React from 'react';
import arrowIcon from 'assets/images/arrow.svg';
import ReactSVG from 'react-svg';
import BreadcrumbsItem from './BreadcrumbsItem';
import './index.scss';

const chevron = <ReactSVG src={arrowIcon} className="breadcrumbs__chevron" />;
const interleave = (arr, thing) =>
  [].concat(...arr.map(n => [n, thing])).slice(0, -1);

function Breadcrumbs({ children, className = '', style = {} }) {
  const childrenWithChevrons = interleave(children, chevron);

  return (
    <div className={`breadcrumbs ${className}`} style={style}>
      {childrenWithChevrons}
    </div>
  );
}

Breadcrumbs.Item = BreadcrumbsItem;
export default Breadcrumbs;

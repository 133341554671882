import React from 'react';
import { dispatch } from 'store';
import cn from 'classnames';
import moment from 'moment';
import Pagination from 'components/Pagination';
import IconSquare from 'components/IconSquare';
import Table, { ALIGNMENT } from 'components/Table';
import LoaderCSS from 'components/LoaderCSS';
import investigationActions from 'features/investigation/actions';
import 'components/TableShipments/index.scss';
import './index.scss';

// In this component we utilize the styles from the TableShipments component
// because there it's not any difference
const TableInvestigations = ({
  withPagination = true,
  investigations,
  sorting,
  totalPages,
  page,
  loading,
  history,
  scope,
  t
}) => {
  function handlePageChange(newPage) {
    dispatch(investigationActions.getInvestigations(newPage, scope));
  }

  function openShipment(shipmentId) {
    history.push(`/shipments/${shipmentId}`);
  }

  function toggleInvestigationStatus(e, id, resolved) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      investigationActions.toggleInvestigationStatus(id, resolved, {
        scope,
        page
      })
    );
  }

  const columns = () => {
    return [
      {
        id: 'shop',
        title: t('shipments.shop'),
        accessor: d => d.shopName?.truncate(25),
        className: 'shipments-table__shop'
      },

      {
        id: 'orderNumber',
        title: t('shipments.orderNumber'),
        accessor: d => d.orderNumber.substring(0, 20),
        className: 'shipments-table__order-number'
      },
      {
        id: 'status',
        title: t('shipments.status'),
        accessor: d => <div className="badge">{t(`status.${d.status}`)}</div>,
        className: 'shipments-table__status'
      },
      {
        id: 'email',
        title: t('shipments.email'),
        accessor: d => d.email,
        className: 'shipments-table__email'
      },
      {
        id: 'country',
        title: t('shipments.country'),
        alignment: ALIGNMENT.CENTER,
        accessor: d => d.country,
        className: 'shipments-table__country'
      },
      {
        id: 'createdAt',
        title: t('shipments.createdAt'),
        alignment: ALIGNMENT.LEFT,
        accessor: d => moment(d.createdAt).format('DD/MM/YYYY HH:mm'),
        className: 'shipments-table__country'
      },
      {
        alignment: ALIGNMENT.CENTER,
        className: 'shipments-table__actions',
        accessor: () => (
          <IconSquare icon="eye" className="shipment-action__icon" />
        )
      },
      {
        id: 'action',
        alignment: ALIGNMENT.CENTER,
        className: 'shipments-table__actions',
        accessor: d => (
          <IconSquare
            icon="tick"
            className={cn('shipment-action__icon', {
              'tick--on': d.resolved
            })}
            onClick={e => toggleInvestigationStatus(e, d.id, !d.resolved)}
          />
        )
      }
    ];
  };

  const { sortCriteria, sortKey } = sorting;
  return (
    <div className="table-shipments__container">
      <div className="shipments-table__table-container">
        <Table
          withBorder={false}
          data={investigations}
          columns={columns()}
          sortCriteria={sortCriteria}
          sortKey={sortKey}
          onRowClick={item => openShipment(item.shipmentId)}
          rowClickable
          t={t}
        />
      </div>
      <LoaderCSS visible={loading} wide />
      {withPagination && investigations.length > 0 && totalPages > 1 && (
        <Pagination
          pages={totalPages}
          page={page}
          onPageChange={handlePageChange}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TableInvestigations;

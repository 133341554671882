export default {
  countries: {
    at: 'Austria',
    be: 'Belgium',
    bg: 'Bulgaria',
    cz: 'Czech Republic',
    de: 'Germany',
    dk: 'Denmark',
    ee: 'Estonia',
    es: 'Spain',
    fi: 'Finland',
    fr: 'France',
    gb: 'Great Britain',
    gr: 'Greece',
    hr: 'Croatia',
    hu: 'Hungary',
    ie: 'Ireland',
    it: 'Italy',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    lv: 'Latvia',
    mc: 'Monaco',
    nl: 'Netherlands',
    pl: 'Poland',
    pt: 'Portugal',
    ro: 'Romania',
    se: 'Sweden',
    si: 'Slovenia',
    sk: 'Slovakia'
  },
  fullMonths: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'Aug',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
  },
  errors: {
    format: {
      invalid_ship_from_house_number: "Invalid house number"
    },
    cancel: {
      after_pickup:
        "Shipment can't be cancelled because pickup date is in the past.",
      incomplete_shipment:
        "Shipment can't be cancelled because it's incomplete.",
      already:
        "Shipment can't be cancelled because it's been cancelled already.",
      label: "Shipment can't be cancelled because it's a dropoff shipment"
    },
    locations: {
      required: 'Some suppliers are not chosen',
      invalid_address: 'No such combination of postal code and house number',
      timeout: 'The response takes too much time. Try again later',
    },
    numberOfBoxes: {
      wrongNumber: 'Amount of boxes differs from the amount requested.'
    },
    timeslots: {
      uncovered_postal_code: 'No timeslots for this postcode',
      invalid: 'Invalid timeslot, choose another one'
    },
    user: {
      invalid_auth: 'The combination of email and password is incorrect.',
      already_confirmed: 'This account has been already confirmed.',
      already_reset_password: 'This link has been used already',
      already_exists: 'User with this email already exists.',
      reset_password_token_expired:
        'Reset password link has expired. Request a new one.'
    },
    passwords: {
      blank: 'No password was provided',
      too_short: 'The provided password was too short',
      too_long: 'The provided password was too long',
      invalid: 'The provided password has a wrong format'
    },
    closeButtonText: 'Gotcha',
    contactAdmin: 'Please, contact admin.',
    unknown_error: 'Unknown error. Please, contact admin'
  },
  locales: {
    english: 'English',
    dutch: 'Dutch',
    french: 'French',
    deutsch: 'Deutsch'
  },
  pages: {
    login: {
      header: 'Welcome to Returnista, log in to continue',
      submit: 'Log in',
      back: 'Back to log in'
    },
    requestResetPassword: {
      header: 'We need your email to send you a reset link',
      submit: 'Send',
      headerAfter:
        'We have sent you a reset email. Click on the link in the mail to reset your password.',
      linkTo: 'Forgot your password? Click here.'
    },
    resetPassword: {
      header: 'Set a new password',
      password: 'New password',
      repeatPassword: 'Repeat new password',
      submit: 'Confirm new password'
    },
    investigationForm: {
      investigation: 'Investigation',
      addComment: 'Add a comment',
      info:
        '*The information on this page is taken from the shipment page and cannot be changed here.',
      placeholder: 'Type a comment that you want to add to the survey.',
      primatyButtonText: 'Start investigation'
    }
  },
  faq: {
    title: 'Frequently asked questions'
  },
  form: {
    addNote: {
      body: {
        label: 'Type your note here'
      },
      buttonText: 'Save'
    },
    cancelShipment: {
      text: 'Are you sure you want to cancel the shipment?',
      notConfirm: 'Do not cancel',
      confirm: 'Cancel'
    },
    logout: {
      text: 'Are you sure you want to logout?',
      no: 'Cancel',
      yes: 'Confirm'
    },
    shipmentUpdated: {
      text: 'Changes saved and sent.',
      buttonText: 'Close'
    },
    investigationStarted: {
      headerText: 'Investigation is started',
      text: 'The carrier will respond within 2 working days.',
      buttonText: 'Close'
    },
    noteCreated: {
      text: 'Note is created',
      buttonText: 'Close'
    },
    locale: { prompt: 'Choose locale' },
    selectNetwork: 'Select carrier',
    selectAmountOfBoxes: 'Select amount of boxes',
    selectCountry: 'Select country',
    selectOption: 'Select option',
    selectTimeslot: 'Select time slot',
    selectShop: 'Select shop',
    selectDestination: 'Select destination',
    selectReturnType: 'Select return type'
  },
  general: {
    allShops: 'All shops',
    free: 'Free',
    overview: 'Overview',
    dutch: 'Dutch',
    edit: 'Edit',
    editShipment: 'Edit shipments',
    email: 'E-mail',
    english: 'English',
    password: 'Password',
    logout: 'Log out',
    myAccount: 'My account',
    newTeamMember: 'New team member',
    no: 'No',
    ongoingShipments: 'Current shipments',
    recentShipments: 'Recent shipments',
    overviewShipment: 'Overview shipments ',
    profile: 'Profile',
    save: 'Save',
    search: 'Search',
    shipment: 'Shipment',
    shipments: 'Shipments',
    shipmentsPerDay: 'Number of shipments per day',
    statistics: 'Statistics',
    team: 'Team',
    yes: 'Yes',
    hide: 'Hide',
    investigations: 'Investigations',
    from: 'From',
    to: 'To',
    noData: 'No data'
  },
  investigations: {
    unresolved: 'Unresolved',
    resolved: 'Resolved',
    all: 'All',
    investigationResolved: 'Investigation closed',
    startInvestigation: 'Start an investigation',
    closeInvestigation: 'Close the investigation',
    areYouSure: 'Are you sure you want to close the investigation?',
    resolve: 'Close investigation',
    closeDialog: 'Cancel'
  },
  home: {
    monthOverview: 'Overview'
  },
  notes: {
    addNote: 'Add note',
    archive: 'Archive',
    myNotes: 'My notes',
    noNoteAvailable: 'No notes available',
    notes: 'Notes',
    order: 'Order',
    pickupDetails: 'View shipment',
    recentNotes: 'Recent notes',
    viewNotes: 'View notes',
    allNotes: 'All notes',
    save: 'Save'
  },
  profile: {
    nameForm: {
      introText: 'You are about to update your name',
      firstName: {
        label: 'First name'
      },
      lastName: {
        label: 'Last name'
      }
    },
    passwordForm: {
      introText: 'You are about to change your password',
      oldPassword: {
        label: 'Current password'
      },
      newPassword: {
        label: 'New password'
      },
      repeatedNewPassword: {
        label: 'Confirm new password'
      },
      successText: 'Password successfully updated',
      successButtonText: 'Back to profile'
    },
    cancel: 'Cancel',
    update: 'Update',
    editNameButton: 'Edit name',
    changePasswordButton: 'Change password',
    changeLanguage: 'Change language to Dutch'
  },
  search: {
    header: 'Search results for',
    placeholder:
      'Type email, postal code, order number, status, name, phone or tracking number',
    search: 'Search'
  },
  shipments: {
    approve: {
      doApprove: 'Approve',
      doDecline: 'Decline',
      markAsAwaiting: 'Awaiting on customer',
      makePendingAgain: 'Pending',
      dialog: {
        addDestination: 'Bestemming toevoegen',
        destination: 'Destination of the package',
        customerWillPay: 'Customer will have to pay',
        approvalOptions: 'Approval options'
      },
      itsBrenger: 'This shipment will be processed by Brenger'
    },
    customerWillPay: 'Customer will have to pay',
    approveStatus: 'Approve status',
    approveStatusCurrent: 'Current approve status',
    accepted: 'Accepted',
    addressInf: 'Address information',
    streetAddress: 'Street address',
    amountOfBoxes: 'Number of parcels',
    cancel: 'Cancel Shipment',
    cancelled: 'Cancelled',
    city: 'City',
    comments: 'Comments',
    company: 'Company',
    country: 'Country',
    customerData: 'Customer details',
    createdAt: 'Created at',
    date: 'Date',
    details: 'Details',
    defect: 'Defect',
    description: 'Description',
    save: 'Save',
    saveWithEmail: 'Save & send e-mail',
    editShipment: 'Edit shipment',
    email: 'Email',
    endTimeSlot: 'End time-slot',
    export: 'Export',
    exportIncidents: 'Incidents',
    exportCSV: 'Export to CSV',
    finished:
      "Oh no, we couldn't find the shipments. Try another search please! 🤯",
    freeShipment: 'Free shipment',
    hasComments:
      'The customer has added a courier instruction to this shipment.',
    hasNotes: 'This shipment contains a note.',
    history: 'History',
    houseNum: 'House number',
    houseAddition: 'House number addition',
    hub: 'HUB',
    image: 'Uploaded image',
    inProgress:
      "One moment please, we're loading the shipments you're searching for...",
    labelUrl: 'Label URL',
    label: 'Label',
    locale: 'Language',
    name: 'Name',
    network: 'Carrier',
    noMore: 'There are no more shipments to show',
    orderInf: 'Order information',
    orderNum: 'Ordernr.',
    orderNumber: 'Order number',
    paid: 'Paid?',
    parent: 'Parent shipment',
    childLabels: 'Child labels',
    paymentStatus: 'Payment status',
    pickUpAddress: 'Pick up address',
    pickedUp: 'Picked up',
    pickupDate: 'Pick up moment',
    pickupDateDetails: 'Pick up moment',
    phone: 'Phone',
    postCode: 'Postal code',
    returnReason: 'Reason of return',
    returned: 'Return created',
    shipment: 'Shipment',
    shipmentDetails: 'Shipment details',
    shop: 'Shop',
    startTimeSlot: 'Start time-slot',
    timeslot: 'Timeslots',
    timeslotsFor: 'Timeslots for {{postalCode}}',
    status: 'Status',
    street: 'Street',
    trackingCode: 'Trackingcode',
    trackShipment: 'Track shipment',
    openFile: 'Link to the file',
    creationDate: 'Creation date',
    insured: 'Insured',
    paymentsStatus: 'Payment status',
    location: 'Destination',
    generateShipment: 'Create shipment',
    generateLabel: 'Create label',
    allShipments: 'All shipments',
    refreshShipments: 'Reload shipments',
    open: 'Open',
    file: 'File',
    returnType: 'Return type',
    returnTypes: {
      dropoff: 'Drop-off',
      pickup: 'Pickup'
    },
    houseNumber: 'House number',
    houseNumberSuffix: 'House number suffix',
    dropoff: 'Drop off',
    pickup: 'Pick-up',
    wantExchangeTo: 'Wants to exchange',
    customReturnReason: "Other return reason",
    wasChanged: "Shipment was changed",
    stateProvince: "State/Province"
  },
  sidebar: {
    overview: 'Overview',
    notes: 'Notes',
    shipments: 'Shipments',
    statistics: 'Statistics',
    team: 'Team',
    generateShipment: 'Create shipment',
    profile: 'Profile',
    investigations: 'Investigations'
  },
  stats: {
    details: 'Details',
    name: 'Name',
    others: 'Others',
    retourRedenen: 'Return reasons',
    shipmentsMonth: 'Shipments month',
    shipmentsPerNetwerk: 'Shipments per shop',
    shipmentsTotal: 'Total shipments',
    shipmentsLocal: 'Local shipments',
    shipmentsForeign: 'Foreign shipments',
    dayWithCount: '{{count}} day',
    dayWithCount_plural: '{{count}} days',
    total: 'Total',
    totalShipments: '{{network}} shipments',
    totalDropoff: '{{network}} drop-offs',
    totalPickup: '{{network}} pick-ups',
    performancePerMerchant: 'Performance per merchant',
    performancePerCountryPerMerchant: 'Performance per country per merchant',
    shopName: 'Shop name',
    filter: 'Select date range',
    load: 'Load',
    insurances: 'Insurances',
    country: 'Country',
    amount: 'Amount',
    monthly: 'Monthly',
    numberOfPackages: 'Number of packages',
    returnReasons: 'Reasons of return',
    byPricing: {
      title: 'By pricing',
      totalFree: 'Free',
      totalPaid: 'Paid'
    },
    allTime: 'For all time',
    totalFree: 'Free',
    byReturnType: {
      title: 'By return type',
      totalDropoffs: 'Dropoffs',
      totalPickups: 'Pickups'
    },
    countryCounters: {
      title: 'By country'
    },
    byCreator: {
      title: 'By creator',
      byAdmins: 'By admins',
      byShopAdmins: 'By shop admins'
    },
  },
  status: {
    cancelled: 'cancelled',
    new: 'new',
    delivered: 'delivered',
    completed: 'completed',
    unfulfilled: 'unfulfilled',
    incident: 'incident',
    transit: 'transit',
    in_depot: 'in_depot',
    collected: 'collected',
    scheduled: 'scheduled',
    out_delivery: 'out_delivery',
    requested: 'requested'
  },
  statusApprove: {
    declined: 'Declined',
    approved: 'Approved',
    pending: 'Pending',
    awaiting_on_customer: 'Awaiting on customer'
  },
  team: {
    accountTitle: 'My account',
    detail: 'Account details',
    editAccount: 'Edit',
    email: 'E-mailaddress',
    firstName: 'First name',
    invite: 'Invite',
    inviteTitle: 'Invite new team member',
    inviteNewTeamMember: 'Invite new team member',
    lastName: 'Surname',
    name: 'Name',
    password: 'Password',
    title: 'Customer Service team',
    memberInvited: 'Hooray! {{name}} is invited to join your team!',
    backToTeam: 'Back to team',
    delete: 'Delete member',
    notDelete: "Don't delete",
    areYouSure: 'Are you sure you want to delete member'
  },
  validationErrors: {
    required: '',
    minLength: '',
    maxLength: '',
    email: '',
    repeated: '',
    alreadyExists: '',
    strongPassword: ''
  },
  roles: {
    shop_admin: 'Shop Admin',
    app: 'App',
    admin: 'Admin',
    customer: 'Customer'
  }
};

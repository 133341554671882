import { createPromiseAction } from '@adobe/redux-saga-promise';

export const TIMESLOTS = {
  GET: 'TIMESLOTS_GET',
  GET_FAIL: 'TIMESLOTS_GET_FAIL',
  GET_OK: 'TIMESLOTS_GET_OK',
  CLEAR_TIMESLOTS: 'TIMESLOTS_CLEAR_TIMESLOTS',
  CLEAR_ERRORS: 'TIMESLOTS_CLEAR_ERRORS'
};

export default {
  getTimeslots: createPromiseAction(TIMESLOTS.GET),
  getTimeslotsOk: timeslots => ({
    type: TIMESLOTS.GET_OK,
    timeslots
  }),
  getTimeslotsFail: error => ({
    type: TIMESLOTS.GET_FAIL,
    error
  }),
  clearTimeslots: () => ({
    type: TIMESLOTS.CLEAR_TIMESLOTS
  }),
  clearErrors: () => ({
    type: TIMESLOTS.CLEAR_ERRORS
  })
};

import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import IconCaret from 'components/IconCaret';
import LoaderCSS from 'components/LoaderCSS';

import './index.scss';

export const ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center'
};

const sortCriterias = {
  ASC: 'DESC',
  DESC: 'ASC'
};

export default ({
  columns,
  data,
  withBorder = true,
  hasHeader = true,
  className = '',
  onRowClick,
  rowClickable = false,
  onHeadClick = () => {},
  sortKey,
  sortCriteria = 'DESC',
  loading,
  t
}) => {
  const emptyText = t('general.noData')
  const renderNoItems = () => {
    if (data.length !== 0) return null;

    return (
      <tr className="table__row">
        <td align="center" colSpan={columns.length}>
          {loading ? <LoaderCSS visible wide/> : emptyText}
        </td>
      </tr>
    );
  };

  return (
    <table
      className={`table ${
        withBorder ? 'table--with-border' : 'table--no-border'
      } ${className}`}
    >
      {hasHeader && (
        <thead>
          <tr>
            {columns.map((c) => (
              <th
                align={c.alignment || 'left'}
                className={`table-option--alignment-${c.alignment || 'left'} ${
                  c.sortable ? 'sortable' : ''
                }`}
                key={`column-${c.id}`}
                onClick={() =>
                  c.sortable && onHeadClick(c.id, sortCriterias[sortCriteria])
                }
              >
                {c.title}
                {c.sortable && (
                  <IconCaret direction={sortKey === c.id && sortCriteria} />
                )}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {renderNoItems()}
        {data.length > 0 &&
          data.map((item) => {
            return (
              <tr
                key={`${item.id}`}
                className={cn({
                  table__row: true,
                  'cursor--pointer': rowClickable
                })}
                onClick={e => rowClickable && onRowClick(item, e)}
              >
                {columns.map(c => (
                  <td
                    align={c.alignment || 'left'}
                    className={`table-option--alignment-${c.alignment ||
                      'left'} ${get(c, 'className', '')}`}
                    key={c.id}
                  >
                    {c.accessor(item)}
                  </td>
                ))}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

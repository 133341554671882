import React from 'react';
import { isNil } from 'lodash';
import TableShipmentsPerCountry from 'components/TableShipmentsPerCountry';
import Counter from '../Counter';
import './index.scss'

export default function ForeignCounters({ counters, rows, t }) {
  if (isNil(counters)) return null;
  if (isNil(rows)) return null;
  const { all, pickup, dropoff } = counters;

  const dataSet = [
    ['stats.totalShipments', t('stats.total'), all],
    ['stats.totalDropoff', t('stats.total'), dropoff],
    ['stats.totalPickup', t('stats.total'), pickup]
  ];
  
  return (
    <>
      <div className='foreign-counters-grid'>
      {dataSet.map((line, index) => (
        <Counter
          key={index}
          title={t(line[0], { network: line[1] })}
          value={line[2]}
        />
      ))}
      </div>
      <TableShipmentsPerCountry rows={rows} t={t} />
    </>
  );
}

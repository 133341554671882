import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import './index.scss';

const mapStateToProps = state => ({
  expanded: state.navigation.expanded
});

const MainContentContainer = ({
  expanded,
  main,
  header,
  mainClassNames = '',
  mainStyles = {},
  containerStyles = {},
  containerClassNames = ''
}) => (
  <div
    className={cn('content-container', containerClassNames)}
    style={{
      paddingLeft: expanded ? '16.875rem' : '4.3125rem',
      ...containerStyles
    }}
  >
    <div
      className={`${mainClassNames} content-container__main`}
      style={mainStyles}
    >
      {header && (
        <div
          className="content-container__main__header"
          style={{ width: mainStyles.width }}
        >
          {header}
        </div>
      )}
      {main}
    </div>
  </div>
);

export default connect(mapStateToProps)(MainContentContainer);

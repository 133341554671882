import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/returnista-logo.svg';
import './index.scss';

export default ({ ...props }) => {
  let switchComponent = null;
  const {
    switchPath,
    switchContent,
    className = '',
    children,
    modalClass = ''
  } = props;
  if (switchPath && switchContent) {
    switchComponent = (
      <div className="auth-container__switch">
        <Link to={props.switchPath}>{props.switchContent}</Link>
      </div>
    );
  }

  return (
    <div className={`auth-container ${className}`}>
      <div className="auth-container__logo">
        <img src={logo} alt="Volta!" className="logo__image" />
      </div>
      <div className={`auth-container__box-container ${modalClass}`}>
        <div className="auth-container__box">{children}</div>
        {switchComponent}
      </div>
    </div>
  );
};

import React from 'react';
import { isEmpty } from 'lodash';
import numeral from 'numeral'
import './index.scss';

export default function MonthlyCounters({ data = [], t }) {
  if (isEmpty(data)) return false;

  return (
    <div className="monthly-counters">
      {data
        .map((counter, index) => (
          <div className="monthly-counter" key={`monthly-${index}`}>
            <div>
              {t(`fullMonths.${counter[0]}`)} {counter[1]}
            </div>
            <div>{numeral(counter[2]).format('0,0')}</div>
          </div>
        ))
        .reduce((result, item) => [result, <hr className='monthly-counter__line' />, item])}
    </div>
  );
}

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldInput from 'components/Field';
import { get } from 'lodash';

function ShipFromPostalCode(props = {}) {
  const { disabled, errors, validate = [], t } = props;

  return (
    <Field
      className="other-class"
      disabled={disabled}
      inputStyle={{ textTransform: 'uppercase' }}
      errors={[get(errors, 'shipFromPostalCode')]}
      label={t('shipments.postCode')}
      name="shipFromPostalCode"
      component={FieldInput}
      validate={validate}
    />
  );
}
export default withTranslation()(ShipFromPostalCode);

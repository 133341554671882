import { put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { get } from 'utils/lodash';
import { handleAPIError } from 'utils/sagaUtils';
import { formatTimeslots } from 'utils/formatters';
import { getTimeslotsRequest } from './api';
import mainActions from '../main/actions';
import timeslotActions from './actions';

export function* getTimeslots(action) {
  yield call(implementPromiseAction, action, function*() {
    yield put(mainActions.startLoading('getTimeslots'));
    const {
      payload: { postalCode, shopId, network }
    } = action;

    try {
      const response = yield getTimeslotsRequest(postalCode, shopId, network);
      // create options for field select
      const timeslots = formatTimeslots(get(response, 'data.data.timeslots'));
      yield put(timeslotActions.getTimeslotsOk(timeslots));
      return timeslots;
    } catch (error) {
      handleAPIError(error, 'getTimeslots');
      throw error;
    } finally {
      yield put(mainActions.stopLoading('getTimeslots'));
    }
  });
}

import { get } from 'lodash';
import { post } from '../rootApi';

export function getAddressRequest(postalCode, houseNumber) {
  return post({
    query: 'op:ResolveAddress',
    variables: {
      postalCode,
      houseNumber,
    }
  });
}

export const addressResponseInterface = response => {
  const { city, street: streetName } = get(response, 'data.data.address', {});
  return {
    city,
    streetName
  };
};

import { NAVIGATION } from './actions';

const INITIAL_STATE = {
  expanded: true
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case NAVIGATION.EXPAND:
      return {
        ...state,
        expanded: true
      };
    case NAVIGATION.COLLAPSE:
      return {
        ...state,
        expanded: false
      };
    case NAVIGATION.TOGGLE:
      return {
        ...state,
        expanded: !state.expanded
      };
    default: {
      return state;
    }
  }
};

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { initialize } from 'redux-form';
import authActions from 'features/auth/actions';
import { dispatch } from 'store';
import AuthLayout from 'components/AuthLayout';
import LoginForm from 'containers/Login/LoginForm';

const LoginContainer = ({ t }) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const handleAuth = async ({ email, password }) => {
    setLoading(true);
    try {
      await dispatch(authActions.login({ email, password }));
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <AuthLayout
      switchPath="/request-reset-password-url"
      switchContent={t('pages.requestResetPassword.linkTo')}
      modalClass="auth-container__login"
    >
      <LoginForm
        loading={loading}
        error1={error}
        clearError={() => setError()}
        onSubmit={handleAuth}
        onEmailChange={email =>
          dispatch(initialize('requestResetPasswordUrl', { email }))
        }
        t={t}
      />
    </AuthLayout>
  );
};

export default compose(withRouter, withTranslation())(LoginContainer);

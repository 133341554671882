import React from 'react';
import { isNil } from 'lodash';
import Counter from '../Counter';
import './index.scss'

export default function LocalCounters({ counters, t }) {
  if (isNil(counters)) return null;
  const { all, dhl, ups } = counters;

  const dataSet = [
    ['stats.totalShipments', t('stats.total'), all.all],
    ['stats.totalDropoff', t('stats.total'), all.dropoff],
    ['stats.totalPickup', t('stats.total'), all.pickup],
    ['stats.totalShipments', 'DHL', dhl.all],
    ['stats.totalDropoff', 'DHL', dhl.dropoff],
    ['stats.totalPickup', 'DHL', dhl.pickup],
    ['stats.totalShipments', 'UPS', ups.all],
    ['stats.totalDropoff', 'UPS', ups.dropoff],
    ['stats.totalPickup', 'UPS', ups.pickup]
  ];

  return (
    <div className='local-counters-grid'>
      {dataSet.map((line, index) => (
        <Counter
          key={index}
          title={t(line[0], { network: line[1] })}
          value={line[2]}
        />
      ))}
    </div>
  );
}

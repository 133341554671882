import { SHIPMENTS } from './actions';

export const INITIAL_STATE = {
  forOverviewPage: { shipments: [], totalPages: 1, page: 0 },
  forShipmentsPage: undefined,
  forSearchPage: { shipments: [], totalPages: 1, page: 0 },
  forGenerateShipmentPage: { shipments: [], totalPages: 1, page: 1 },
  shipment: null,
  page: 1,
  shopId: undefined,
  errors: {}
};

export default (state = INITIAL_STATE, action = {}) => {
  const {
    pageName,
    totalPages,
    page,
    shipments,
    shipment,
    error,
    featureName,
    shopId
  } = action;
  switch (action.type) {
    case SHIPMENTS.GET_OK:
      return {
        ...state,
        [pageName]: {
          shipments,
          totalPages,
          page
        },
        errors: { ...state.errors, [featureName]: null }
      };
    case SHIPMENTS.GET_FAIL:
      return {
        ...state,
        [pageName]: INITIAL_STATE[pageName],
        errors: { ...state.errors, [featureName]: error }
      };
    case SHIPMENTS.SEARCH_OK:
      return {
        ...state,
        forSearchPage: {
          shipments,
          totalPages,
          page
        },
        errors: { ...state.errors, [featureName]: null }
      };
    case SHIPMENTS.SEARCH_FAIL:
      return {
        ...state,
        forSearchPage: INITIAL_STATE.forSearchPage,
        errors: { ...state.errors, [featureName]: error }
      };
    case SHIPMENTS.GET_ONE_OK:
      return {
        ...state,
        shipment,
        errors: { ...state.errors, [featureName]: null }
      };
    case SHIPMENTS.GET_ONE_FAIL:
      return {
        ...state,
        shipment: null,
        errors: { ...state.errors, [featureName]: error }
      };
    case SHIPMENTS.CLEAR_SHIPMENT:
      return {
        ...state,
        shipment: null
      };
    case SHIPMENTS.CREATE_LABEL_OK:
    case SHIPMENTS.CREATE_SHIPMENT_OK:
    case SHIPMENTS.UPDATE_APPROVE_STATUS_OK:
    case SHIPMENTS.UPDATE_PICKUP_OK:
    case SHIPMENTS.CANCEL_ONE_OK:
      return {
        ...state,
        errors: { ...state.errors, [featureName]: null }
      };
    case SHIPMENTS.CREATE_LABEL_FAIL:
    case SHIPMENTS.CREATE_SHIPMENT_FAIL:
    case SHIPMENTS.UPDATE_APPROVE_STATUS_FAIL:
    case SHIPMENTS.UPDATE_PICKUP_FAIL:
    case SHIPMENTS.CANCEL_ONE_FAIL:
      return {
        ...state,
        errors: { ...state.errors, [featureName]: error }
      };
    case SHIPMENTS.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case SHIPMENTS.SET_PAGE:
      return {
        ...state,
        [featureName]: {
          ...state[featureName],
          page
        }
      };
    case SHIPMENTS.SET_SHOP_ID:
      return {
        ...state,
        shopId
      };
    default:
      return state;
  }
};

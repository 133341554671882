/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { isEqual, get } from 'lodash';
import SearchBar from 'components/SearchBar';
import Chart from 'components/Chart';
import Note from 'components/Note';
import shipmentActions from 'features/shipment/actions';
import { dispatch } from 'store';
import noteActions from 'features/note/actions';
import statsActions from 'features/stats/actions';
import MainContentContainer from 'components/MainContentContainer';
import ContentBox from 'components/ContentBox';
import TableShipments from 'components/TableShipments';
import LoaderCSS from 'components/LoaderCSS';
import './index.scss';

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sorting: {},
      notes: [],
      dailyCounters: [],
      loading: {}
    };

    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    const { isAdmin } = this.props;
    dispatch(
      shipmentActions.getShipments({
        scope: 'forOverviewPage',
        page: 1,
        sorting: {},
        isAdmin
      })
    );
    dispatch(noteActions.getNotes('forOverviewPage', 1, {}));
    dispatch(statsActions.getDailyCounters(14));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    const { notes, dailyCounters, loading } = nextProps;

    if (!isEqual(notes, prevState.notes)) {
      newState = { ...newState, notes };
    }

    if (!isEqual(dailyCounters, prevState.dailyCounters)) {
      newState = { ...newState, dailyCounters };
    }

    if (!isEqual(loading, prevState.loading)) {
      newState = { ...newState, loading };
    }

    return newState;
  }

  onSort(sortKey, sortCriteria) {
    const { isAdmin } = this.props;
    this.setState({ sorting: { sortKey, sortCriteria } });
    dispatch(
      shipmentActions.getShipments({
        scope: 'forOverviewPage',
        page: 1,
        sorting: {
          sortKey,
          sortCriteria
        },
        isAdmin
      })
    );
  }

  render() {
    const { t, isAdmin } = this.props;
    const { notes, dailyCounters, sorting, loading } = this.state;

    return (
      <MainContentContainer
        style={{ height: 'fit-content' }}
        mainStyles={{
          width: '100%',
          minHeight: '100%',
          height: '100%',
          display: 'flex'
        }}
        main={
          <>
            <div className="central-content">
              <SearchBar />
              <ContentBox
                className="overview__stats"
                containerStyle={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  height: '100%'
                }}
              >
                <ContentBox.Row
                  header={
                    <ContentBox.Row.Header text={t('general.statistics')}>
                      <LoaderCSS visible={loading.getDailyCounters} />
                    </ContentBox.Row.Header>
                  }
                  content={<Chart data={dailyCounters} t={t} />}
                />
              </ContentBox>
              <ContentBox
                className="overview__shipments"
                containerStyle={{
                  flexDirection: 'column',
                  border: 'none',
                  height: '100%'
                }}
              >
                <ContentBox.Row
                  style={{ overflowY: 'scroll' }}
                  header={
                    <ContentBox.Row.Header text={t('general.recentShipments')}>
                      <LoaderCSS visible={loading.getShipments} />
                    </ContentBox.Row.Header>
                  }
                  content={
                    <TableShipments
                      scope="forOverviewPage"
                      featureName="getShipments"
                      onSort={this.onSort}
                      sorting={sorting}
                      isAdmin={isAdmin}
                      withPagination={false}
                    />
                  }
                />
              </ContentBox>
            </div>
            <div className="right-content">
              <ContentBox
                className="overview__notes"
                containerStyle={{
                  flexDirection: 'column',
                  border: 'none',
                  borderRadius: '2.5rem',
                  height: '100%'
                }}
              >
                <ContentBox.Row
                  style={{ overflowY: 'scroll' }}
                  header={
                    <ContentBox.Row.Header text={t('notes.notes')}>
                      <LoaderCSS visible={loading.getNotes} />
                    </ContentBox.Row.Header>
                  }
                  content={notes.map((note, index) => (
                    <Note key={index} note={note} t={t} />
                  ))}
                />
              </ContentBox>
            </div>
          </>
        }
      />
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  note: {
    forOverviewPage: { notes }
  },
  stats: { dailyCounters },
  main: { loadingObj: loading }
}) => ({
  notes,
  dailyCounters,
  loading,
  isAdmin: get(user, 'isAdmin', false)
});
export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Overview);

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';
import flags from 'utils/flags';
import { useSelector } from 'react-redux';
import { getShop } from 'utils/selectors';

function ShipFromCountryCode(props = {}) {
  const {
    disabled,
    errors,
    values,
    validate = [],
    change,
    onChange,
    t
  } = props;

  const shop = useSelector(getShop);
  const countryOptions = get(shop, 'countryOptions', []);
  const { isUps, isDpd, isPickup } = flags({values});

  const options = countryOptions.filter(option => {
    if (isPickup && isUps) return option.value !== 'nl';
    if (isDpd) return option.value !== 'us';

    return true;
  });

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      style={{ zIndex: 10 }}
      disabled={disabled}
      options={options}
      prompt={t('form.selectCountry')}
      errors={[get(errors, 'shipFromCountryCode')]}
      label={t('shipments.country')}
      name="shipFromCountryCode"
      validate={validate}
      change={change}
      onChange={onChange}
    />
  );
}
export default withTranslation()(ShipFromCountryCode);

import { post } from '../rootApi';

export function getDailyCountersRequest(noDays) {
  const to = new Date();
  const rawFrom = new Date(to);
  if (noDays === 365) {
    rawFrom.setFullYear(to.getFullYear() - 1);
  } else {
    rawFrom.setDate(to.getDate() - noDays + 1);
  }
  const from = rawFrom.toISOString();
  const query = `
  {
    statsDailyCounters(from: "${from}", to: "${to.toISOString()}") {
      date
      label
      fullLabel
      value
    }
  }
 `;

  return post({ query });
}

export function getTotalCountersRequest(fromValue, toValue) {
  const fromTo = [];
  if (fromValue) fromTo.push(`from: "${fromValue}"`);
  if (toValue) fromTo.push(`to: "${toValue}"`);
  let fromToStr = fromTo.join(', ');
  if (fromToStr !== '') fromToStr = `(${fromToStr})`;

  const query = `
  query {
    statsTotalCounters${fromToStr}
    statsBilledCounters${fromToStr}
  }
 `;

  return post({ query });
}

export function getShopCountersRequest(fromValue, toValue) {
  const fromTo = [];
  if (fromValue) fromTo.push(`from: "${fromValue}"`);
  if (toValue) fromTo.push(`to: "${toValue}"`);
  let fromToStr = fromTo.join(', ');
  if (fromToStr !== '') fromToStr = `(${fromToStr})`;

  const query = `
  query {
    statsMonthlyCounters
    statsReturnReasonCounters${fromToStr}
    statsShopCounters${fromToStr}
    shop {
      returnReasons
      showReturnReasons
      gatekeeperEnabled
    }
  }
 `;

  return post({ query });
}

import React from 'react';
import cn from 'classnames';

export default ({ className = '', text, separated, style, children }) => (
  <div
    className={cn(
      'content-box__row__header',
      {
        'content-box__row__header--with-separator': separated
      },
      className
    )}
    style={style}
  >
    <h1 className="h1--default" style={{ marginBottom: '0' }}>
      {text}
    </h1>
    {children}
  </div>
);

import { post } from '../rootApi';

export function getTeamMembersRequest() {
  const GET_TEAM = `query{
    team(shopId:null) {
     email
     firstName
     lastName
     role
    }
  }
`;

  return post({ query: GET_TEAM });
}

export function inviteTeamMemberRequest(firstName, lastName, email) {
  const INVITE_USER = `
    mutation inviteUser( $email:String!,$firstName :String!, $lastName:String!){
    inviteUser(email: $email, firstName: $firstName, lastName: $lastName) {
      email
      firstName
      lastName,
    }
  }
`;

  const variables = { email, firstName, lastName };
  return post({ query: INVITE_USER, variables });
}

export function deleteTeamMemberRequest(email) {
  const query = `
    mutation deleteUser( $email:String!){
    deleteUser(email: $email)
  }
`;

  return post({ query, variables: { email } });
}

import React from 'react';
import ShopSelector from 'components/ShopSelector';
import { withTranslation } from 'react-i18next';

function Shop(props = {}) {
  const { loading, selectedValue, onChange, shopOptions = [] } = props;

  const options = shopOptions;

  if (!options) return null;

  return (
    <ShopSelector
      options={options}
      onSelect={onChange}
      loading={loading}
      selectedValue={selectedValue}
    />
  );
}
export default withTranslation()(Shop);

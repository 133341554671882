import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import './index.scss';

const ConfirmLogoutModal = ({ onClose, onProceed, t }) => {
  return (
    <Modal
      visible
      className="modal--small"
      onClose={onClose}
      withCloseButton
      style={{
        padding: '2.875rem 4.125rem'
      }}
    >
      <>
        <div className="confirm-logout-modal__text">
          {t('form.logout.text')}
        </div>
        <div className="confirm-logout-modal__buttons">
          <Button
            text={t('form.logout.no')}
            className="button--grey width--auto"
            onClick={onClose}
          />
          <Button
            text={t('form.logout.yes')}
            onClick={onProceed}
            className="button--danger width--auto"
          />
        </div>
      </>
    </Modal>
  );
};

export default ConfirmLogoutModal;

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Text, Modal } from './styles';
import { useSelector } from 'react-redux';
import { dispatch } from 'store.js';
import mainActions from 'features/main/actions';
import { get } from 'utils/lodash';
import { getMain } from 'utils/selectors';
import Button from 'components/Button';

function SuccessUpdateModal(props) {
  const { t } = props;
  const onClose = () =>
    dispatch(mainActions.setShowSuccessPickupUpdateModal(false));
  const main = useSelector(getMain);
  const show = get(main, 'showSuccessPickupUpdateModal');

  if (!show) return null;

  return (
    <Modal onClose={onClose}>
      <Container>
        <Text>{t('form.shipmentUpdated.text')}</Text>

        <Button
          text={t('errors.closeButtonText')}
          type="submit"
          onClick={onClose}
          className="button--primary width--auto"
        />
      </Container>
    </Modal>
  );
}

export default withTranslation()(SuccessUpdateModal);

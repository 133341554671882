import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldSelect from 'components/FieldSelect';
import { get } from 'lodash';

function Timeslot(props = {}) {
  const {
    options,
    disabled,
    errors,
    validate = [],
    change,
    onChange,
    t
  } = props;
  
  if (!options) return null;

  return (
    <Field
      component={FieldSelect}
      className="other-class"
      style={{ zIndex: 9 }}
      disabled={disabled}
      options={options}
      prompt={t('form.selectTimeslot')}
      errors={[get(errors, 'timeslotId')]}
      label={t('shipments.timeslot')}
      name="timeslotId"
      validate={validate}
      change={change}
      onChange={onChange}
    />
  );
}
export default withTranslation()(Timeslot);

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Router as BrowserRouter,
  Route,
  Redirect,
  Switch,
  withRouter
} from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { noStatisticsShopsIds } from 'config.js';
import history from './history';

import MainContainer from './components/MainContainer';
import SidebarNavigation from './components/SidebarNavigation';
// import * as Icons from './utils/icons';
import Loader from './components/Loader';
import IconSquare from './components/IconSquare';
import { dispatch } from './store';
import authActions from './features/auth/actions';
import Login from './containers/Login';
import ConfirmLogoutModal from './containers/Login/ConfirmLogoutModal';
import Overview from './containers/Overview';
import Stats from './containers/Stats';
import Shipments from './containers/Shipments';
import ShipmentDetails from './containers/ShipmentDetails';
import ShipmentEdit from './containers/ShipmentEdit/index';
import SearchResults from './containers/SearchResults';
import GenerateShipment from './containers/GenerateShipment';
import GeneratedShipments from './containers/GeneratedShipments';
import Faq from './containers/Faq';

import ResetPassword from './containers/ResetPassword';
import RequestResetPassword from './containers/RequestResetPassword';
import Team from './containers/Team';
import Profile from './containers/Profile';
import Investigations from './containers/Investigations';
// import Returns from './containers/Returns';
// import Team from './containers/Team';
// import ReturnOrder from './containers/ReturnOrder';
// import Report from './containers/Report';
// import Settings from './containers/Settings';

export const AUTH_CONSTANTS = {
  MUST_BE_AUTHENTICATED: 1,
  MUST_NOT_BE_AUTHENTICATED: 2,
  DOES_NOT_MATTER: 3
};

export function getAccessibleRoutes(routes, authenticated) {
  const accesibleRoutes = routes.filter(route => {
    if (
      (route.auth === AUTH_CONSTANTS.MUST_BE_AUTHENTICATED && authenticated) ||
      (route.auth === AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED &&
        !authenticated) ||
      route.auth === AUTH_CONSTANTS.DOES_NOT_MATTER
    ) {
      return true;
    }

    return false;
  });
  return accesibleRoutes;
}

const RouteWithMainContainer = (
  path,
  Comp,
  auth = AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
) => ({
  path,
  exact: true,
  auth,
  main: ({ backgroundColor, ...rest }) => (
    <main style={{ width: '100%' }}>
      <Comp {...rest} />
    </main>
  )
});

export const routes = [
  RouteWithMainContainer('/', Login, AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED),
  RouteWithMainContainer(
    '/login',
    Login,
    AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/reset-password/:token',
    ResetPassword,
    AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/request-reset-password-url',
    RequestResetPassword,
    AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/confirm-registration/:token',
    ResetPassword,
    AUTH_CONSTANTS.MUST_NOT_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/overview',
    Overview,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer('/stats', Stats, AUTH_CONSTANTS.MUST_BE_AUTHENTICATED),
  RouteWithMainContainer(
    '/shipments',
    Shipments,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/shipments/:id/edit',
    ShipmentEdit,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/shipments/:id/notes',
    (...props) => {
      const newProps = { ...props, showNotes: true };
      return <ShipmentDetails {...newProps} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/shipments/:id/startInvestigation',
    (...props) => {
      const newProps = { ...props, startInvestigation: true };
      return <ShipmentDetails {...newProps} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer('/faq', Faq, AUTH_CONSTANTS.MUST_BE_AUTHENTICATED),
  RouteWithMainContainer(
    '/shipments/:id',
    ShipmentDetails,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/shipments/:id/investigationStarted',
    (...props) => {
      return <ShipmentDetails {...{ ...props, investigationStarted: true }} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/shipments/:id/noteCreated',
    (...props) => {
      return <ShipmentDetails {...{ ...props, noteCreated: true }} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/s/:searchKey',
    SearchResults,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/team/addMember',
    (...props) => {
      const newProps = { ...props, addMember: true };
      return <Team {...newProps} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/team/memberInvited',
    (...props) => {
      const newProps = { ...props, memberInvited: true };
      return <Team {...newProps} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer('/team', Team, AUTH_CONSTANTS.MUST_BE_AUTHENTICATED),
  RouteWithMainContainer(
    '/profile',
    Profile,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/generate-shipment/:shipmentId',
    GenerateShipment,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/generate-shipment',
    GenerateShipment,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/generated-shipments',
    GeneratedShipments,
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  ),
  RouteWithMainContainer(
    '/investigations',
    (...props) => {
      return <Investigations {...{ ...props }} />;
    },
    AUTH_CONSTANTS.MUST_BE_AUTHENTICATED
  )
];

const Routes = ({ authenticated }) => {
  const routesFiltered = getAccessibleRoutes(routes, authenticated);
  const redirectRoute = routesFiltered[0];

  if (routesFiltered.length === 0) return null;

  return (
    <Switch>
      {routesFiltered.map((route, index) => (
        <Route
          key={`${index}-${route.path}`}
          path={route.path}
          exact={route.exact}
          render={({ ...props }) => <MainContainer route={route} {...props} />}
        />
      ))}
      <Redirect from="*" to={redirectRoute.path} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  shopId: get(state, 'auth.user.shop_id'),
  authenticated: state.auth.authenticated,
  role: get(state, 'auth.user.role'),
  loading: !isEmpty(state.main.loading),
  showLogoutModal: state.auth.showLogoutModal
});

const Router = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps)
)(Routes);

const icon = iconName => (
  <IconSquare icon={iconName} className="sidebar-navigation__icon" />
);

const topNavItems = t => [
  {
    path: '/overview',
    name: t('sidebar.overview'),
    image: icon('overview')
  },
  {
    path: '/shipments',
    name: t('sidebar.shipments'),
    image: icon('shipments')
  },
  {
    path: '/stats',
    name: t('sidebar.statistics'),
    image: icon('statistics')
  },
  {
    path: '/investigations',
    name: t('sidebar.investigations'),
    image: icon('attention')
  },
  {
    path: '/generate-shipment',
    name: t('sidebar.generateShipment'),
    image: icon('generateShipment')
  },
  {
    path: '/team',
    name: t('sidebar.team'),
    image: icon('team')
  }
];

const middleNavItems = t => [];

const bottomNavigationItems = t => [
  {
    path: '/faq',
    name: 'FAQ',
    image: icon('faq')
  },
  {
    path: '/profile',
    name: t('sidebar.profile'),
    image: icon('profile')
  },
  {
    path: '/logout',
    name: t('general.logout'),
    image: icon('logout'),
    handleClick: () => dispatch(authActions.toggleLogoutModal())
  }
];

const RouterWrapper = ({ shopId, authenticated, t, showLogoutModal }) => {
  const excludedRoutes = ['/shipments', '/stats'];
  let topNavigationItems = topNavItems(t);
  if (noStatisticsShopsIds.includes(shopId))
    topNavigationItems = topNavigationItems.filter(
      item => !excludedRoutes.includes(item.path)
    );

  return (
    <div>
      <Loader loading={false} />
      <BrowserRouter history={history}>
        {authenticated && (
          <SidebarNavigation
            topItems={topNavigationItems}
            middleItems={middleNavItems(t)}
            bottomItems={bottomNavigationItems(t)}
          />
        )}
        <Router />
        {showLogoutModal && (
          <ConfirmLogoutModal
            visible
            onClose={() => dispatch(authActions.toggleLogoutModal())}
            onProceed={() => dispatch(authActions.logout())}
            t={t}
          />
        )}
      </BrowserRouter>
    </div>
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(RouterWrapper);

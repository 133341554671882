import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import './index.scss';

const Container = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const Button = styled.button`
  color: #121212;
  background-color: transparent;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 20%;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
`;
const Value = styled.input`
  display: flex;
  padding: 0.5rem;

  width: 2rem;
  border: 0;
  text-align: center;
  background: none !important;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`;

export default function FieldNumber({
  control,
  setValue,
  name,
  disabled = false,
  defaultValue,
  rules = {},
  visible = true
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, ref }) => {
        const onSetValue = val => {
          const newValue = +value + val;
          if (newValue < 1) return;

          setValue(name, newValue, { shouldValidate: true });
        };

        return (
          <Container disabled={disabled} visible={visible}>
            <Button type="button" onClick={() => onSetValue(-1)}>
              -
            </Button>
            <Value
              type="number"
              disabled
              ref={ref}
              name={name}
              value={value}
              onChange={() => onChange(value)}
            />
            <Button type="button" primary onClick={() => onSetValue(1)}>
              +
            </Button>
          </Container>
        );
      }}
    />
  );
}

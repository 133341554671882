import React from 'react';
import cn from 'classnames';
import './index.scss';

function LoaderCSS({ visible = false, wide = false }) {
  return (
    <div className={cn('loader-css', { 'loader-css--wide': wide})}>
      {visible && (
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
}

export default LoaderCSS;

import { put } from 'redux-saga/effects';
import { get } from 'lodash';
import { push } from 'react-router-redux';
import { PER_PAGE } from 'config.js';
import { handleAPIError } from 'utils/sagaUtils';
import { getNotesRequest, createNoteRequest } from './api';
import noteActions from './actions';
import mainActions from '../main/actions';
import shipmentActions from '../shipment/actions';

export function* getNotes(action) {
  yield put(mainActions.startLoading('getNotes'));
  const { pageName, page, sorting, userId, shipmentId } = action;

  try {
    const response = yield getNotesRequest({
      perPage: PER_PAGE.notes[pageName],
      page,
      userId,
      shipmentId,
      sorting
    });
    const notes = get(response, 'data.data.notes');
    yield put(noteActions.getNotesOk(pageName, notes));
  } catch (error) {
    yield put(noteActions.getNotesFail(pageName, error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('getNotes'));
  }
}

export function* addNote(action) {
  yield put(mainActions.startLoading('addNote'));
  const { body, shipmentId } = action;

  try {
    yield createNoteRequest(body, shipmentId);
    yield put(noteActions.addNoteOk());
    yield put(shipmentActions.getShipment(shipmentId));
    yield put(push(`/shipments/${shipmentId}/noteCreated`));
  } catch (error) {
    yield put(noteActions.addNoteFail(error));
    handleAPIError(error);
  } finally {
    yield put(mainActions.stopLoading('addNote'));
  }
}

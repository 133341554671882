import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldInput from 'components/Field';
import { get } from 'lodash';

function Name(props = {}) {
  const { disabled, errors, validate = [], change, t } = props;

  return (
    <Field
      className="other-class"
      disabled={disabled}
      errors={[get(errors, 'name')]}
      label={t('shipments.name')}
      component={FieldInput}
      name="name"
      validate={validate}
      change={change}
    />
  );
}
export default withTranslation()(Name);

import { post } from '../rootApi';

export function refreshTokenRequest(token) {
  const query = `
    mutation refreshToken($token:String!){
    refreshToken(token: $token) {
      jwtToken
      error
    }
  }
`;

  const variables = { token };
  return post({ query, variables });
}

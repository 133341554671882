import React from 'react';
import ReactSVG from 'react-svg';
import Button from 'components/Button';
import { isNil } from 'lodash';
import teamMemberIcon from 'assets/images/teamMember.svg';
import deleteIcon from 'assets/images/closeFat.svg';
import './index.scss';

function TeamMemberIcon({
  user,
  showConfirmationDialog,
  onDelete,
  confirmDelete,
  t
}) {
  if (isNil(user)) return null;
  if (showConfirmationDialog) {
    return (
      <div key={user.email} className="team-member__confirm-delete">
        <span className="team-member__confirm-delete__text">
          {t('team.areYouSure')}
        </span>
        <div className="team-member__confirm-delete__name">
          {user.firstName} {user.lastName}
        </div>
        <div className="team-member__confirm-delete__actions">
          <Button
            text={t('team.delete')}
            className="button--secondary "
            onClick={confirmDelete}
            style={{
              minHeight: '2.3125rem',
              height: '2.3125rem',
              padding: '0 1rem',
              width: 'auto'
            }}
            textStyle={{
              fontSize: '.875rem'
            }}
          />
          <Button
            text={t('team.notDelete')}
            className="button--primary "
            onClick={() => onDelete(null)}
            style={{
              marginLeft: '1rem',
              minHeight: '2.3125rem',
              height: '2.3125rem',
              padding: '0 1rem',
              width: 'auto'
            }}
            textStyle={{
              fontSize: '.875rem'
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div key={user.email} className="team-member">
      <ReactSVG
        src={deleteIcon}
        onClick={() => onDelete(user.email)}
        className="team-member__delete"
      />
      <div className="team-member__icon">
        <ReactSVG src={teamMemberIcon} />
      </div>
      <div className="team-member__name">
        {user.firstName || user.first_name} {user.lastName || user.last_name}
      </div>
      <div className={`team-member__role--${user.role}`}>{t(`roles.${user.role}`)}</div>
      <div>{user.email}</div>
    </div>
  );
}

export default TeamMemberIcon;

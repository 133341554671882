import React, { useEffect } from 'react';
import { dispatch } from 'store';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Pagination from 'components/Pagination';
import Table, { ALIGNMENT } from 'components/Table';
import Button from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import ContentBox from 'components/ContentBox';
import MainContentContainer from 'components/MainContentContainer';
import './index.scss';
import Emoji from 'components/Emoji';
import userFlags from 'utils/userFlags';
import shipmentActions from 'features/shipment/actions';
import { getUser } from 'utils/selectors';
import flags from 'utils/flags';

function DashboardShipments({
  data: { shipments, page, totalPages } = {},
  history,
  loading,
  t
}) {
  const user = useSelector(getUser);
  const { isAdmin } = userFlags(user);

  const openLabel = labelUrl => window.open(labelUrl, '_blank');
  const openShipment = shipmentId => history.push(`/shipments/${shipmentId}`);
  let columns = [];

  const reloadShipments = newPage =>
    dispatch(
      shipmentActions.getShipments({
        scope: 'forGenerateShipmentPage',
        page: newPage || page,
        isAdmin,
        withAuthor: true
      })
    );

  useEffect(() => {
    reloadShipments();
  }, []);

  if (isAdmin)
    columns.push({
      id: 'icon',
      accessor: d =>
        (flags({ values: d }).isPickup && (
          <Emoji symbol="🚌" label="pickup" />
        )) ||
        (flags({ values: d }).isDropoff && (
          <Emoji symbol="📦" label="dropoff" />
        )),
      className: 'shipments-table__emoji'
    });

  columns = [
    ...columns,
    {
      id: 'email',
      title: t('shipments.email'),
      accessor: shipment => shipment.email || 'N/A'
    },
    {
      id: 'created at',
      title: t('shipments.createdAt'),
      accessor: shipment => moment(shipment.createdAt).format('DD/MM/YY HH:mm')
    },
    {
      id: 'action-shipment',
      title: t('shipments.shipment'),
      alignment: ALIGNMENT.CENTER,
      className: 'shipments-actions',
      accessor: shipment =>
        shipment.id && (
          <Button
            onClick={() => openShipment(shipment.id)}
            className="button--secondary open-label-button"
            style={{ display: 'inline-flex' }}
            text={t('shipments.open')}
          />
        )
    },
    {
      id: 'action-label',
      title: t('shipments.label'),
      alignment: ALIGNMENT.CENTER,
      className: 'shipments-actions',
      accessor: shipment =>
        shipment.labelUrl && (
          <Button
            onClick={() => openLabel(shipment.labelUrl)}
            className="button--secondary open-label-button"
            style={{ display: 'inline-flex' }}
            text={t('shipments.open')}
          />
        )
    }
  ];

  return (
    <MainContentContainer
      style={{ height: 'fit-content' }}
      mainStyles={{
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      main={
        <>
          <ContentBox
            header={
              <Breadcrumbs style={{ marginBottom: '2.5rem' }}>
                <Breadcrumbs.Item
                  onClick={() => history.push('/overview')}
                  tabIndex={0}
                  key="first"
                >
                  {t('general.overview')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                  onClick={() => history.push('/generate-shipment')}
                  tabIndex={-1}
                  key="second"
                >
                  {t('shipments.generateShipment')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item tabIndex={-2} key="third">
                  {t('shipments.allShipments')}
                </Breadcrumbs.Item>
              </Breadcrumbs>
            }
            style={{ minWidth: '61.25rem', minHeight: '29.25rem' }}
            containerStyle={{
              flexDirection: 'column',
              height: '50rem',
              borderRadius: '2.5rem'
            }}
          >
            <ContentBox.Row
              header={
                <ContentBox.Row.Header
                  text={t('shipments.allShipments')}
                  separated
                />
              }
              content={
                <>
                  <Button
                    text={t('shipments.refreshShipments')}
                    style={{ marginBottom: '2rem' }}
                    className="button--secondary"
                    onClick={() => reloadShipments()}
                    disabled={loading.getShipments}
                  />
                  <Table
                    loading={loading.getShipments}
                    withBorder={false}
                    data={shipments}
                    columns={columns}
                    t={t}
                  />
                  {totalPages > 1 && (
                    <Pagination
                      className="shipments-pagination"
                      pages={totalPages}
                      page={page}
                      onPageChange={reloadShipments}
                    />
                  )}
                </>
              }
            />
          </ContentBox>
        </>
      }
    />
  );
}

const mapStateToProps = state => {
  const {
    main: { loadingObj: loading },
    shipment: { forGenerateShipmentPage: data }
  } = state;

  return {
    loading,
    data
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(DashboardShipments);

import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import navigationActions from 'features/navigation/actions';
import { Logo } from '../Logo';
import { dispatch } from '../../store';
import './index.scss';

const mapStateToProps = state => {
  return {
    expanded: state.navigation.expanded,
    shop: state.shop.shop,
    role: get(state, 'auth.user.role')
  };
};

const Item = ({
  disabled,
  path,
  name,
  expanded,
  image,
  smallLine,
  handleClick
}) => (
  <NavLink
    to={path}
    key={`${path}-${name}`}
    className={`sidebar-navigation__box__item ${
      disabled ? 'sidebar-navigation__box__item--disabled' : ''
    } sidebar-navigation__box__item--${name.toLowerCase().replace(' ', '-')}`}
    onClick={handleClick}
  >
    {image}
    {expanded && (
      <div>
        <span>{name}</span>
        {smallLine && (
          <span className="sidebar-navigation__box__item__small-line">
            {smallLine}
          </span>
        )}
      </div>
    )}
  </NavLink>
);

class SidebarNavigation extends React.Component {
  handleArrowClick() {
    dispatch(navigationActions.toggle());
  }

  render() {
    const {
      topItems,
      bottomItems,
      middleItems,
      expanded,
      shop,
      role
    } = this.props;

    const className = !expanded
      ? 'sidebar-navigation--collapsed'
      : 'sidebar-navigation--expanded';

    const hideGenerateShipment =
      role !== 'admin' &&
      (isNil(get(shop, 'frontendAssetId')) ||
      get(shop, 'frontendAssetId') === 'naduvi');

    return (
      <div className={`sidebar-navigation ${className}`}>
        <Logo />
        <div className="sidebar-navigation__box sidebar-navigation__box--top">
          {topItems
            .filter(
              item =>
                !(hideGenerateShipment && item.path === '/generate-shipment')
            )
            .map((item, index) => (
              <Item
                path={item.path}
                name={item.name}
                image={item.image}
                expanded={expanded}
                disabled={item.disabled}
                smallLine={item.smallLine}
                key={`${index}-${item.path}-${item.name}`}
                handleClick={item.handleClick}
              />
            ))}
        </div>
        <div className="sidebar-navigation__box sidebar-navigation__box--middle">
          {middleItems.map((item, index) => (
            <Item
              path={item.path}
              name={item.name}
              image={item.image}
              expanded={expanded}
              disabled={item.disabled}
              smallLine={item.smallLine}
              key={`${index}-${item.path}-${item.name}`}
              handleClick={item.handleClick}
            />
          ))}
        </div>
        <div className="sidebar-navigation__box sidebar-navigation__box--bottom">
          {bottomItems.map((item, index) => (
            <Item
              path={item.path}
              name={item.name}
              image={item.image}
              expanded={expanded}
              disabled={item.disabled}
              smallLine={item.smallLine}
              key={`${index}-${item.path}-${item.name}`}
              handleClick={item.handleClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SidebarNavigation);

export const TEAM = {
  GET: 'TEAM_GET',
  GET_FAIL: 'TEAM_GET_FAIL',
  GET_OK: 'TEAM_GET_OK',
  INVITE_MEMBER: 'TEAM_INVITE_MEMBER',
  INVITE_MEMBER_FAIL: 'TEAM_INVITE_MEMBER_FAIL',
  INVITE_MEMBER_OK: 'TEAM_INVITE_MEMBER_OK',
  DELETE_MEMBER: 'TEAM_DELETE_MEMBER',
  DELETE_MEMBER_FAIL: 'TEAM_DELETE_MEMBER_FAIL',
  DELETE_MEMBER_OK: 'TEAM_DELETE_MEMBER_OK'
};

export default {
  getTeam: () => ({
    type: TEAM.GET
  }),
  getTeamOk: members => ({
    type: TEAM.GET_OK,
    members
  }),
  getTeamFail: error => ({
    type: TEAM.GET_FAIL,
    error
  }),
  inviteMember: (firstName, lastName, email) => ({
    type: TEAM.INVITE_MEMBER,
    firstName,
    lastName,
    email
  }),
  inviteMemberOk: () => ({
    type: TEAM.INVITE_MEMBER_OK,
    error: null
  }),
  inviteMemberFail: error => ({
    type: TEAM.INVITE_MEMBER_FAIL,
    error
  }),
  deleteMember: (email) => ({
    type: TEAM.DELETE_MEMBER,
    email
  }),
  deleteMemberOk: () => ({
    type: TEAM.DELETE_MEMBER_OK,
    error: null
  }),
  deleteMemberFail: error => ({
    type: TEAM.DELETE_MEMBER_FAIL,
    error
  })
};

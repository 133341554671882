import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import './index.scss';

const ConfirmCancelModal = ({ onClose, onProceed, t, visible }) => {
  return (
    <Modal
      visible={visible}
      className="modal--small"
      onClose={onClose}
      withCloseButton
      style={{
        padding: '2.875rem 4.125rem'
      }}
    >
      <>
        <div className="confirm-cancel-modal__text">
          {t('form.cancelShipment.text')}
        </div>
        <div className="confirm-cancel-modal__buttons">
          <Button
            text={t('form.cancelShipment.notConfirm')}
            className="button--grey width--auto"
            onClick={onClose}
          />
          <Button
            text={t('form.cancelShipment.confirm')}
            onClick={onProceed}
            className="button--danger width--auto"
          />
        </div>
      </>
    </Modal>
  );
};

export default ConfirmCancelModal;
